import {
  IChat,
  ICreateKnowledgeDocPayload,
  IExpertise,
  IFilesUploadPayload,
} from "../types";

export class FileProcessor {
  static DEBUG = false;
  static ENABLE_HACKS = false;
  static MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
  static MAX_TOTAL_SIZE = 25 * 1024 * 1024; // 25MB
  static MAX_FILE_COUNT = 10;
  static VALID_FILE_TYPES = ["pdf", "png", "jpg", "jpeg", "webp", "gif"];
  static MAX_IMAGE_SIZE = 1024; // Maximum dimension for images

  private static log(...args: any[]) {
    if (FileProcessor.DEBUG) {
      console.log("FileProcessor:", ...args);
    }
  }

  private static bytesToMB(bytes: number): string {
    return (bytes / (1024 * 1024)).toFixed(2) + " MB";
  }

  static async processFiles(
    files: File[],
    context: { chatId?: string | IChat; expertiseId?: string | IExpertise }
  ): Promise<IFilesUploadPayload> {
    FileProcessor.log("Processing files:", files);
    const validFiles: ICreateKnowledgeDocPayload[] = [];
    const invalidFiles: File[] = [];
    let totalSize = 0;

    if (FileProcessor.ENABLE_HACKS) {
      FileProcessor.MAX_FILE_COUNT = 100;
      FileProcessor.MAX_TOTAL_SIZE = 100 * 1024 * 1024; // 100MB
      FileProcessor.log("Hacks enabled. New limits:", {
        MAX_FILE_COUNT: FileProcessor.MAX_FILE_COUNT,
        MAX_TOTAL_SIZE: FileProcessor.MAX_TOTAL_SIZE,
      });
    }

    for (const file of files) {
      try {
        FileProcessor.log(
          `Processing file: ${
            file.name
          }, Original size: ${FileProcessor.bytesToMB(file.size)}`
        );
        const processedFile = await FileProcessor.processFile(file);
        totalSize += processedFile.size;

        FileProcessor.log(
          `Processed file: ${
            processedFile.name
          }, New size: ${FileProcessor.bytesToMB(processedFile.size)}`
        );

        if (totalSize > FileProcessor.MAX_TOTAL_SIZE) {
          throw new Error(
            `Total file size exceeds ${
              FileProcessor.MAX_TOTAL_SIZE / 1024 / 1024
            }MB limit`
          );
        }

        if (validFiles.length >= FileProcessor.MAX_FILE_COUNT) {
          throw new Error(
            `Exceeded maximum file count of ${FileProcessor.MAX_FILE_COUNT}`
          );
        }

        const fileType = FileProcessor.getFileExtension(processedFile.name);
        const knowledgeDocPayload: ICreateKnowledgeDocPayload = {
          pathName: processedFile.name.split(".").shift() || "",
          pathExtension: `.${fileType}`,
          file: processedFile,
          knowledgeType: fileType,
        };

        // Set the correct context
        if (context.chatId) {
          knowledgeDocPayload.chatId = context.chatId.toString();
        } else if (context.expertiseId) {
          knowledgeDocPayload.expertiseId = context.expertiseId.toString();
        }

        validFiles.push(knowledgeDocPayload);

        FileProcessor.log(
          "File processed successfully:",
          processedFile.name,
          "Context:",
          context
        );
      } catch (error) {
        console.error(`Error processing file ${file.name}:`, error);
        invalidFiles.push(file);
      }
    }

    FileProcessor.log(
      "File processing completed. Valid files:",
      validFiles.length,
      "Invalid files:",
      invalidFiles.length,
      "Total size:",
      FileProcessor.bytesToMB(totalSize),
      "Context:",
      context
    );

    return {
      files: validFiles,
      invalidFileCount: invalidFiles.length,
    };
  }

  private static async processFile(file: File): Promise<File> {
    FileProcessor.log(
      "Processing file:",
      file.name,
      "Size:",
      FileProcessor.bytesToMB(file.size)
    );
    const originalFileType = FileProcessor.getFileExtension(file.name);
    const lowerCaseFileName = FileProcessor.getFileNameWithLowercaseExtension(
      file.name
    );

    if (!FileProcessor.VALID_FILE_TYPES.includes(originalFileType)) {
      throw new Error(`Invalid file type: ${originalFileType}`);
    }

    if (
      file.size > FileProcessor.MAX_FILE_SIZE &&
      !FileProcessor.ENABLE_HACKS
    ) {
      throw new Error(
        `File size exceeds ${FileProcessor.MAX_FILE_SIZE / 1024 / 1024}MB limit`
      );
    }

    if (["png", "jpg", "jpeg", "webp", "gif"].includes(originalFileType)) {
      return FileProcessor.resizeAndConvertImage(file, lowerCaseFileName);
    }

    // For non-image files (e.g., PDF), just ensure the filename has a lowercase extension
    return new File([file], lowerCaseFileName, { type: file.type });
  }

  private static getFileExtension(fileName: string): string {
    return fileName.split(".").pop()?.toLowerCase() || "";
  }

  private static getFileNameWithLowercaseExtension(fileName: string): string {
    const parts = fileName.split(".");
    const extension = parts.pop()?.toLowerCase() || "";
    return `${parts.join(".")}.${extension}`;
  }

  public static async resizeAndConvertImage(
    file: File,
    newFileName: string,
    overrideMaxSize?: number
  ): Promise<File> {
    FileProcessor.log(
      "Resizing and converting image:",
      file.name,
      "Original size:",
      FileProcessor.bytesToMB(file.size)
    );

    return new Promise((resolve, reject) => {
      const maxSize = overrideMaxSize || FileProcessor.MAX_IMAGE_SIZE;
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        let { width, height } = img;

        FileProcessor.log("Original dimensions:", width, "x", height);

        // Resize the image if necessary, with the largest dimension capped at 300px
        if (width > maxSize || height > maxSize) {
          if (width > height) {
            height = (height * maxSize) / width;
            width = maxSize;
          } else {
            width = (width * maxSize) / height;
            height = maxSize;
          }
        }

        FileProcessor.log("Resized dimensions:", width, "x", height);

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");

        if (ctx) {
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(
            (blob) => {
              if (blob) {
                // Convert the resized image to PNG
                const pngFileName =
                  newFileName.replace(/\.[^/.]+$/, "") + ".png";
                const resizedFile = new File([blob], pngFileName, {
                  type: "image/png",
                });
                FileProcessor.log(
                  "Image resized and converted to PNG successfully:",
                  pngFileName,
                  "New size:",
                  FileProcessor.bytesToMB(resizedFile.size)
                );
                resolve(resizedFile);
              } else {
                reject(new Error("Failed to create blob from canvas"));
              }
            },
            "image/png" // Always convert to PNG format
          );
        } else {
          reject(new Error("Failed to get 2D context from canvas"));
        }
      };

      img.onerror = () => {
        reject(new Error(`Failed to load image: ${file.name}`));
      };

      img.src = URL.createObjectURL(file);
    });
  }
}
