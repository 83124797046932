import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Typography,
  CircularProgress,
  Box,
  alpha,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import CustomInput from "../../../ui-elements/CustomInput";
import { IUserDetails } from "../../../../types/index";
import { useTheme } from "@mui/material";
import { updateUserDetails } from "../../../../features/users/userDetailsSlice";
import BlockTitle from "../../../ui-elements/BlockTitle";
import CustomDatePicker from "../../../ui-elements/CustomDatePicker";
import { BackToLoginButton } from "./components/BackToLoginButton";
import AnimatedAuthView from "./AnimatedAuthView";
import { AnimatedFromDirection } from "./AnimatedViewWrapper";
import { UserProfileImage } from "../../../ui-elements/UserProfileImage";

const UserDetailsView: React.FC = () => {
  const userCredentials = useAppSelector(
    (state) => state.userCredentials.userCredentials
  );
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const submitButton = useRef<HTMLButtonElement | null>(null);
  const [currentErrorMessage, setCurrentErrorMessage] = useState<string | null>(
    null
  );
  const existingUserDetails = useAppSelector(
    (state) => state.userDetails.userDetails
  );
  const [loading, setLoading] = useState(false);

  const thirteenYearsAgo = new Date();
  thirteenYearsAgo.setFullYear(thirteenYearsAgo.getFullYear() - 16);
  thirteenYearsAgo.setHours(0, 0, 0, 0); // Ignore time part for comparison
  const [date, setDate] = useState<Date>(thirteenYearsAgo);

  const [formData, setFormData] = useState({
    firstName: existingUserDetails?.firstName || "",
    lastName: existingUserDetails?.lastName || "",
    birthday: existingUserDetails?.birthday || thirteenYearsAgo,
  } as IUserDetails);

  const pricingPageSlice = useAppSelector((state) => state.pricingPage);
  const intendedPlan = pricingPageSlice.intendedPlan;

  useEffect(() => {
    if (existingUserDetails) {
      setFormData(existingUserDetails);
      if (existingUserDetails.birthday) {
        setDate(new Date(existingUserDetails.birthday));
      }
    }
  }, [existingUserDetails]);

  const clearErrorMessage = () => {
    setCurrentErrorMessage(null);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Update formData with the latest values before submission
    const updatedFormData = {
      ...formData,
      firstName: (
        e.currentTarget.elements.namedItem("firstName") as HTMLInputElement
      ).value,
      lastName: (
        e.currentTarget.elements.namedItem("lastName") as HTMLInputElement
      ).value,
      birthday: date,
    };

    // Check for required fields
    if (!updatedFormData.firstName || !updatedFormData.lastName) {
      setCurrentErrorMessage("Please fill out all fields");
      return;
    }

    // Check for birthday selection
    const selectedDate = new Date(updatedFormData.birthday);
    selectedDate.setHours(0, 0, 0, 0); // Normalize the time part for accurate comparison
    if (selectedDate.getTime() === thirteenYearsAgo.getTime()) {
      setCurrentErrorMessage(
        "Odds are you aren't exactly 16 years old today. Please select your birthday."
      );
      return;
    } else if (selectedDate > thirteenYearsAgo) {
      setCurrentErrorMessage(
        "You must be 16 years or older to use Virtual Experts"
      );
      return;
    }

    setLoading(true);
    try {
      const response = await dispatch(updateUserDetails(updatedFormData));
      if (response.type === "userDetails/updateUserDetails/rejected") {
        setCurrentErrorMessage(response.payload);
      } else {
        setCurrentErrorMessage(null);
        // AuthStateMachine will handle navigation
      }
    } catch (error) {
      setCurrentErrorMessage("An error occurred, please try again.");
    }
    setLoading(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
    setFormData((prevFormData) => ({
      ...prevFormData,
      birthday: newDate,
    }));
  };

  return (
    <AnimatedAuthView direction={AnimatedFromDirection.Right}>
      <Box
        component={"form"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
        }}
        onSubmit={handleSubmit}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h4" textAlign={"center"}>
              {"User Info"}
            </Typography>
            {intendedPlan && !loading && (
              <Typography
                sx={{
                  color: alpha(theme.palette.text.secondary, 0.7),
                  fontSize: "0.85rem",
                  textAlign: "center",
                  fontWeight: "400",
                  padding: "4px",
                  paddingBottom: "16px",
                }}
              >
                You're signing up for the{" "}
                <Typography
                  component="span"
                  color={theme.palette.info.main}
                  fontSize={"0.85rem"}
                  sx={{ fontWeight: "bold" }}
                >
                  {intendedPlan.name} plan
                </Typography>
              </Typography>
            )}
          </Box>
        </Box>

        {loading && (
          <Box
            sx={{
              position: "relative",
              bottom: "-25px",
            }}
          >
            <CircularProgress thickness={6} />
          </Box>
        )}
        <Box
          sx={{
            visibility: loading ? "hidden" : "visible",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: theme.palette.text.secondary,
                fontSize: "12px",
                textAlign: "center",
                fontWeight: "300",
                paddingBottom: "16px",
                paddingLeft: "8px",
                marginTop: "8px",
              }}
            >
              {`Congrats! ${userCredentials?.email} has been accepted into the beta program. Please fill out the following information in order to continue.`}
            </Typography>
          </Box>
          {currentErrorMessage && (
            <Typography
              sx={{
                color: theme.palette.error.main,
                fontSize: "12px",
                textAlign: "center",
                cursor: "pointer",
                fontWeight: "600",
                padding: "8px",
              }}
              onClick={clearErrorMessage}
            >
              {currentErrorMessage || "Something went wrong, please try again"}
            </Typography>
          )}
          <UserProfileImage />
          <BlockTitle
            title="Name"
            description={
              "This is how you will be identified by your experts and later by other users."
            }
            overrideMarginTop={true}
          />
          <Box
            display={"flex"}
            flexDirection={"row"}
            width={"100%"}
            marginTop={"24px"}
            marginBottom={"24px"}
          >
            <CustomInput
              name="firstName"
              label="First Name"
              value={formData.firstName}
              startingValue={existingUserDetails?.firstName || ""}
              handleChange={handleChange}
              half={true}
              forceInivisible={loading}
            />
            <Box width={"16px"} />
            <CustomInput
              name="lastName"
              label="Last Name"
              value={formData.lastName}
              startingValue={existingUserDetails?.lastName || ""}
              handleChange={handleChange}
              half={true}
              forceInivisible={loading}
            />
          </Box>
          <Box sx={{ marginTop: "32px" }}>
            <BlockTitle
              title="Age"
              description={
                "This is required to ensure you are at least 16 years old."
              }
              overrideMarginTop={true}
            />
            <Box height={"16px"} />
            <CustomDatePicker
              selectedDate={date}
              onDateChange={handleDateChange}
              backgroundColorOverride={theme.palette.background.backdrop}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              paddingTop: "16px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              ref={submitButton}
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{
                marginTop: "24px",
                marginBottom: "16px",
                borderRadius: "6px",
                width: "194px",
                height: "40px",
                "&:hover:active": {
                  transform: "scale(0.95)",
                  transition: "transform 0.1s",
                },
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Continue"}
            </Button>
            <BackToLoginButton />
          </Box>
        </Box>
      </Box>
    </AnimatedAuthView>
  );
};

export default UserDetailsView;
