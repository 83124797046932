import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  lighten,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SectionBase from "../../workspace-page/content-views/profile-view/sections/SectionBase";
import { useAppDispatch } from "../../../../app/store";
import {
  logout,
  setManualLogout,
} from "../../../../features/users/userAuthSlice";
import { useNavigate } from "react-router-dom";
import {
  clearAllKnowledgeJobs,
  clearAllReplyJobs,
} from "../../../../features/users/accountPageSlice";
import { simulateMonth } from "../../../../routes/userRoutes";
import BlockTitle from "../../../ui-elements/BlockTitle";
import { UtilityService } from "../../../../services/UtilityService";

const DangerZoneBlock: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  const [simulating, setisSimulating] = useState(false);
  const [clearingJobs, setClearingJobs] = useState(false);
  const [jobsCleared, setJobsCleared] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = containerRef?.current?.clientWidth as number;
      //console.log("width: " + width);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const callResetPassword = () => {
    dispatch(setManualLogout(true));
    dispatch(logout());
    navigate("/auth/request-reset-password");
  };

  const callClearReplyJobs = async () => {
    setClearingJobs(true);
    const startTime = Date.now();
    await dispatch(clearAllReplyJobs());
    const elapsedTime = Date.now() - startTime;
    if (elapsedTime < 1000) {
      await new Promise((resolve) => setTimeout(resolve, 1000 - elapsedTime));
    }
    setClearingJobs(false);
    setJobsCleared(true);
    setTimeout(() => setJobsCleared(false), 3000); // Reset after 3 seconds
  };

  const callClearAllKnowledgeJobs = () => {
    dispatch(clearAllKnowledgeJobs());
  };

  const callSimulateMonth = async () => {
    if (simulating) {
      return;
    }

    setisSimulating(true);
    const result = await simulateMonth();
    setisSimulating(false);
  };

  const isMobile = UtilityService.getIsMobile();

  const DangerButton = ({
    onClick,
    label,
    isLoading = false,
    isCleared = false,
  }) => {
    return (
      <Button
        onClick={onClick}
        variant="contained"
        disabled={isLoading}
        sx={{
          height: "36px",
          width: "140px",
          color: isCleared
            ? theme.palette.error.main
            : theme.palette.text.primary,
          background: theme.palette.background.buttonHighlighted,
          boxShadow: "none",
          fontWeight: isCleared ? 600 : 500,
          fontSize: "12px",
          textTransform: "none",
          marginTop: isXs ? "16px" : "0",
          "&:hover": !isMobile
            ? {
                backgroundColor: isCleared
                  ? theme.palette.background.buttonHighlighted
                  : theme.palette.error.main,
                boxShadow: "none",
              }
            : undefined,
          "&:active": {
            transform: "scale(0.95)",
          },
        }}
      >
        {isLoading ? (
          <CircularProgress
            size={16}
            thickness={5}
            sx={{ color: theme.palette.error.main }}
          />
        ) : isCleared ? (
          "All Clear!"
        ) : (
          label
        )}
      </Button>
    );
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-column",
        alignItems: "flex-start",
        alignContent: "flex-start",
        height: "auto",
        width: "100%",
        paddingBottom: "50px",
      }}
    >
      <SectionBase title="Danger Zone">
        <Box
          sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "24px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            display="flex"
            flexDirection={isXs ? "column" : "row"}
            alignItems={isXs ? "flex-start" : "center"}
            justifyContent="space-between"
            width="100%"
            gap="16px"
          >
            <BlockTitle
              title="Reset Password"
              description="You will be logged out and redirected to reset your password"
              overrideMarginTop={true}
            />

            <DangerButton onClick={callResetPassword} label="Reset" />
          </Box>
          <Box height="24px" />
          <Divider orientation="horizontal" />
          <Box height="24px" />
          <Box
            display="flex"
            flexDirection={isXs ? "column" : "row"}
            alignItems={isXs ? "flex-start" : "center"}
            justifyContent="space-between"
            width="100%"
            gap="16px"
          >
            <BlockTitle
              title="Clear Job Queue"
              description="This will clear all jobs in your reply queue - which can break things. Only do this if you know what you're doing."
              overrideMarginTop={true}
            />
            <DangerButton
              onClick={callClearReplyJobs}
              label="Clear"
              isLoading={clearingJobs}
              isCleared={jobsCleared}
            />
          </Box>
          {/* <Box height="24px" />
          <Divider orientation="horizontal" />
          <Box height="24px" />
          <Box
            display="flex"
            flexDirection={isXs ? "column" : "row"}
            alignItems={isXs ? "flex-start" : "center"}
            justifyContent="space-between"
            width="100%"
            gap="16px"
          >
            <BlockTitle
              title="Simulate Month"
              description="This is for testing Stripe billing events only."
              overrideMarginTop={true}
            />
            <DangerButton
              onClick={callSimulateMonth}
              label="Simulate"
              isLoading={simulating}
            />
          </Box> */}
        </Box>
      </SectionBase>
    </Box>
  );
};

export default DangerZoneBlock;
