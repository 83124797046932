import { Checkbox, useTheme } from "@mui/material";
import { Submit } from "../../icons";
import { useEffect } from "react";
import { UtilityService } from "../../services/UtilityService";

const CustomCheckbox = ({ isChecked, setIsChecked, size = "24px" }) => {
  const theme = useTheme();
  const isMobile = UtilityService.getIsMobile();

  useEffect(() => {
    //console.log("isChecked changed in useEffect", isChecked);
  }, [isChecked]);

  const handleTouchEnd = (event) => {
    event.preventDefault();
    setIsChecked({ target: { checked: !isChecked } });
  };

  return (
    <Checkbox
      checked={isChecked}
      onChange={setIsChecked}
      onTouchEnd={isMobile ? handleTouchEnd : undefined}
      disableRipple={isMobile}
      icon={<span />} // Empty span for the unchecked state
      checkedIcon={<Submit />} // Using Submit icon for the checked state
      sx={{
        height: size,
        width: size,
        borderRadius: `calc(${size} / 6)`,
        padding: "0px",
        border: `1px solid ${theme.palette.text.secondary}`,
        marginRight: "8px",
      }}
    />
  );
};

export default CustomCheckbox;
