import { IAuthFormData, IUser } from "../types/index";
import { IGoogleAuthFormData } from "../types/user/IUserAuth";
import { IResetPasswordPayload } from "../types/user/IUserCredentials";
import { IUserDetails } from "../types/user/IUserDetails";
import { IUserPreferences } from "../types/user/IUserPreferences";
import API from "./api";

// current user
export const loginGoogleUser = (googleAuthFormData: IGoogleAuthFormData) =>
  API.post("/users/loginGoogleUser", googleAuthFormData);
export const signUpGoogleUser = (googleAuthFormData: IGoogleAuthFormData) =>
  API.post("/users/signUpGoogleUser", googleAuthFormData);
export const login = (authFormData: IAuthFormData) =>
  API.post("/users/login", authFormData);
export const signUp = (authFormData: IAuthFormData) =>
  API.post("/users/signup", authFormData);
export const validateToken = () => API.get("/users/validateToken");
export const updateUser = (updatedUser: IUser) =>
  API.put("/users/updateUser", updatedUser);
export const clearAllReplyJobs = () => API.get("/users/clearAllReplyJobs");

// user details
export const loadUserDetails = (id: string) =>
  API.post("/users/loadUserDetails", id);
export const updateUserDetails = (userDetails: IUserDetails) =>
  API.put("/users/updateUserDetails", userDetails);
export const uploadImage = (image: File) => {
  const formData = new FormData();
  formData.append("image", image); // 'image' must match the field name on the server

  // Send the image in a FormData object
  return API.post("/users/upload-image", formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Important for file uploads
    },
  });
};

// user preferences
export const loadUserPreferences = (id: string) =>
  API.post("/users/loadUserPreferences", id);
export const updateUserPreferences = (userPreferences: IUserPreferences) =>
  API.put("/users/updateUserPreferences", userPreferences);

// user subscription
export const getSubscriptionInfo = (id: string) =>
  API.post("/users/getSubscriptionInfo", id);
export const checkout = (planId: string) =>
  API.post("/users/checkout", { planId });
export const cancelPlan = () => API.post("/users/cancelPlan");
export const validatePayment = (sessionId: string) =>
  API.post("/users/validatePayment", { sessionId });
export const getUsageDataReport = () => API.get("/users/getUsageDataReport");
export const createCustomerPortalSession = () =>
  API.post("/users/createCustomerPortalSession");
export const revertScheduledCancel = () => API.post("/users/revertCancel");
export const revertScheduledDowngrade = () =>
  API.post("/users/revertDowngrade");
export const getIsUserDirty = () => API.post("/users/isUserDirty");
export const clearIsUserDirty = () => API.post("/users/clearIsUserDirty");

// user credentials
export const getUserCredentialsInfo = (id: string) =>
  API.post("/users/getUserCredentialsInfo", id);
export const resendVerificationEmail = (email: string) =>
  API.post("/users/resendVerificationEmail", email);
export const sendResetPasswordEmail = (email: any) =>
  API.post("/users/sendResetPasswordEmail", email);
export const resetPassword = (resetPasswordPayload: IResetPasswordPayload) =>
  API.post("/users/resetPassword", resetPasswordPayload);
export const fetchPlans = () => API.get("/users/fetchPlans");

// testing
export const simulateMonth = () => API.post("/users/simulateMonth");
export const clearAllKnowledgeJobs = () =>
  API.get("/users/clearAllKnowledgeJobs");
