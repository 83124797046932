import React from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useAppSelector } from "../../../app/store";
import { Close } from "../../../icons";

export const CountdownRow: React.FC = () => {
  const theme = useTheme();
  const activeExpert = useAppSelector((state) => state.experts.activeExpert);
  const activeColor = activeExpert?.color || theme.palette.colors.purple[300];
  const color = activeColor;
  const { timeRemaining } = useAppSelector((state) => state.timedAlertPopup);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      sx={{
        marginLeft: "-4px",
        paddingLeft: "4px",
      }}
      gap={"4px"}
    >
      <IconButton
        size="small"
        sx={{
          height: 16,
          width: 16,
          padding: 0,
          color: color,
        }}
      >
        <Close />
      </IconButton>
      <Typography
        variant="body2"
        sx={{
          fontSize: "0.85rem",
          fontWeight: 700,
          color: color,
        }}
      >
        Will dismiss in {timeRemaining} seconds
      </Typography>
    </Box>
  );
};
