import React, { useEffect, useCallback, useState, useRef } from "react";
import { useAppSelector, useAppDispatch } from "../../../app/store";
import {
  Box,
  Typography,
  Snackbar,
  lighten,
  darken,
  Fade,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  dismissTimedAlertPopup,
  decrementTimer,
} from "../../../features/ui/timedAlertPopupSlice";
import { UtilityService } from "../../../services/UtilityService";
import { CountdownRow } from "./CountdownRow";

const TimedAlertPopup: React.FC = () => {
  const dispatch = useAppDispatch();
  const themeSlice = useAppSelector((state) => state.theme);
  const isDarkMode = themeSlice.isDarkMode;
  const theme = useTheme();
  const { show, icon, title, subheader, details, timeRemaining } =
    useAppSelector((state) => state.timedAlertPopup);

  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef({ icon, title, subheader, details, timeRemaining });

  useEffect(() => {
    if (show && !isVisible) {
      contentRef.current = { icon, title, subheader, details, timeRemaining };
      setIsVisible(true);
    }
  }, [show, icon, title, subheader, details, timeRemaining]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isVisible) {
      timer = setInterval(() => {
        dispatch(decrementTimer());
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isVisible, dispatch]);

  useEffect(() => {
    if (timeRemaining === 0 && isVisible) {
      handleClose();
    }
  }, [timeRemaining, isVisible]);

  const handleClose = useCallback(() => {
    setIsVisible(false);
    setTimeout(() => {
      dispatch(dismissTimedAlertPopup());
    }, 300); // Duration of fade out animation
  }, [dispatch]);

  const isMobile = UtilityService.getIsMobile();
  const highlightedBackground = isDarkMode
    ? lighten(theme.palette.background.buttonHighlighted, 0.05)
    : darken(theme.palette.colors.grayScale.white, 0.03);

  const defaultBackground = isDarkMode
    ? theme.palette.background.buttonHighlighted
    : theme.palette.colors.grayScale.white;

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={isVisible}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 400 }}
      sx={{
        transition: "transform 0.3s ease-out",
      }}
    >
      <Box
        sx={{
          // transform down half the screen so its centered
          transform: "translateY(-140px)",
          backgroundColor: defaultBackground,
          borderRadius: "16px",
          p: "16px",
          minWidth: 300,
          maxWidth: 500,
          boxShadow: isDarkMode
            ? "0px 4px 20px 20px rgba(0, 0, 0, 0.55)"
            : "0px 4px 20px 20px rgba(0, 0, 0, 0.05)",
          cursor: "pointer",
          "&:hover": {
            ...(!isMobile && {
              backgroundColor: highlightedBackground,
              borderRadius: "16px",
            }),
          },
        }}
        onClick={handleClose}
      >
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {contentRef.current.icon && (
            <Box
              sx={{
                height: "24px",
                width: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "8px",
                color: theme.palette.text.primary,
                borderRadius: "50%",
              }}
            >
              {React.cloneElement(
                contentRef.current.icon as React.ReactElement,
                {
                  style: { width: "100%", height: "100%" },
                }
              )}
            </Box>
          )}
          <Typography
            variant="h6"
            component="div"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {contentRef.current.title || "Alert"}
          </Typography>
        </Box>
        <Typography
          variant="subtitle1"
          fontSize="0.9rem"
          fontWeight={500}
          sx={{ mb: 1, color: theme.palette.text.secondary }}
        >
          {contentRef.current.subheader}
        </Typography>
        <Typography
          variant="body1"
          fontSize="0.75rem"
          sx={{ mb: "16px", color: theme.palette.text.secondary }}
        >
          {contentRef.current.details}
        </Typography>
        <CountdownRow />
      </Box>
    </Snackbar>
  );
};

export default TimedAlertPopup;
