import { useEffect } from "react";
import CustomToggle from "../../../../ui-elements/CustomToggle";
import { Box, Grid } from "@mui/material";
import BlockTitle from "../../../../ui-elements/BlockTitle";
import { IUserPreferences } from "../../../../../types";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { setDarkMode } from "../../../../../features/ui/themeSlice";
import { updateUserPreferences } from "../../../../../features/users/userPreferencesSlice";

interface DarkModeBlockProps {
  userPreferences: IUserPreferences | null;
}

const DarkModeBlock = ({ userPreferences }: DarkModeBlockProps) => {
  const dispatch = useAppDispatch();
  const isDarkMode = useAppSelector((state) => state.theme.isDarkMode);

  useEffect(() => {
    // console.log("isDarkMode", isDarkMode);
  }, [userPreferences, isDarkMode]);

  const handleToggleChange = async (event) => {
    const updatedUser = {
      ...userPreferences,
      prefersDarkMode: event.target.checked,
    };
    dispatch(setDarkMode(event.target.checked));
    dispatch(updateUserPreferences(updatedUser));
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Box>
        <BlockTitle
          title={"Dark Mode"}
          subtitle={isDarkMode ? "On" : "Off"}
          description={
            isDarkMode ? "Turn on the lights" : "Turn off the lights"
          }
          overrideMarginTop={true}
        />
      </Box>
      <Box display="flex" alignItems="center">
        <CustomToggle checked={isDarkMode} onChange={handleToggleChange} />
      </Box>
    </Box>
  );
};

export default DarkModeBlock;
