import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import CustomInput from "../../../ui-elements/CustomInput";
import { useAppDispatch } from "../../../../app/store";
import { resetPassword } from "../../../../features/users/userCredentialsSlice";
import { IResetPasswordPayload } from "../../../../types/user/IUserCredentials";
import AnimatedAuthView from "./AnimatedAuthView";
import { AnimatedFromDirection } from "./AnimatedViewWrapper";

const ResetPasswordView: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentErrorMessage, setCurrentErrorMessage] = useState<string | null>(
    null
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenFromUrl = searchParams.get("token");
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    } else {
      navigate("/auth/login");
    }
  }, [location, navigate]);

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setCurrentErrorMessage("Passwords do not match.");
      return;
    }

    setLoading(true);
    setCurrentErrorMessage(null);

    try {
      const resetPasswordPayload = {
        token,
        password: newPassword,
      } as IResetPasswordPayload;

      const result = await dispatch(resetPassword(resetPasswordPayload));
      if (result.type === "userCredentials/resetPassword/fulfilled") {
        setSuccess(true);
        setTimeout(() => {
          navigate("/auth/login");
        }, 3000);
      } else {
        setCurrentErrorMessage(
          result.payload.message ||
            "Failed to reset password. Please try again."
        );
      }
    } catch (error) {
      setCurrentErrorMessage("An error occurred. Please try again.");
    }
    setLoading(false);
  };

  return (
    <AnimatedAuthView direction={AnimatedFromDirection.Right}>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" gutterBottom>
          Reset Password
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.secondary,
            fontSize: "14px",
            textAlign: "center",
            fontWeight: "400",
            maxWidth: "400px",
            mb: 3,
          }}
        >
          {success
            ? "Your password has been successfully reset. You will be redirected to the login page shortly."
            : "Please enter your new password."}
        </Typography>
        {!success && (
          <Box width={"100%"} marginTop={"8px"} marginBottom={"24px"}>
            <CustomInput
              name="newPassword"
              label="New Password"
              handleChange={handleNewPasswordChange}
              type="password"
              disabled={loading}
            />
            <Box height={"24px"} />
            <CustomInput
              name="confirmPassword"
              label="Confirm Password"
              handleChange={handleConfirmPasswordChange}
              type="password"
              disabled={loading}
            />
          </Box>
        )}
        {currentErrorMessage && !loading && (
          <Typography
            sx={{
              color: theme.palette.error.main,
              fontSize: "14px",
              textAlign: "center",
              fontWeight: "500",
              mb: 2,
            }}
          >
            {currentErrorMessage}
          </Typography>
        )}
        {!success && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading || success}
            sx={{ mb: 2, minWidth: "200px" }}
          >
            {loading ? <CircularProgress size={24} /> : "Reset Password"}
          </Button>
        )}
        <Button
          sx={{
            fontSize: "14px",
            textTransform: "none",
          }}
          onClick={() => navigate("/auth/login")}
          disabled={loading}
        >
          Back to Login
        </Button>
      </Box>
    </AnimatedAuthView>
  );
};

export default ResetPasswordView;
