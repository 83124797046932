import { Dispatch } from "redux";
import { checkout } from "../features/users/userSubscriptionSlice";
import {
  ISubscriptionInfo,
  IUserDetails,
  IUserCredentials,
  IUserAuth,
} from "../types";
import { IPlan } from "../types/ui/pricing-page/IPlan";
import { SubscriptionStatus } from "../types/user/ISubscriptionInfo";

export function navigateBasedOnAuthStage(
  navigate: (path: string) => void,
  userPayload: {
    userCredentials: IUserCredentials | null;
    userDetails: IUserDetails | null;
    subscriptionInfo: ISubscriptionInfo | null;
    userAuth: IUserAuth | null;
    intendedPlan: IPlan | null;
  },
  currentPath: string // Added currentPath parameter
) {
  const {
    userCredentials,
    userDetails,
    subscriptionInfo,
    userAuth,
    intendedPlan,
  } = userPayload;

  const safeNavigate = (targetPath: string) => {
    if (currentPath !== targetPath) {
      navigate(targetPath);
    }
  };

  try {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (token) {
      return safeNavigate("/auth/reset-password?token=" + token);
    }

    const emailVerified = params.get("emailVerified");
    if (emailVerified) {
      if (userAuth?.token) {
        return safeNavigate("/auth/user-details");
      } else {
        return safeNavigate("/auth/login?emailVerified=true");
      }
    }

    const rerouteToSignup = params.get("signup");
    if (rerouteToSignup) {
      return safeNavigate("/auth/signup");
    }

    const resetPassword = params.get("resetPassword");
    if (resetPassword) {
      return safeNavigate("/auth/reset-password");
    }

    const backToLogin = params.get("backToLogin");
    if (backToLogin) {
      return safeNavigate("/auth/login");
    }
  } catch (error) {
    console.error("Failed to parse token from URL", error);
  }

  if (!userCredentials) {
    return; // Don't navigate if there are no credentials
  }

  if (!userCredentials?.verified) {
    return safeNavigate("/auth/verification-pending");
  }

  if (!userCredentials?.betaApproved) {
    return safeNavigate("/auth/beta-pending");
  }

  if (!userDetails || !userDetails?.onboarded) {
    return safeNavigate("/auth/user-details");
  }

  const subscriptionStatus = subscriptionInfo?.subscriptionStatus;
  const isActiveSubscription =
    subscriptionStatus === SubscriptionStatus.ACTIVE ||
    subscriptionStatus === SubscriptionStatus.PENDING_CHANGE ||
    subscriptionStatus === SubscriptionStatus.PENDING_CANCEL;

  if (!isActiveSubscription) {
    if (intendedPlan) {
      return safeNavigate(`/pricing?checkout=true`);
    }
    return safeNavigate("/pricing");
  }

  return safeNavigate("/workspace");
}

export function hasAccessToWorkspace(userSubscription: ISubscriptionInfo) {
  return (
    userSubscription?.subscriptionStatus === SubscriptionStatus.ACTIVE ||
    userSubscription?.subscriptionStatus ===
      SubscriptionStatus.PENDING_CHANGE ||
    userSubscription?.subscriptionStatus === SubscriptionStatus.PENDING_CANCEL
  );
}
