import { forwardRef, useEffect } from "react";
import {
  Box,
  Grid,
  ListItem,
  Typography,
  useTheme,
  keyframes,
  lighten,
  CircularProgress,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { ExpertAvatar, PrimaryIcon } from "../../../../../ui-elements";
import { Close } from "../../../../../../icons";
import { clearActiveJobsForChat } from "../../../../../../features/chats/chatSlice";

const bubbleSize = "6px";

const WorkingCell = forwardRef((props, ref) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const chatSlice = useAppSelector((state) => state.chat);
  const activeChat = chatSlice.activeChat;
  const activeExpert = useAppSelector((state) => state.experts.activeExpert);
  const expertColor = activeExpert?.color || theme.palette.primary.main;

  const shimmer = keyframes`
    0% { background-position: 200% 0; }
    100% { background-position: -200% 0; }
  `;

  useEffect(() => {
    //console.log("WorkingCell activeChat: ", activeChat);
  }, [activeChat]);

  const handleCancelClicked = () => {
    dispatch(clearActiveJobsForChat(activeChat._id));
  };

  return (
    <ListItem>
      <Box
        ref={ref}
        display={"flex"}
        flexDirection={"row"}
        width="100%"
        minHeight="80px"
        justifyContent={"flex-start"}
        alignItems={"center"}
        style={{
          marginTop: "-18px",
          marginBottom: "-18px",
          paddingBottom: "8px",
          paddingRight: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "16px",
            padding: "0px",
            marginTop: "12px",
            marginLeft: "0px",
            paddingTop: "5px",
            paddingBottom: "5px",
            position: "relative",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            padding="0px"
          >
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <ExpertAvatar expert={activeExpert} backgroundSize={18} />
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "0.85rem",
                  color: theme.palette.text.secondary,
                }}
              >
                {activeExpert?.name}
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "6px",
              padding: "8px",
              paddingRight: "12px",
              marginTop: "6px",
              borderRadius: "16px",
              alignItems: "center",
            }}
          >
            {/* <PrimaryIcon
              iconName={"Close"}
              icon={<Close />}
              defaultColorOverride={expertColor}
              handleIconClick={handleCancelClicked}
            /> */}

            {/* <Box
              sx={{
                width: bubbleSize,
                height: bubbleSize,
                borderRadius: "50%",
                backgroundColor: expertColor,
                animation: "pulse 1.0s infinite",
                animationDelay: "0ms",
              }}
            />
            <Box
              sx={{
                width: bubbleSize,
                height: bubbleSize,
                borderRadius: "50%",
                backgroundColor: expertColor,
                animation: "pulse 1.0s infinite",
                animationDelay: "100ms",
              }}
            />
            <Box
              sx={{
                width: bubbleSize,
                height: bubbleSize,
                borderRadius: "50%",
                backgroundColor: expertColor,
                animation: "pulse 1.0s infinite",
                animationDelay: "200ms",
              }}
            /> */}
            <CircularProgress
              size={12}
              thickness={8}
              sx={{
                marginLeft: "-5px",
                color: expertColor,
              }}
            />
            <Typography
              sx={{
                background: `linear-gradient(90deg, ${expertColor} 25%, ${lighten(
                  expertColor,
                  0.7
                )} 50%, ${expertColor} 75%)`,
                backgroundSize: "200% 100%",
                animation: `${shimmer} 3s linear infinite 2s`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: "0.8rem",
                fontWeight: 800,
                whiteSpace: "nowrap",
              }}
            >
              {activeChat.isWorkingMessage !== ""
                ? activeChat.isWorkingMessage
                : "Working"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
});

export default WorkingCell;
