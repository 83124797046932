import {
  INewMessagePayload,
  INewPromptPayload,
  IMessage,
  IUserDetails,
  IPrompt,
} from "../../types";
import { sendMessage, updateMessage } from "../chats/messagesSlice";
import { createPrompt, updatePrompt } from "../chats/promptsSlice";
import {
  cancelEditPrompt,
  setCurrentMessageText,
  setCurrentPromptText,
} from "../chats/workspaceSlice";

export async function executeSendMessage(
  chatId: string,
  message: string,
  userDetails: IUserDetails,
  dispatch: any,
  messageFiles: File[]
) {
  const payload = {
    chatId: chatId,
    message: message,
    senderId: userDetails._id,
    senderType: "User",
    senderVoiceId: userDetails.voice,
    messageFiles: messageFiles,
  } as INewMessagePayload;

  dispatch(setCurrentMessageText(""));
  return await dispatch(sendMessage(payload));
}

export async function executeUpdateMessage(message: IMessage, dispatch: any) {
  dispatch(setCurrentMessageText(""));
  return await dispatch(updateMessage(message));
}

export async function executeCreatePrompt(
  cacheId: string,
  message: string,
  dispatch: any
) {
  const payload = {
    cacheId: cacheId,
    message: message,
  } as INewPromptPayload;
  dispatch(setCurrentPromptText(""));
  return await dispatch(createPrompt(payload));
}

export async function executeUpdatePrompt(
  updatedPrompt: IPrompt,
  dispatch: any
) {
  dispatch(cancelEditPrompt());
  return await dispatch(updatePrompt(updatedPrompt));
}
