import React, { useState, useEffect } from "react";
import { alpha, Fab, useTheme, Fade } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { UtilityService } from "../../../../../services/UtilityService";

const ScrollToBottomButton = ({ onClick, visible }) => {
  const theme = useTheme();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    let timeout;
    if (visible) {
      timeout = setTimeout(() => {
        setShowButton(true);
      }, 500);
    } else {
      setShowButton(false);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [visible]);

  const isMobile = UtilityService.getIsMobile();

  return (
    <Fade in={showButton} timeout={450}>
      <Fab
        size="small"
        color="primary"
        aria-label="scroll to bottom"
        onClick={onClick}
        sx={{
          position: "absolute",
          bottom: "12px",
          right: "12px",
          zIndex: 0,
          backdropFilter: "blur(2.5px)",
          border: `1px solid ${
            theme.palette.mode === "dark"
              ? alpha(theme.palette.colors.grayScale[300], 0.8)
              : alpha(theme.palette.colors.grayScale[400], 0.3)
          }`,
          backgroundColor: alpha(theme.palette.background.backdrop, 0.5),
          "&:hover": !isMobile
            ? {
                backgroundColor: alpha(theme.palette.background.backdrop, 1.0),
              }
            : {},
        }}
      >
        <KeyboardArrowDown
          sx={{
            color:
              theme.palette.mode === "dark"
                ? alpha(theme.palette.colors.grayScale[300], 0.8)
                : alpha(theme.palette.colors.grayScale[400], 0.5),
          }}
        />
      </Fab>
    </Fade>
  );
};

export default ScrollToBottomButton;
