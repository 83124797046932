import React, { useState, useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import PrimaryIcon from "../ui-elements/PrimaryIcon";
import { ColorBrush, ColorHandle } from "../../icons";
import { UtilityService } from "../../services/UtilityService";

interface ColorPickerProps {
  initialColor: string;
  onColorChange: (color: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  initialColor,
  onColorChange,
}: ColorPickerProps) => {
  const [pickedColor, setPickedColor] = useState(initialColor);
  const [isHovered, setIsHovered] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const theme = useTheme();
  const isMobile = UtilityService.getIsMobile();

  useEffect(() => {
    setPickedColor(initialColor);
  }, [initialColor]);

  const handleColorChange = () => {
    const newColor = "random"; // You might want to implement a more sophisticated color selection logic here
    setPickedColor(newColor);
    onColorChange(newColor);
  };

  const handleMouseEnter = () => {
    if (!isMobile) setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovered(false);
      setIsMouseDown(false);
    }
  };

  const handleMouseDown = () => {
    if (!isMobile) setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    if (!isMobile) {
      setIsMouseDown(false);
      setIsHovered(false);
    }
  };

  return (
    <Box
      width="40px"
      height="40px"
      sx={{
        display: "flex",
        borderRadius: "8px",
        "&:hover": !isMobile && {
          backgroundColor: theme.palette.background.buttonHighlighted,
          transition: "padding 0.2s ease-in-out",
        },
      }}
      onClick={handleColorChange}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
    >
      <PrimaryIcon
        iconName="Random Color"
        icon={<ColorHandle />}
        size="40px"
        animated={false}
        sx={{
          transform: isMouseDown
            ? "scale(0.8)"
            : isHovered
            ? "scale(1.1)"
            : "scale(1)",
          transition: "transform 0.05s ease-in-out",
        }}
      />
      <PrimaryIcon
        iconName="Random Color"
        defaultColorOverride={pickedColor}
        icon={<ColorBrush />}
        size="40px"
        disabled={true}
        sx={{
          left: "-44px",
          transform: isMouseDown
            ? "scale(0.8)"
            : isHovered
            ? "scale(1.1)"
            : "scale(1)",
          transition: "transform 0.05s ease-in-out",
        }}
        animated={false}
      />
    </Box>
  );
};

export default ColorPicker;
