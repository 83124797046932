import React, { ReactElement, useState, useEffect, useRef } from "react";
import {
  Tooltip,
  useTheme,
  Box,
  Typography,
  Fade,
  darken,
} from "@mui/material";
import { ToolTipBase } from "../../../icons";
import { useAppSelector } from "../../../app/store";
import { getTooltipDescription } from "./customTooltipData";

interface CustomTooltipProps {
  title: string;
  description?: string;
  icon?: ReactElement;
  children: ReactElement;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  title = "Tooltip",
  icon = <ToolTipBase />,
  description,
  children,
}) => {
  const theme = useTheme();
  const themeSlice = useAppSelector((state) => state.theme);
  const isDarkMode = themeSlice.isDarkMode;
  const expertSlice = useAppSelector((state) => state.experts);
  const activeExpert = expertSlice.activeExpert;
  const expertColor = activeExpert?.color;
  const textColor = expertColor ? "white" : theme.palette.text.primary;
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef({ title, description, icon });
  const customDescription = getTooltipDescription(title);
  const finalDescription = description || customDescription;
  const hasDescription =
    finalDescription &&
    finalDescription !== undefined &&
    finalDescription !== "";

  useEffect(() => {
    contentRef.current = { title, description: finalDescription, icon };
  }, [title, description, customDescription, icon]);

  const handleOpen = () => {
    setIsVisible(true);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const tooltipContent = (
    <Box
      sx={{
        borderRadius: "16px",
        p: hasDescription ? "12px" : "2px",
        // minWidth: 200,
        // maxWidth: 300,
      }}
    >
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        {contentRef.current.icon && hasDescription && (
          <Box
            sx={{
              height: "24px",
              width: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "2px",
              paddingBottom: "4px",
              marginLeft: "-4px",
              marginRight: "4px",
              color: textColor,
              borderRadius: "50%",
            }}
          >
            {React.cloneElement(contentRef.current.icon, {
              style: { width: "100%", height: "100%" },
            })}
          </Box>
        )}
        <Typography
          variant={hasDescription ? "h6" : "body1"}
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: hasDescription ? "1rem" : "0.875rem",
            fontWeight: hasDescription ? 900 : 500,
            color: textColor,
          }}
        >
          {contentRef.current.title}
        </Typography>
      </Box>
      {contentRef.current.description && (
        <Typography
          variant="body1"
          fontSize="0.75rem"
          mt={hasDescription ? "4px" : "0px"}
          sx={{ color: textColor }}
        >
          {contentRef.current.description}
        </Typography>
      )}
    </Box>
  );

  const backgroundColor =
    expertColor && hasDescription
      ? expertColor
      : darken(theme.palette.text.secondary, 0.5);

  return (
    <Tooltip
      title={tooltipContent}
      open={isVisible}
      onOpen={handleOpen}
      onClose={handleClose}
      arrow
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 300 }}
      componentsProps={{
        tooltip: {
          sx: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: backgroundColor,
            color: "text.primary",
            borderRadius: "10px",
            boxShadow: isDarkMode
              ? "0px 4px 20px 20px rgba(0, 0, 0, 0.55)"
              : "0px 4px 20px 20px rgba(0, 0, 0, 0.05)",
            "& .MuiTooltip-arrow": {
              color: backgroundColor,
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
