import React, { useRef, useEffect, useState } from "react";
import { Box, List, ListItem, useMediaQuery, useTheme } from "@mui/material";
import PromptCell from "./PromptCell";
import InstructionPanel from "../../../../popups/instruction-panel/InstructionPanel";
import {
  deletePrompt,
  updatePrompt,
} from "../../../../../features/chats/promptsSlice";
import ScrollContainer from "../ScrollContainer";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { IPrompt } from "../../../../../types/index";
import { doAction } from "../../../../../features/commands/commandsSlice";
import ScrollHeader from "../ScrollHeader";
import useScreenSize from "../../../../ui-elements/useScreenSize";
import { UtilityService } from "../../../../../services/UtilityService";
import { cacheInstructionPanelData } from "../../../../popups/instruction-panel/instructionPanelData";
import {
  ActiveView,
  cancelEditPrompt,
  startEditingPrompt,
  updateActiveView,
} from "../../../../../features/chats/workspaceSlice";

const CacheView = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const screenSize = useScreenSize();

  const workspaceSlice = useAppSelector((state) => state.workspace);
  const activeLayout = workspaceSlice.activeLayout;
  const promptsSlice = useAppSelector((state) => state.prompts.prompts);
  const cacheSlice = useAppSelector((state) => state.cache);
  const activeExpert = useAppSelector((state) => state.experts.activeExpert);

  const [selectedCell, setSelectedCell] = useState("");
  const [prompts, setPrompts] = useState(promptsSlice);
  const scrollRef: React.RefObject<HTMLDivElement> = useRef(null);

  const handleDeleteClicked = ({ prompt = null, deleteAll = false }) => {
    if (promptsSlice === null || promptsSlice.length === 0) return;

    if (prompt) {
      dispatch(deletePrompt(prompt._id));
      return;
    }

    if (deleteAll) {
      const actionData = { message: "", command: "@clearCache" };
      dispatch(doAction(actionData));
    }
  };

  const handleEditClicked = (prompt: IPrompt) => {
    dispatch(startEditingPrompt(prompt));
  };

  const handleCancelEdit = () => {
    dispatch(cancelEditPrompt());
    const activeView = workspaceSlice.activeView;
    if (activeView !== ActiveView.CACHE) {
      dispatch(updateActiveView(ActiveView.CHAT));
    }
  };

  useEffect(() => {
    setPrompts(promptsSlice);
    if (prompts != null && prompts.length > 0) {
      scrollRef.current?.scrollIntoView({ behavior: "auto" });
    }
  }, [promptsSlice]);

  useEffect(() => {
    // console.log("cacheSlice", cacheSlice);
  }, [cacheSlice.activeCache]);

  useEffect(() => {
    if (promptsSlice) {
      const sortedPrompts = [...promptsSlice].sort((a, b) => {
        const aDate = new Date(a.createdAt);
        const bDate = new Date(b.createdAt);
        return bDate.getTime() - aDate.getTime();
      });

      setPrompts(sortedPrompts);
    }

    if (prompts != null && prompts.length > 0) {
      scrollRef.current?.scrollIntoView({ behavior: "auto" });
    }
  }, [promptsSlice]);

  const handleCheckboxChecked = async (updatedPrompt: IPrompt) => {
    await dispatch(updatePrompt(updatedPrompt));
  };

  const isMobile = UtilityService.getIsMobile();
  const isSmallHeight = useMediaQuery("(max-height:600px)");

  return (
    <Box
      sx={{
        ...(activeLayout === 3 || (isMobile && window.innerWidth < 768)
          ? {
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }
          : {
              position: "fixed",
              top: "16px",
              bottom: 0,
              right: "16px",
              width: `${theme.rightPanelWidth - 16}px`,
              display: "flex",
              flexDirection: "column",
              marginLeft: "16px",
              marginTop: "64px",
              marginBottom: "16px",
              alignContent: "space-between",
              alignItems: "space-between",
              justifyContent: "space-between",
            }),
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: `100%`,
          backgroundColor: theme.palette.background.paper,
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ScrollHeader
          title={"Cache"}
          handleDeleteClicked={() =>
            handleDeleteClicked({ prompt: null, deleteAll: true })
          }
          showDelete={
            prompts != null && prompts != undefined && prompts.length > 0
          }
        />
        <ScrollContainer
          sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
        >
          {prompts != null && prompts.length > 0 ? (
            <List sx={{ marginTop: "-24px" }}>
              {prompts.map((prompt) => (
                <ListItem
                  key={prompt._id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "0px",
                    paddingLeft: "0px",
                  }}
                >
                  <PromptCell
                    key={prompt._id}
                    handleDelete={() => handleDeleteClicked({ prompt: prompt })}
                    handleEdit={handleEditClicked}
                    handleCancelEdit={handleCancelEdit}
                    prompt={prompt}
                    onSelect={() =>
                      setSelectedCell(
                        selectedCell === prompt._id ? "" : prompt._id
                      )
                    }
                    onClickAway={() =>
                      selectedCell === prompt._id ? setSelectedCell("") : null
                    }
                    handleCheckboxChecked={handleCheckboxChecked}
                    adjustCheckboxForMediumScreen={screenSize.isMedium}
                    expertColor={activeExpert?.color}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: isSmallHeight ? 0 : "16px",
                paddingLeft: isSmallHeight ? 0 : "6.5px",
              }}
            >
              <InstructionPanel {...cacheInstructionPanelData} />
            </Box>
          )}
          <div ref={scrollRef} />
        </ScrollContainer>
      </Box>
    </Box>
  );
};

export default CacheView;
