import { FileProcessor } from "./FileProcessor"; // Assuming this is the correct path for the FileProcessor class
import { uploadImage } from "../routes/userRoutes";

export const processUserImagePicked = async (file: File) => {
  try {
    // Validate file type
    const validImageTypes = ["jpg", "jpeg", "png", "webp"];
    if (
      !validImageTypes.includes(file.type.split("/").pop()?.toLowerCase() || "")
    ) {
      throw new Error("Invalid file type. Only JPG and PNG are allowed.");
    }

    // Use FileProcessor to resize and convert the image if necessary
    const processedImage = await FileProcessor.resizeAndConvertImage(
      file,
      file.name
    );

    // Call the API endpoint to upload the image
    const response = await uploadImage(processedImage);

    if (response.data.success) {
      return {
        success: true,
        url: response.data.url, // The URL of the uploaded image
      };
    } else {
      throw new Error("Image upload failed.");
    }
  } catch (error) {
    console.error("Error processing user image:", error);
    return {
      success: false,
      error: error instanceof Error ? error.message : "Image upload failed",
    };
  }
};
