import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import BlockTitle from "../../../../ui-elements/BlockTitle";
import { IUserPreferences } from "../../../../../types";
import { useAppDispatch } from "../../../../../app/store";
import CustomCheckbox from "../../../../ui-elements/CustomCheckbox";
import { updateUserPreferences } from "../../../../../features/users/userPreferencesSlice";

interface NewsletterBlockProps {
  userPreferences: IUserPreferences | null;
}

const NewsletterBlock = ({ userPreferences }: NewsletterBlockProps) => {
  const dispatch = useAppDispatch();
  const isSubscribed = userPreferences?.subscribedToNewsletter;
  const [isChecked, setIsChecked] = useState(isSubscribed);

  useEffect(() => {
    //console.log("isSubscribed changed in useEffect", isSubscribed);
  }, [isSubscribed]);

  const handleCheckboxChange = async (event) => {
    const newValue = event.target.checked;
    const updatedUser = {
      ...userPreferences,
      subscribedToNewsletter: newValue,
    };

    setIsChecked(newValue);
    console.log("updatedUser", updatedUser);
    dispatch(updateUserPreferences(updatedUser));
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Box width="70%">
        <BlockTitle
          title={"Newsletter"}
          subtitle={isChecked ? "Subscribed" : "Unsubscribed"}
          description="Receive updates, news, and exclusive offers from Virtual Experts."
          overrideMarginTop={true}
        />
      </Box>
      <Box display="flex" alignItems="center">
        <CustomCheckbox
          isChecked={isSubscribed}
          setIsChecked={handleCheckboxChange}
        />
      </Box>
    </Box>
  );
};

export default NewsletterBlock;
