import React, { useEffect, useRef, useState } from "react";
import {
  alpha,
  Box,
  Button,
  Divider,
  lighten,
  Typography,
  useTheme,
} from "@mui/material";
import SectionBase from "../../workspace-page/content-views/profile-view/sections/SectionBase";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { useNavigate } from "react-router-dom";
import {
  cancelPlan,
  createCustomerPortalSession,
  revertScheduledCancel,
  revertScheduledDowngrade,
  setSettingToCancelled,
} from "../../../../features/users/userSubscriptionSlice";
import { SubscriptionStatus } from "../../../../types/user/ISubscriptionInfo";
import Banner from "./blocks/Banner";
import { CurrentSubscription } from "./blocks/CurrentSubscription";
import CancelPopup from "../../../popups/CancelPopup";
import { fetchIsUserDirty } from "../../../../features/users/isUserDirtySlice";
import UsageSection from "../usage-section/UsageSection";
import { UtilityService } from "../../../../services/UtilityService";

const SubscriptionSection: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const accountPageSlice = useAppSelector((state) => state.accountPage);
  const userSubscriptionSlice = useAppSelector(
    (state) => state.userSubscription
  );

  const usageDataReport = accountPageSlice.usageDataReport;
  const containerRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<number | null>(null);

  const shouldShowCancellingOnStart = () => {
    if (userSubscriptionSlice.settingToCancelled === true) {
      return true;
    }

    if (!userSubscriptionSlice.userSubscription) {
      return false;
    }

    if (
      userSubscriptionSlice.userSubscription?.subscriptionStatus ===
      SubscriptionStatus.PENDING_CANCEL
    ) {
      return true;
    }

    return false;
  };

  const [showCancelling, setShowCancelling] = useState(
    shouldShowCancellingOnStart()
  );

  const [showDowngrading, setShowDowngrading] = useState(
    userSubscriptionSlice.userSubscription?.subscriptionStatus ==
      SubscriptionStatus.PENDING_CHANGE
  );
  const [cancelPopupOpen, setCancelPopupOpen] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      //const width = containerRef?.current?.clientWidth as number;
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  useEffect(() => {
    pollForIsUserDirty();
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    setShowCancelling(shouldShowCancellingOnStart());
  }, [userSubscriptionSlice.settingToCancelled]);

  const pollForIsUserDirty = async () => {
    await dispatch(fetchIsUserDirty());
    timeoutRef.current = window.setTimeout(pollForIsUserDirty, 5000);
  };

  useEffect(() => {
    setShowCancelling(shouldShowCancellingOnStart());

    setShowDowngrading(
      userSubscriptionSlice.userSubscription.subscriptionStatus ===
        SubscriptionStatus.PENDING_CHANGE
    );

    setShowProcessing(false);
  }, [userSubscriptionSlice.userSubscription.subscriptionStatus]);

  const handleInvoiceClick = () => {
    dispatch(createCustomerPortalSession());
  };

  const handleCancelConfirm = async () => {
    dispatch(setSettingToCancelled(true));
    setCancelPopupOpen(false);
    setShowCancelling(true);
    setShowProcessing(true);
    await dispatch(cancelPlan());

    // setShowCancelling(shouldShowCancellingOnStart());
  };

  const isPremiumPlan = () => {
    return userSubscriptionSlice.userSubscription?.planName === "Premium";
  };

  const handleChangedMindConfirm = () => {
    if (showCancelling) {
      setShowProcessing(true);
      dispatch(revertScheduledCancel());
    }

    if (showDowngrading) {
      setShowProcessing(true);
      dispatch(revertScheduledDowngrade());
    }
  };

  const isMobile = UtilityService.getIsMobile();

  return (
    <Box
      ref={containerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        height: "auto",
        width: "100%",
      }}
    >
      <Banner
        showBanner={showCancelling}
        theme={theme}
        usageDataReport={usageDataReport}
        bannerType="cancel"
        handleActionConfirm={handleChangedMindConfirm}
        showProcessing={showProcessing}
      />
      <Banner
        showBanner={showDowngrading}
        theme={theme}
        usageDataReport={usageDataReport}
        bannerType="downgrade"
        handleActionConfirm={handleChangedMindConfirm}
        showProcessing={showProcessing}
      />
      <SectionBase title="Subscription">
        <CurrentSubscription
          userSubscriptionSlice={userSubscriptionSlice}
          usageDataReport={usageDataReport}
          theme={theme}
        />
        <Box
          paddingBottom={"16px"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Divider
            sx={{
              width: "100%",
              marginTop: "16px",
              marginBottom: "16px",
              backgroundColor: alpha(theme.palette.colors.grayScale[500], 0.05),
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Button
              variant="contained"
              disableRipple
              sx={{
                textTransform: "none",
                marginLeft: "16px",
                borderRadius: "8px",
                boxShadow: "none",
                color: isPremiumPlan()
                  ? alpha(theme.palette.colors.grayScale.white, 0.5)
                  : theme.palette.colors.grayScale.white,
                backgroundColor: isPremiumPlan()
                  ? theme.palette.colors.grayScale[500]
                  : lighten(theme.palette.colors.blue[500], 0.4),
                "&:hover": !isMobile
                  ? {
                      boxShadow: "none",
                      backgroundColor: isPremiumPlan()
                        ? alpha(theme.palette.colors.grayScale[500], 0.6)
                        : lighten(theme.palette.colors.blue[500], 0.3),
                      transform: isPremiumPlan() ? undefined : "scale(1.02)",
                    }
                  : undefined,
                "&:active": {
                  transform: "scale(0.98)",
                },
              }}
              onClick={() => navigate("/pricing")}
            >
              {isPremiumPlan() ? "Manage Plan" : "Upgrade"}
            </Button>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "16px",
                marginRight: "16px",
              }}
            >
              <Typography
                sx={{
                  textTransform: "none",
                  marginRight: showCancelling ? "2px" : "16px",
                  fontSize: "12px",
                  fontWeight: 300,
                  backgroundColor: `transparent`,
                  opacity: 0.7,
                  textDecoration: "underline",
                  "&:hover": !isMobile
                    ? {
                        backgroundColor: `transparent`,
                        opacity: 1,
                        cursor: "pointer",
                      }
                    : undefined,
                }}
                onClick={handleInvoiceClick}
              >
                billing
              </Typography>
              {!showCancelling && (
                <Typography
                  sx={{
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: 300,
                    backgroundColor: `transparent`,
                    opacity: 0.7,
                    textDecoration: "underline",
                    "&:hover": !isMobile
                      ? {
                          backgroundColor: `transparent`,
                          opacity: 1,
                          cursor: "pointer",
                        }
                      : undefined,
                  }}
                  onClick={() => setCancelPopupOpen(true)} // Open the popup
                >
                  cancel
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </SectionBase>
      <UsageSection usageDataReport={usageDataReport} />
      <CancelPopup
        open={cancelPopupOpen}
        onClose={() => setCancelPopupOpen(false)}
        onConfirm={handleCancelConfirm}
        featureCancel={true}
      />
    </Box>
  );
};

export default SubscriptionSection;
