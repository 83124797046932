// CommandCell.tsx
import React from "react";
import { Divider, ListItem, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ICommand } from "../../../../../../types/index";
import { getIconForCommand } from "../../../../../../features/commands/commandSource";
import { PrimaryIcon } from "../../../../../ui-elements";
import { IExpert } from "../../../../../../types";
import { IMessageBarState } from "../state-setters/MessageBarState";
import { UtilityService } from "../../../../../../services/UtilityService";

interface CommandCellProps {
  command: ICommand;
  index: number;
  messageBarState: IMessageBarState;
  expert?: IExpert;
  selectCellAtIndex: (index: number) => void;
}

const CommandCell: React.FC<CommandCellProps> = ({
  command,
  index,
  messageBarState,
  expert,
  selectCellAtIndex,
}) => {
  const theme = useTheme();
  const isMobile = UtilityService.getIsMobile();

  return (
    <ListItem
      key={index}
      sx={{
        width: "100%",
        display: "flex",
        marginBottom: "0px",
        marginTop: "4px",
        color:
          index == messageBarState.highlightedSuggestionsIndex && !isMobile
            ? theme.palette.text.primary
            : theme.palette.text.secondary,
        backgroundColor:
          index == messageBarState.highlightedSuggestionsIndex && !isMobile
            ? theme.palette.mode === "dark"
              ? theme.palette.colors.grayScale[600]
              : theme.palette.colors.grayScale[75]
            : "transparent",
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingBottom: 0,
        paddingTop: "8px",
        borderRadius: "10px",
        cursor: "pointer",
        "&:hover": !isMobile
          ? {
              backgroundColor:
                theme.palette.mode === "dark"
                  ? theme.palette.colors.grayScale[600]
                  : theme.palette.colors.grayScale[75],
              color:
                index == messageBarState.highlightedSuggestionsIndex
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
            }
          : {},
      }}
      onMouseDown={() => {
        messageBarState.isClickingCommand = true;
        selectCellAtIndex(index);
      }}
    >
      <Box
        sx={{
          width: "100%",
          borderRadius: "4px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PrimaryIcon
            iconName={command.command}
            icon={getIconForCommand(command.command)}
            backgroundColorOverride={
              messageBarState.highlightedSuggestionsIndex === index && !isMobile
                ? expert?.color
                : theme.palette.background.paper
            }
            highlightedColorOverride={
              messageBarState.highlightedSuggestionsIndex === index && !isMobile
                ? expert?.color
                : theme.palette.background.paper
            }
            size="30px"
            selectedColorOverride={theme.palette.colors.grayScale.white}
            forceSelected={
              messageBarState.highlightedSuggestionsIndex === index && !isMobile
            }
            animated={false}
            sx={{
              borderRadius: "20px",
              width: "36px",
              height: "36px",
              marginRight: "12px",
              marginTop: "8px",
              marginBottom: "8px",
              marginLeft: "4px",
              color: theme.palette.text.primary,
            }}
          />

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>
              {command.command}
            </Typography>
            <Typography
              sx={{ marginTop: "4px", fontSize: "12px", paddingRight: "8px" }}
            >
              {command.description}
            </Typography>
          </Box>
        </Box>
        <Divider
          sx={{
            marginTop: "16px",
            marginBottom: 0,
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.colors.grayScale[900]
                : theme.palette.colors.grayScale[50],
          }}
        />
      </Box>
    </ListItem>
  );
};

export default CommandCell;
