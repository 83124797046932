import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import { clearMessages, setPlaceholderFilesForMessage } from "./messagesSlice";
import {
  handleAxiosError,
  handleAxiosErrorWithStatus,
} from "../../app/ErrorHandler";
import { IChat, IExpert, IMessage } from "../../types/index";
import { RootState } from "../../app/store";
import {
  IChatWorkingState,
  IOpenExistingChatPayload,
} from "../../types/chat/IChat";
import { openChat } from "./chat-processors/OpenExistingChat";
import { openNewChat } from "./chat-processors/OpenNewChat";
import { LocalStorageService } from "../../services/LocalStorageService";

const initialState = {
  loading: false,
  activeChat: null as IChat | null,
  chats: [] as IChat[],
  isSwitchingChats: false,
  error: "" as unknown,
};

export const openExistingChat = createAsyncThunk(
  "chat/openChat",
  async (
    openExistingChatPayload: IOpenExistingChatPayload,
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setIsSwitchingChats(true));
      const chatId = openExistingChatPayload.chatId;
      const switchingExperts = openExistingChatPayload.switchingExperts;
      await openChat(
        chatId,
        switchingExperts,
        dispatch,
        getState,
        rejectWithValue
      );
      dispatch(setIsSwitchingChats(false));
      return;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

// save chat if it has messages, then create and open a new chat
export const saveAndOpenNewChat = createAsyncThunk(
  "chat/saveAndOpenNewChat",
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setIsSwitchingChats(true));
      let state = getState() as RootState;
      const activeExpertBefore = state.experts.activeExpert as IExpert;
      const chat = await openNewChat(
        dispatch,
        getState,
        rejectWithValue,
        handleAxiosError
      );

      state = getState() as RootState;
      const activeExpertAfter = state.experts.activeExpert as IExpert;
      if (activeExpertBefore._id !== activeExpertAfter._id) {
        dispatch(setIsSwitchingChats(false));
        return;
      }

      await dispatch(fetchChatsForExpert(null));

      state = getState() as RootState;
      const finalExpertState = state.experts.activeExpert as IExpert;
      if (activeExpertBefore._id !== finalExpertState._id) {
        dispatch(setIsSwitchingChats(false));
        return;
      }

      dispatch(selectChat(chat));
      dispatch(setIsSwitchingChats(false));
      return chat;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const updateChat = createAsyncThunk(
  "chats/updateChat",
  async (updatedChat: IChat, { rejectWithValue }) => {
    try {
      const result = await api.updateChat(updatedChat);
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const fetchChatsForExpert = createAsyncThunk(
  "chat/fetchChatsForExpert",
  async (expert: IExpert | null, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const activeExpert = state.experts.activeExpert as IExpert;
      const activeExpertId = activeExpert?._id;

      const result = await api.fetchChatsForExpert(
        expert ? expert._id : activeExpertId
      );

      if (expert && expert._id === activeExpertId) {
        return;
      }

      dispatch(setChats(result.data));
      return;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const deleteChat = createAsyncThunk(
  "chat/deleteChat",
  async (chatId: string, { dispatch, getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const chat = state.chat.chats.find((chat) => chat._id === chatId);
    const currentChatState = chat?.chatState;

    try {
      const messages = state.messages.messages.filter(
        (message) => message.chatId === chatId
      );
      for (const message of messages) {
        LocalStorageService.remove(`${message._id}-placeholderFiles`);
      }
    } catch (e) {
      // didn't remove any cached placeholders
    }

    try {
      const chatStatePayload = {
        chatId: chatId,
        chatState: "deleting",
      };
      dispatch(setChatStateForChat(chatStatePayload));

      const result = await api.deleteChat(chatId);
      return result.data;
    } catch (error) {
      // revert state back to what it was before the delete
      const chatStatePayload = {
        chatId: chatId,
        chatState: currentChatState,
      };
      dispatch(setChatStateForChat(chatStatePayload));
      return rejectWithValue(handleAxiosErrorWithStatus(error));
    }
  }
);

export const resetChat = createAsyncThunk(
  "chat/resetChat",
  async (chatId: string, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setIsLoading(true));
      const state = getState() as RootState;
      try {
        const messages = state.messages.messages.filter(
          (message) => message.chatId === chatId
        );
        for (const message of messages) {
          LocalStorageService.remove(`${message._id}-placeholderFiles`);
        }
      } catch (e) {
        // didn't remove any cached placeholders
      }

      const result = await api.resetChat(chatId);
      dispatch(clearMessages());
      dispatch(setIsLoading(false));
      return result.data;
    } catch (error) {
      dispatch(setIsLoading(false));
      return rejectWithValue(handleAxiosErrorWithStatus(error));
    }
  }
);

export const clearActiveJobsForChat = createAsyncThunk(
  "chat/clearActiveJobsForChat",
  async (chatId: string, { dispatch, rejectWithValue }) => {
    try {
      const result = await api.clearActiveJobsForChat(chatId);
      dispatch(hydrateChat(chatId));
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const hydrateChat = createAsyncThunk(
  "chat/hydrateChat",
  async (chatId: string, { dispatch, getState, rejectWithValue }) => {
    try {
      const result = await api.hydrateChat(chatId);
      const chat = result.data;

      try {
        const state = getState() as RootState;
        const chatSlice = state.chat;
        const chats = chatSlice.chats;
        const activeChat = chatSlice.activeChat;

        const updatedChats = chats.map((c) => (c._id === chat._id ? chat : c));

        dispatch(setChats(updatedChats));

        if (activeChat?._id === chat._id) {
          dispatch(selectChat(chat));
        }

        const messages = state.messages.messages;
        for (let i = 0; i < messages.length; i++) {
          const message = messages[i] as IMessage;
          const placeholderFiles = await LocalStorageService.getFilesForKey(
            `${message._id}-placeholderFiles`
          );
          if (
            message.knowledgeDocs.length > 0 &&
            placeholderFiles?.length > 0
          ) {
          } else {
            if (placeholderFiles?.length > 0) {
              if (
                !message.placeholderFiles ||
                message.placeholderFiles === undefined ||
                message.placeholderFiles?.length === 0
              ) {
                dispatch(
                  setPlaceholderFilesForMessage({
                    messageId: message._id,
                    placeholderFiles,
                  })
                );
              }
            }
          }
        }

        return chat;
      } catch (error) {
        console.log("Error hydrating chat: ", error);
      }
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const updateChatWorkingState = createAsyncThunk(
  "chat/updateChatWorkingState",
  async (
    chatWorkingState: IChatWorkingState,
    { getState, dispatch, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const chatId = chatWorkingState.chatId;

      const updatedChats = state.chat.chats.map((chat) =>
        chat._id === chatId
          ? {
              ...chat,
              isWorking: chatWorkingState.isWorking,
              isWorkingMessage: chatWorkingState.isWorkingMessage,
            }
          : chat
      );

      dispatch(setChats(updatedChats));

      const activeChat = state.chat.activeChat;
      if (activeChat?._id === chatId) {
        const updatedActiveChat = {
          ...activeChat,
          isWorking: chatWorkingState.isWorking,
          isWorkingMessage: chatWorkingState.isWorkingMessage,
        };
        dispatch(selectChat(updatedActiveChat));
      }
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const chatSlice = createSlice({
  name: "chat",
  initialState: initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.loading = action.payload;
    },
    selectChat: (state, action) => {
      state.activeChat = action.payload;
    },
    clearChat: (state) => {
      state.loading = false;
      state.activeChat = null;
      state.chats = [];
      state.error = "";
    },
    setChatStateForChat: (state, action) => {
      const chatId = action.payload.chatId;
      const chatState = action.payload.chatState;
      const chat = state.chats.find((chat) => chat._id === chatId);
      if (chat) {
        chat.chatState = chatState;
      }

      if (state.activeChat?._id === chatId) {
        state.activeChat.chatState = chatState;
      }

      state.chats = state.chats.sort((a, b) => {
        if (a.chatState === "opening" || a.chatState === "open") return -1;
        if (b.chatState === "opening" || b.chatState === "open") return 1;
        return 0;
      });
    },
    setChats: (state, action) => {
      state.chats = action.payload;
      state.chats = state.chats.sort((a, b) => {
        if (a.chatState === "opening" || a.chatState === "open") return -1;
        if (b.chatState === "opening" || b.chatState === "open") return 1;
        return 0;
      });
    },
    setIsSwitchingChats: (state, action) => {
      state.isSwitchingChats = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(openExistingChat.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(openExistingChat.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(openExistingChat.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchChatsForExpert.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchChatsForExpert.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fetchChatsForExpert.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateChat.fulfilled, () => {
      // handle updating active chat
    });
    builder.addCase(deleteChat.fulfilled, () => {
      // handle deleting chat
    });
    builder.addCase(resetChat.fulfilled, (state, action) => {
      state.loading = false;
      state.activeChat = action.payload;
    });
    builder.addCase(resetChat.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const {
  setIsLoading,
  selectChat,
  clearChat,
  setChatStateForChat,
  setChats,
  setIsSwitchingChats,
} = chatSlice.actions;

export default chatSlice.reducer;
