import { useState, useEffect } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  alpha,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getDaysInMonth, format } from "date-fns";

const CustomDatePicker = ({
  selectedDate,
  onDateChange,
  backgroundColorOverride = null,
}) => {
  const theme = useTheme();
  const isValidDate =
    selectedDate instanceof Date && !isNaN(selectedDate.getTime());
  const [month, setMonth] = useState(isValidDate ? selectedDate.getMonth() : 0);
  const [year, setYear] = useState(
    isValidDate ? selectedDate.getFullYear() : new Date().getFullYear()
  );
  const [day, setDay] = useState(isValidDate ? selectedDate.getDate() : 1);
  const [days, setDays] = useState([]);

  const months = Array.from({ length: 12 }, (_, i) => i);
  const years = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i
  );

  useEffect(() => {
    if (isValidDate) {
      setMonth(selectedDate.getMonth());
      setYear(selectedDate.getFullYear());
      setDay(selectedDate.getDate());
    }
  }, [selectedDate]);

  useEffect(() => {
    const days = Array.from(
      { length: getDaysInMonth(new Date(year, month)) },
      (_, i) => i + 1
    );
    setDays(days);
  }, [month, year]);

  const handleDayChange = (event) => {
    setDay(event.target.value);
    onDateChange(new Date(year, month, event.target.value));
  };

  const handleMonthChange = (event) => {
    const newMonth = event.target.value;
    setMonth(newMonth);
    const maxDays = getDaysInMonth(new Date(year, newMonth));
    if (day > maxDays) {
      setDay(maxDays);
      onDateChange(new Date(year, newMonth, maxDays));
    } else {
      onDateChange(new Date(year, newMonth, day));
    }
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
    onDateChange(new Date(event.target.value, month, day));
  };

  const selectStyle = {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(
        theme.palette.text.placeholder,
        theme.palette.mode === "dark" ? 0.8 : 1.0
      ),
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "& .Mui-focused .MuiInputLabel-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiInputLabel-root": {
      backgroundColor: backgroundColorOverride
        ? backgroundColorOverride
        : theme.palette.background.backdrop,
      paddingRight: "5px",
      paddingLeft: "5px",
    },
    background: backgroundColorOverride
      ? backgroundColorOverride
      : theme.palette.background.backdrop,
    borderRadius: "8px",
    fontSize: "1rem",
    fontWeight: 600,
    height: "50px",
    color: theme.palette.text.primary,
  };

  const inputLabelStyle = {
    color: alpha(theme.palette.text.secondary, 0.6),
    fontWeight: 700,
    fontSize: "1rem",
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
    backgroundColor: backgroundColorOverride
      ? backgroundColorOverride
      : theme.palette.background.backdrop,
    paddingLeft: "11px",
    paddingRight: "11px",
    marginLeft: "-3px",
    borderRadius: "8px",
  };

  const DateInput = ({ label, value, onChange, options, width }) => (
    <Grid item width={width}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel sx={inputLabelStyle}>{label}</InputLabel>
        <Select value={value} onChange={onChange} sx={selectStyle}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );

  return (
    <Grid item width={"100%"}>
      <Grid container spacing={2}>
        <DateInput
          label="Month"
          value={month}
          onChange={handleMonthChange}
          options={months.map((m) => ({
            value: m,
            label: format(new Date(0, m), "MMM"),
          }))}
          width="100px"
        />
        <DateInput
          label="Day"
          value={day}
          onChange={handleDayChange}
          options={days.map((d) => ({ value: d, label: d }))}
          width="90px"
        />
        <DateInput
          label="Year"
          value={year}
          onChange={handleYearChange}
          options={years.map((y) => ({ value: y, label: y }))}
          width="110px"
        />
      </Grid>
    </Grid>
  );
};

export default CustomDatePicker;
