import React from "react";
import { Typography, Box, useTheme } from "@mui/material";

interface BlockTitleProps {
  title: string;
  subtitle?: string;
  description?: string;
  overrideMarginTop?: boolean;
  halfSize?: boolean;
}

const BlockTitle: React.FC<BlockTitleProps> = ({
  title,
  subtitle,
  description,
  overrideMarginTop,
  halfSize = false,
}) => {
  const theme = useTheme();
  return (
    <Box
      width={halfSize ? "50%" : "100%"}
      marginTop={"0px"}
      marginBottom={"4px"}
    >
      <Box display="flex" flexDirection="row" alignItems="center" gap="8px">
        <Typography
          fontWeight={800}
          color={theme.palette.text.primary}
        >{`${title}`}</Typography>
        {subtitle && (
          <Typography fontWeight={300} color={theme.palette.text.primary}>
            {subtitle}
          </Typography>
        )}
      </Box>
      {description && (
        <Typography
          fontSize="0.8rem"
          color={theme.palette.text.primary}
          fontStyle="italic"
          sx={{ opacity: 0.5 }}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default BlockTitle;
