// create a slice for the instruction popup to present information to the user
import { createSlice } from "@reduxjs/toolkit";

interface InstructionPopupState {
  show: boolean;
  title: string;
  lines: string[];
  icon: React.ReactNode;
}

const initialState: InstructionPopupState = {
  show: false,
  title: "",
  lines: [],
  icon: null,
};

export const instructionPopupSlice = createSlice({
  name: "instructionPopup",
  initialState,
  reducers: {
    showInstructionPopup: (state, action) => {
      state.title = action.payload.title;
      state.lines = action.payload.lines;
      state.icon = action.payload.icon;
      state.show = true;
    },
    hideInstructionPopup: (state) => {
      state.show = false;
    },
  },
});

export const { showInstructionPopup, hideInstructionPopup } =
  instructionPopupSlice.actions;

export default instructionPopupSlice.reducer;
