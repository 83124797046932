import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { IKnowledgeDoc, IMessage } from "../../../../../../types";
import SourcesPopup from "./SourcesPopup";
import CellFileCard from "../../../footer-bar/file-drawer/CellFileCard";
import { useAppDispatch } from "../../../../../../app/store";
import { setAPopupIsShowing } from "../../../../../../features/ui/confirmationPopupSlice";
import { IPlaceholderFile } from "../../../../../../types/chat/IMessage";

interface SourcesBlockProps {
  message: IMessage;
  knowledgeDocs: IKnowledgeDoc[];
}

const SourcesBlock: React.FC<SourcesBlockProps> = ({
  message,
  knowledgeDocs,
}) => {
  const dispatch = useAppDispatch();
  const [showSources, setShowSources] = useState(false);
  const [hasNonImageSources, setHasNonImageSources] = useState(false);
  const [placeholderFiles, setPlaceholderFiles] = useState<IPlaceholderFile[]>(
    []
  );

  const handleOpenSources = () => {
    if (placeholderFiles.length > 0) {
      return null;
    }

    dispatch(setAPopupIsShowing(true));
    setShowSources(true);
  };

  const handleCloseSources = () => {
    setShowSources(false);
    dispatch(setAPopupIsShowing(false));
  };

  useEffect(() => {
    window.onfocus = () => {
      dispatch(setAPopupIsShowing(showSources));
    };
    return () => {
      window.onfocus = null;
    };
  }, []);

  useEffect(() => {
    if (knowledgeDocs.length > 0) {
      setPlaceholderFiles([]);
      const hasNonImages = knowledgeDocs.some(
        (doc) =>
          !["png", "jpg", "jpeg"].includes(doc.knowledgeType.toLowerCase())
      );
      setHasNonImageSources(hasNonImages);
      return;
    }

    const placeholderFiles = [];
    if (message.placeholderFiles?.length > 0) {
      for (let i = 0; i < message.placeholderFiles.length; i++) {
        const placeholderFile = message.placeholderFiles[i];
        const isImage = placeholderFile.file.type.includes("image");
        if (!isImage) {
          setHasNonImageSources(true);
          placeholderFiles.push(placeholderFile);
        }
      }
    }
    if (placeholderFiles?.length > 0) {
      setPlaceholderFiles(placeholderFiles);
    }
  }, [message, knowledgeDocs, dispatch]);

  if (!knowledgeDocs || knowledgeDocs.length === 0) {
    if (placeholderFiles === undefined || placeholderFiles?.length <= 0) {
      return null;
    }
  }

  return (
    <Box
      sx={{
        flexDirection: "column",
        height: "100%",
        position: "relative",
        marginLeft: "-8px",
      }}
    >
      {hasNonImageSources && (
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            justifyContent: "center",
            marginTop: "-10px",
          }}
        >
          <CellFileCard
            onClick={handleOpenSources}
            fileCount={knowledgeDocs.length}
            placeholderFiles={placeholderFiles}
          />
        </Box>
      )}
      {showSources && (
        <SourcesPopup onClose={handleCloseSources} sources={knowledgeDocs} />
      )}
    </Box>
  );
};

export default SourcesBlock;
