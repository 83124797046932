import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import { handleAxiosError } from "../../app/ErrorHandler";
import { IUserDetails } from "../../types/user/IUserDetails";
import { LocalStorageService } from "../../services/LocalStorageService";
import { processUserImagePicked } from "../../services/processUserImagePicked";
import { RootState } from "../../app/store";

const initialState = {
  userDetails: LocalStorageService.get("userDetails") as IUserDetails | null,
};

export const loadUserDetails = createAsyncThunk(
  "userDetails/loadUserDetails",
  async (userDetailsId: string, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.loadUserDetails(userDetailsId);
      dispatch(setUserDetails(data));
      LocalStorageService.set("userDetails", data);
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  "userDetails/updateUserDetails",
  async (userDetails: IUserDetails, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await api.updateUserDetails(userDetails);
      dispatch(setUserDetails(data));
      LocalStorageService.set("userDetails", data);
      return data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const clearUserDetails = createAsyncThunk(
  "userDetails/clearUserDetails",
  async (_, { dispatch }) => {
    LocalStorageService.remove("userDetails");
    dispatch(setUserDetails(null));
  }
);

// setUserImage pass in file to await process image picked then hit api
export const updateUserImage = createAsyncThunk(
  "userDetails/setUserImage",
  async (file: File, { dispatch, rejectWithValue }) => {
    try {
      const result = await processUserImagePicked(file);
      const { url } = result;
      dispatch(setUserImage(url));
      return url;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState: initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setUserImage: (state, action) => {
      state.userDetails.picture = action.payload;
    },
  },
});

export const { setUserDetails, setUserImage } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
