import React, { ReactNode } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface SectionBaseProps {
  title: string;
  color?: string;
  children: ReactNode;
}

const SectionBase: React.FC<SectionBaseProps> = ({
  title,
  color,
  children,
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        alignContent: "flex-start",
        width: "100%",
        height: "auto",
        marginTop: "8px",
      }}
    >
      <Card
        elevation={0}
        sx={{
          width: "100%",
          borderRadius: isXs ? "0px" : "16px",
          margin: isXs ? "0px" : "16px",
          marginRight: isXs ? "4px" : "8px",
          marginBottom: { xs: "8px", sm: "20px" },
          marginTop: { xs: "32px", sm: "44px" },
          overflow: "visible",
          overflowX: "none",
          maxWidth: isXs ? "100%" : "600px",
          backgroundColor: theme.palette.background.backdrop,
        }}
      >
        <CardContent
          sx={{
            padding: "0px",
            display: "flex",
            alignContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            overflow: "visible",
            width: "100%",
            height: "auto",
          }}
        >
          <Typography
            sx={{
              position: "relative",
              fontWeight: 800,
              fontSize: "26px",
              marginLeft: "24px",
              top: "-17px",
              color: color ? color : theme.palette.colors.purple[500],
            }}
          >
            {title}
          </Typography>
        </CardContent>
        {(children && children) || (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              height: "100px",
              marginTop: "10px",
            }}
          >
            <CircularProgress
              sx={{
                color: color || theme.palette.primary.main,
              }}
            />
          </div>
        )}
      </Card>
    </Box>
  );
};

export default SectionBase;
