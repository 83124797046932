import React, { useState } from "react";
import { Typography, Paper, Box, useTheme, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PlanFeatures from "./PlanFeatures";
import {
  getCreditString,
  IPlan,
} from "../../../../../types/ui/pricing-page/IPlan";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { checkout } from "../../../../../features/users/userSubscriptionSlice";
import ConfirmDowngradePopup from "./ConfirmDowngradePopup";
import { setIntendedPlan } from "../../../../../features/ui/pages/pricingPageSlice";
import { UtilityService } from "../../../../../services/UtilityService";
import SubscribeButton from "./SubscribeButton";
import { usePlanLogic } from "../usePlanLogic";

interface ISubscriptionCellProps {
  plan: IPlan;
  isFeaturedPlan: boolean;
}

export const SubscriptionCell: React.FC<ISubscriptionCellProps> = ({
  plan,
  isFeaturedPlan,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isDowngrade, hasCurrentPlan } = usePlanLogic(plan);

  const userAuthSlice = useAppSelector((state) => state.userAuth);
  const userDetailsSlice = useAppSelector((state) => state.userDetails);
  const userCredentialsSlice = useAppSelector((state) => state.userCredentials);
  const userAuth = userAuthSlice.userAuth;
  const isLoggedIn = userAuth?.token !== null && userAuth?.token !== undefined;
  const isBetaApproved = userCredentialsSlice.userCredentials?.betaApproved;
  const isVerified = userCredentialsSlice.userCredentials?.verified;
  const isOnboarded = userDetailsSlice.userDetails?.onboarded;

  const [openDialog, setOpenDialog] = useState(false);

  const creditString = getCreditString(plan.creditLimit);
  const isDarkMode = theme.palette.mode === "dark";
  const accentColor = isFeaturedPlan
    ? theme.palette.primary.main
    : theme.palette.text.primary;

  const isMobile = UtilityService.getIsMobile();

  const handlePlanSelected = () => {
    if (hasCurrentPlan && isDowngrade) {
      setOpenDialog(true);
      return;
    }

    if (isLoggedIn) {
      if (!isBetaApproved) {
        return navigate("/auth/beta-pending");
      }

      if (!isVerified) {
        return navigate("/auth/verification-pending");
      }

      if (!isOnboarded) {
        return navigate("/auth/user-details");
      }

      dispatch(checkout(plan._id));
      navigate("/loading?upgrading=true");
    } else {
      dispatch(setIntendedPlan(plan));
      navigate("/auth/signup");
    }
  };

  const handleCloseDialog = (confirm: boolean) => {
    setOpenDialog(false);
    if (confirm) {
      dispatch(checkout(plan._id));
      navigate("/loading?upgrading=true");
    }
  };

  const planFeatures = [
    "AI chats organized by virtual expert",
    "Deeper context with per-expert chat memory",
    "Persist prompts between chats with Cache",
    "Talk to text transcription powered by Deepgram",
    "Listen to messages aloud with text-to-speech",
    "Multi-voice conversation playback",
    "Realtime web results powered by Brave",
    "PDF, PNG, JPEG, (and more soon) file reading support",
    "Customizable expert personalities and knowledge",
  ];

  return (
    <Box>
      <Paper
        elevation={2}
        sx={{
          maxWidth: "400px",
          p: { xs: "24px", sm: "16px", md: "24px" },
          borderRadius: "8px",
          transition: "0.3s",
          ...(isMobile
            ? {}
            : {
                "&:hover": {
                  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                },
              }),
        }}
      >
        <Typography
          variant="h6"
          fontSize={isFeaturedPlan ? "32px" : undefined}
          color={accentColor}
        >
          {plan.name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {plan.description}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            my: 2,
            mb: 2,
            color: isFeaturedPlan
              ? isDarkMode
                ? theme.palette.colors.purple[200]
                : theme.palette.colors.purple[900]
              : undefined,
          }}
        >
          ${plan.price}/mo
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
        >
          <Typography
            fontSize={isFeaturedPlan ? "42px" : "32px"}
            fontWeight={"600"}
            textAlign={"center"}
            color={accentColor}
          >
            {creditString}
          </Typography>
          <Typography
            fontSize={"0.85rem"}
            fontWeight={"500"}
            textAlign={"center"}
            color={theme.palette.text.secondary}
          >
            credits
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          marginTop={"16px"}
          marginBottom={"24px"}
          width={"100%"}
        >
          <SubscribeButton
            plan={plan}
            isFeaturedPlan={isFeaturedPlan}
            onSubscribe={handlePlanSelected}
          />
        </Box>
        <Divider sx={{ mt: 2 }} />
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          fontWeight={"900"}
          fontSize={"10px"}
          width={"100%"}
          textAlign={"center"}
          sx={{ mt: 4, opacity: 0.7 }}
        >
          - All Plans Include -
        </Typography>
        <PlanFeatures features={planFeatures} accentColor={accentColor} />
      </Paper>
      <ConfirmDowngradePopup open={openDialog} onClose={handleCloseDialog} />
    </Box>
  );
};

export default SubscriptionCell;
