import React, { useState } from "react";
import { Box, IconButton, useTheme } from "@mui/material";
import {
  ToolTipBase,
  ToolTipActiveDark,
  ToolTipActiveLight,
} from "../../icons";
import { UtilityService } from "../../services/UtilityService";

interface ToolTipButtonProps {
  onClick: () => void;
  disabled?: boolean;
  size?: string;
  sx?: React.CSSProperties;
}

const ToolTipButton: React.FC<ToolTipButtonProps> = ({
  onClick,
  disabled = false,
  size = "32px",
  sx = {},
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const theme = useTheme();
  const isMobile = UtilityService.getIsMobile();

  const handleMouseEnter = () => {
    if (!isMobile && !disabled) setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovered(false);
      setIsPressed(false);
    }
  };

  const handleMouseDown = () => {
    if (!isMobile && !disabled) setIsPressed(true);
  };

  const handleMouseUp = () => {
    if (!isMobile) {
      setIsPressed(false);
      setIsHovered(true);
    }
  };

  const getIconScale = () => {
    if (isPressed) return "scale(0.8)";
    if (isHovered) return "scale(1.1)";
    return "scale(1)";
  };

  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      sx={{
        marginRight: "8px",
        width: size,
        height: size,
        borderRadius: "6px",
        padding: "6px",
        backgroundColor: "transparent",
        "&:hover": !isMobile &&
          !disabled && {
            backgroundColor: theme.palette.background.buttonHighlighted,
          },
        ...sx,
      }}
      disableFocusRipple
      disableRipple
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "transform 0.1s ease-in-out",
          transform: isPressed
            ? "scale(0.8) translateY(-1px)"
            : "scale(1) translateY(-1px)",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "calc(100% + 2px)",
            height: "calc(100% + 2px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: theme.palette.text.secondary,
          }}
        >
          <ToolTipBase />
        </Box>
        <Box
          sx={{
            position: "absolute",
            width: "calc(100% + 2px)",
            height: "calc(100% + 2px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: isHovered || isPressed ? 1 : 0,
          }}
        >
          <ToolTipActiveDark />
        </Box>
        <Box
          sx={{
            position: "absolute",
            width: "calc(100% + 2px)",
            height: "calc(100% + 2px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "transform 0.1s ease-in-out, opacity 0.1s ease-in-out",
            transform: getIconScale(),
            opacity: isHovered || isPressed ? 1 : 0,
          }}
        >
          <ToolTipActiveLight />
        </Box>
      </Box>
    </IconButton>
  );
};

export default ToolTipButton;
