import React, { useEffect, useState } from "react";
import { List, ClickAwayListener, Container, Box } from "@mui/material";
import MessageCell from "./cells/MessageCell";
import InstructionPanel from "../../../../popups/instruction-panel/InstructionPanel";
import { useAppSelector, useAppDispatch } from "../../../../../app/store";
import ScrollContainer from "../ScrollContainer";
import ScrollHeader from "../ScrollHeader";
import { doAction } from "../../../../../features/commands/commandsSlice";
import {
  setActiveMessage,
  setEditing,
  playMessage,
  setShowFullQueue,
} from "../../../../../features/chats/messagesSlice";
import { IErrorMessage, IMessage } from "../../../../../types";
import NotificationPopup from "../../../../popups/NotificationPopup";
import {
  forceOverrideStopAudio,
  forceStopAudio,
} from "../../../../../features/audio/textToSpeechSlice";
import { PulseLoader } from "react-spinners";
import { setPreparingEdit } from "../../../../../features/chats/messagesSlice";
import { useTheme } from "@mui/material/styles";
import { CellState } from "./blocks/MCTypes";
import { setAPopupIsShowing } from "../../../../../features/ui/confirmationPopupSlice";
import { showErrorNotification } from "../../../../../features/ui/errorSlice";
import WorkingCell from "./cells/WorkingCell";
import useScreenSize from "../../../../ui-elements/useScreenSize";
import { useLocation } from "react-router-dom";
import { chatInstructionPanelData } from "../../../../popups/instruction-panel/instructionPanelData";
import { cancelEditPrompt } from "../../../../../features/chats/workspaceSlice";

const ChatView = () => {
  //#region use stuff
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const screenSize = useScreenSize();

  const userDetails = useAppSelector((state) => state.userDetails.userDetails);
  const activeExpert = useAppSelector((state) => state.experts?.activeExpert);
  const chatSlice = useAppSelector((state) => state.chat);
  const messagesSlice = useAppSelector((state) => state.messages);
  const textToSpeechSlice = useAppSelector((state) => state.textToSpeech);
  const workspaceSlice = useAppSelector((state) => state.workspace);

  const scrollRef = React.useRef<HTMLDivElement>(null);
  //#endregion

  //#region state stuff
  const [messages, setMessages] = useState(messagesSlice.messages);
  const [selectedCell, setSelectedCell] = useState("");
  const [isPreparingEdit, setIsPreparingEdit] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showWorkingCell, setShowWorkingCell] = useState(false);
  //#endregion

  useEffect(() => {
    // console.log("textToSpeechSlice", textToSpeechSlice);
  }, [textToSpeechSlice]);

  useEffect(() => {
    const messageCount = messagesSlice?.messages?.length ?? 0;
    setMessages(messagesSlice?.messages);

    if (messageCount > 0) {
      scrollToBottom();
    }
  }, [messagesSlice.messages, chatSlice.activeChat]);

  // Add this new function
  const scrollToBottom = () => {
    setTimeout(() => {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  };

  //#region useEffects
  useEffect(() => {
    return () => {
      // When component unmounts (navigating away), clear active message
      dispatch(setActiveMessage(null));
    };
  }, [location.pathname, dispatch]);

  useEffect(() => {
    // update selected cell when changed
    if (messagesSlice.activeMessage !== null) {
      setSelectedCell(messagesSlice?.activeMessage?._id);
    } else {
      setSelectedCell("");
    }
  }, [messagesSlice.activeMessage]);

  const [lastMessageCount, setLastMessageCount] = useState(0);

  useEffect(() => {
    const messagesCount = messages?.length ?? 0;
    if (messagesCount !== lastMessageCount || chatSlice.activeChat?.isWorking) {
      const isWorking = chatSlice?.activeChat?.isWorking ?? false;
      if (messagesSlice.preparingEdit) {
        setHasLoaded(false);
      }

      if (!hasLoaded) {
        if (messagesCount > 0) {
          setHasLoaded(true);
        }

        scrollRef.current?.scrollIntoView({ behavior: "auto" });
      } else {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      }
      // set preparing edit to false so that dots go away
      if (isPreparingEdit) {
        dispatch(setPreparingEdit(false));
        setIsPreparingEdit(false);
        setSelectedCell("");
      }

      setShowWorkingCell(isWorking);
    }
    setLastMessageCount(messagesCount);
  }, [messages, chatSlice.activeChat]);

  useEffect(() => {
    // console.log("isWorking: ", messagesSlice.isWorking);
  }, [
    messagesSlice,
    textToSpeechSlice,
    chatSlice,
    selectedCell,
    isPreparingEdit,
    chatSlice,
  ]);

  useEffect(() => {
    setIsPreparingEdit(messagesSlice.preparingEdit);
  }, [messagesSlice.preparingEdit]);

  useEffect(() => {
    if (messagesSlice.showFullQueue && messagesSlice.showFullQueue !== "") {
      dispatch(setAPopupIsShowing(true));
      const errorMessage = {
        title: "Queue Full",
        message:
          "The message queue is full. Please wait for a response to a previous message and then use the edit feature to submit this message again.",
      } as IErrorMessage;

      dispatch(showErrorNotification(errorMessage));
      dispatch(setShowFullQueue(""));
    }
  }, [messagesSlice.showFullQueue]);
  //#endregion

  //#region functions and handlers
  const handleEdit = (messageId: string) => {
    if (messagesSlice.editing) {
      setSelectedCell("");
      dispatch(setActiveMessage(null));
      dispatch(setEditing(false));
    } else {
      const message = messages.find(
        (message) => message._id === messageId
      ) as IMessage;

      setSelectedCell(messageId);
      dispatch(setActiveMessage(message));
      dispatch(setEditing(true));

      if (workspaceSlice.isEditPromptMode) {
        dispatch(cancelEditPrompt());
      }
    }
  };

  const handlePlay = (messageId: string) => {
    const message = messages.find(
      (message) => message._id === messageId
    ) as IMessage;

    setSelectedCell(messageId);
    dispatch(setActiveMessage(message));

    if (textToSpeechSlice.isPlaying) {
      dispatch(forceStopAudio());
    } else if (textToSpeechSlice.isLoading) {
      dispatch(forceOverrideStopAudio());
    } else {
      // start the audio
      dispatch(playMessage(message));
    }
  };

  useEffect(() => {
    const handleStopAudio = () => {
      handleStop("");
    };

    return () => {
      handleStopAudio();
    };
  }, [location]);

  const handleStop = (messageId: string) => {
    // console.log("handleStop", messageId);

    if (textToSpeechSlice.isLoading) {
      dispatch(forceOverrideStopAudio());
      return;
    }

    dispatch(forceStopAudio());
  };

  const handleSaveClicked = () => {
    if (messages === null || messages.length === 0) return;
    let hasActiveJob = false;
    for (let i = 0; i < chatSlice.chats.length; i++) {
      const chat = chatSlice.chats[i];

      if (chat.chatState !== "saved" && chat.chatState !== "open") {
        hasActiveJob = true;
        break;
      }
    }

    if (!hasActiveJob) {
      setShowNotification(false);
      const actionData = {
        message: "",
        command: "@save",
      };
      dispatch(doAction(actionData));
    } else {
      setShowNotification(true);
    }
  };

  const handleDeleteClicked = () => {
    if (messages === null || messages.length === 0) return;
    const actionData = { message: "", command: "@trash" };
    dispatch(doAction(actionData));
  };

  const handleClickAway = () => {
    if (!messagesSlice.editing && selectedCell !== "") {
      setSelectedCell("");
      dispatch(setActiveMessage(null));
    }
  };

  const handleCellClicked = (messageId: string) => {
    if (messagesSlice.editing) return;
    if (textToSpeechSlice.isLoading) return;
    if (textToSpeechSlice.isPlaying) return;
    if (messagesSlice.copyMessage) return;
    if (messageId !== selectedCell) {
      if (messageId !== "") {
        setSelectedCell(messageId);
      } else {
        setSelectedCell("");
        dispatch(setActiveMessage(null));
      }
    } else {
      setSelectedCell("");
      dispatch(setActiveMessage(null));
    }
  };

  function getStateForCell(messageId: string) {
    if (selectedCell === messageId) {
      if (messagesSlice.activeMessage?._id === messageId) {
        if (messagesSlice.editing) {
          return CellState.EDITING;
        } else if (textToSpeechSlice.isPlaying) {
          return CellState.PLAYING;
        } else if (textToSpeechSlice.isLoading) {
          return CellState.LOADING;
        }
      }
    }
    return CellState.DEFAULT;
  }

  // get the _id of the most recent user message to determine canEdit
  const lastUserMessageId = messages
    .slice()
    .find((message) => message.senderType === "User")?._id;

  function showIsLoading() {
    if (chatSlice.loading) return true;
    if (isPreparingEdit) return true;
    if (chatSlice.activeChat !== null && chatSlice.activeChat !== undefined) {
      switch (chatSlice.activeChat.chatState) {
        case "saving":
        case "deleting":
          return true;
        default:
          return false;
      }
    }

    return true;
  }

  const countLabel = () => {
    const count = chatSlice.activeChat?.activeJobs?.length ?? 0;
    return count > 0 ? `Processing ${count} documents` : null;
  };

  //#endregion

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {showNotification ? (
          <NotificationPopup
            triggerPopup={showNotification}
            title={"Processing..."}
            message={"You can only save one conversation at a time"}
          />
        ) : null}
        <ScrollHeader
          title={"Chat"}
          countLabel={countLabel()}
          handleAddClicked={handleSaveClicked}
          handleDeleteClicked={handleDeleteClicked}
        />
        {showIsLoading() ? (
          <Container
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PulseLoader
              size={16}
              margin={6}
              color={activeExpert?.color ?? theme.palette.primary.main}
              speedMultiplier={0.5}
            />
          </Container>
        ) : messages != null && messages.length > 0 ? (
          <ScrollContainer id="scroll-container-id">
            <List
              sx={{
                paddingBottom: screenSize.isLarge ? "8px" : "12px",
                height: "100%",
              }}
            >
              {messages
                .slice()
                .reverse()
                .map((message, index) => (
                  <div
                    key={message._id}
                    ref={index === messages.length - 1 ? scrollRef : null}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingRight: "8px",
                      paddingLeft: "8px",
                    }}
                  >
                    <MessageCell
                      message={message}
                      expert={activeExpert}
                      userDetails={userDetails}
                      cellState={getStateForCell(message._id) as CellState}
                      onSelect={handleCellClicked}
                      onEdit={handleEdit}
                      onStop={handleStop}
                      onPlay={handlePlay}
                      canEdit={
                        lastUserMessageId &&
                        lastUserMessageId === message._id &&
                        textToSpeechSlice.isPlaying === false
                      }
                      canPlay={
                        textToSpeechSlice.isPlaying === false ||
                        messagesSlice.activeMessage?._id === message._id
                      }
                      disabled={
                        (messagesSlice.editing &&
                          messagesSlice.activeMessage?._id !== message._id) ||
                        textToSpeechSlice.isLoading === true
                      }
                    />
                  </div>
                ))}
              {showWorkingCell && <WorkingCell />}
            </List>
          </ScrollContainer>
        ) : (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              paddingTop: "0px",
              paddingBottom: "16px",
              minHeight: "100%",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100%",
                width: "100%",
              }}
            >
              <InstructionPanel
                {...chatInstructionPanelData(userDetails?.firstName)}
              />
            </Box>
          </Box>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default ChatView;
