import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import React from "react";

interface TimedAlertPopupState {
  show: boolean;
  icon?: React.ReactNode;
  title?: string;
  subheader?: string;
  details?: string;
  timeRemaining: number;
  onDismiss?: () => void;
}

const initialState: TimedAlertPopupState = {
  show: false,
  icon: null,
  title: "",
  subheader: "",
  details: "",
  timeRemaining: 5,
};

export const dismissTimedAlertPopup = createAsyncThunk(
  "timedAlertPopup/dismiss",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { onDismiss } = state.timedAlertPopup;
    if (onDismiss) {
      onDismiss();
    }
    dispatch(hideTimedAlertPopup());
  }
);

export const timedAlertPopupSlice = createSlice({
  name: "timedAlertPopup",
  initialState,
  reducers: {
    showTimedAlertPopup: (
      state,
      action: PayloadAction<
        Omit<TimedAlertPopupState, "show" | "timeRemaining">
      >
    ) => {
      return { ...state, ...action.payload, show: true, timeRemaining: 5 };
    },
    hideTimedAlertPopup: (state) => {
      return { ...initialState };
    },
    decrementTimer: (state) => {
      if (state.timeRemaining > 0) {
        state.timeRemaining -= 1;
      }
    },
  },
});

export const { showTimedAlertPopup, hideTimedAlertPopup, decrementTimer } =
  timedAlertPopupSlice.actions;

export default timedAlertPopupSlice.reducer;
