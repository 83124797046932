import CustomToggle from "../../../../ui-elements/CustomToggle";
import { Box } from "@mui/material";
import BlockTitle from "../../../../ui-elements/BlockTitle";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { updateUserPreferences } from "../../../../../features/users/userPreferencesSlice";

const EnableTooltips = () => {
  const dispatch = useAppDispatch();
  const userPreferencesSlice = useAppSelector((state) => state.userPreferences);
  const userPreferences = userPreferencesSlice.userPreferences;
  const tooltipsEnabled = userPreferences?.tooltipsEnabled ?? true;

  const handleToggleChange = async (event) => {
    const updatedUser = {
      ...userPreferences,
      tooltipsEnabled: event.target.checked,
    };
    dispatch(updateUserPreferences(updatedUser));
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
    >
      <Box>
        <BlockTitle
          title={"Tooltips"}
          subtitle={tooltipsEnabled ? "Enabled" : "Disabled"}
          description={
            tooltipsEnabled
              ? "Tooltips will be enabled for all icons"
              : "Tooltips will be disabled for all icons"
          }
          overrideMarginTop={true}
        />
      </Box>
      <Box display="flex" alignItems="center">
        <CustomToggle checked={tooltipsEnabled} onChange={handleToggleChange} />
      </Box>
    </Box>
  );
};

export default EnableTooltips;
