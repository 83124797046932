import { useAppSelector } from "../../../../app/store";
import { IPlan } from "../../../../types/ui/pricing-page/IPlan";

export const usePlanLogic = (plan: IPlan) => {
  const userSubscriptionSlice = useAppSelector(
    (state) => state.userSubscription
  );
  const userSubscription = userSubscriptionSlice.userSubscription;
  const currentPlanId = userSubscription?.planId;
  const currentPlanName = userSubscription?.planName;

  const isCurrentPlan = plan._id === currentPlanId;
  const hasCurrentPlan =
    currentPlanId !== undefined &&
    currentPlanId !== null &&
    currentPlanId !== "";

  const planOrder = ["starter", "pro", "premium"];
  const currentPlanIndex = planOrder.indexOf(
    currentPlanName?.toLowerCase() ?? ""
  );
  const thisPlanIndex = planOrder.indexOf(plan.name.toLowerCase());

  const isUpgrade = thisPlanIndex > currentPlanIndex;
  const isDowngrade = thisPlanIndex < currentPlanIndex;

  const buttonText = isCurrentPlan
    ? "Current"
    : hasCurrentPlan
    ? isUpgrade
      ? "Upgrade"
      : "Downgrade"
    : "Get Started";

  const isDisabled = isCurrentPlan;

  return {
    isCurrentPlan,
    hasCurrentPlan,
    isUpgrade,
    isDowngrade,
    buttonText,
    isDisabled,
  };
};
