import { useEffect } from "react";
import { EditorState, ContentState } from "draft-js";
import { selectCommand } from "../../../../../../features/commands/commandsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { IMessageBarState } from "./MessageBarState";
import { createCommandDecorator } from "../commands/CommandDecorator";
import { ActiveView } from "../../../../../../features/chats/workspaceSlice";

export const useEditPromptWatcher = (messageBarState: IMessageBarState) => {
  const dispatch = useAppDispatch();
  const workspaceSlice = useAppSelector((state) => state.workspace);
  const commandsSlice = useAppSelector((state) => state.commands);
  const activeExpert = useAppSelector((state) => state.experts.activeExpert);

  useEffect(() => {
    const activeView = workspaceSlice.activeView;
    if (workspaceSlice.isEditPromptMode && activeView !== ActiveView.CACHE) {
      dispatch(
        selectCommand({ command: "@prompt", startIndex: 0, endIndex: 7 })
      );
      const promptText = workspaceSlice.currentPromptText || "@prompt ";

      const contentState = ContentState.createFromText(promptText);
      const newEditorState = EditorState.createWithContent(
        contentState,
        createCommandDecorator(commandsSlice.commands, activeExpert?.color)
      );

      // Move cursor to the end of the text
      const selection = newEditorState.getSelection().merge({
        anchorOffset: promptText.length,
        focusOffset: promptText.length,
      });

      const editorStateWithSelection = EditorState.forceSelection(
        newEditorState,
        selection
      );

      const updatedState = {
        editorState: editorStateWithSelection,
        showPlaceholder: false,
      };

      Object.assign(messageBarState, updatedState);

      // Trigger the command recognition
      dispatch(
        selectCommand({
          command: "@prompt",
          startIndex: 0,
          endIndex: 7,
        })
      );
    } else if (
      !workspaceSlice.isEditPromptMode &&
      activeView !== ActiveView.CACHE
    ) {
      // Clear the editor state and turn on the placeholder when exiting edit prompt mode
      const emptyEditorState = EditorState.createEmpty(
        createCommandDecorator(commandsSlice.commands, activeExpert?.color)
      );

      const updatedState = {
        editorState: emptyEditorState,
        showPlaceholder: true,
      };

      Object.assign(messageBarState, updatedState);
      dispatch(selectCommand(null));
    }
  }, [
    workspaceSlice.isEditPromptMode,
    workspaceSlice.currentPromptText,
    workspaceSlice.activeView,
    dispatch,
    messageBarState,
    commandsSlice.commands,
    activeExpert,
  ]);
};
