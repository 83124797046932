import { ListItemIcon, Typography, Box } from "@mui/material";
import { Logo } from "../../../../../icons";

interface PlanFeaturesProps {
  features: string[];
  accentColor: string;
}

const PlanFeatures: React.FC<PlanFeaturesProps> = ({
  features,
  accentColor,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginBottom: "16px",
        marginTop: "16px",
        width: "100%",
        paddingLeft: { xs: "16px", md: "24px" },
        paddingRight: { xs: "16px", md: "24px" },
      }}
    >
      {features.map((feature, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "16px",
            width: "100%",
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: "auto",
              marginRight: "16px",
            }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              height={"16px"}
              width={"16px"}
              color={accentColor}
            >
              <Logo />
            </Box>
          </ListItemIcon>
          <Typography
            variant="body2"
            fontSize={"0.8rem"}
            sx={{ wordWrap: "break-word", flexGrow: 1 }}
          >
            {feature}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default PlanFeatures;
