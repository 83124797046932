import React, { useState, useEffect } from "react";
import {
  Avatar,
  CircularProgress,
  Grid,
  Typography,
  Box,
  useTheme,
  Snackbar,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { updateUserImage } from "../../features/users/userDetailsSlice";
import { fileTypes } from "../pages/auth-page/AuthPageManager";

interface UserProfileImageProps {
  sizeOverride?: number;
}

export const UserProfileImage: React.FC<UserProfileImageProps> = ({
  sizeOverride = 80,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const userDetailsSlice = useAppSelector((state) => state.userDetails);
  const userDetails = userDetailsSlice.userDetails;
  const hasImage =
    userDetails?.picture &&
    userDetails?.picture !== "" &&
    userDetails?.picture !== "undefined";
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(userDetails?.picture ?? "");
  const [currentErrorMessage, setCurrentErrorMessage] = useState<string | null>(
    null
  );

  // Add this effect to update imageUrl when userDetails.picture changes
  useEffect(() => {
    setImageUrl(userDetails?.picture ?? "");
  }, [userDetails?.picture]);

  const callReportError = (message: string) => {
    setCurrentErrorMessage(message);
  };

  const postDetail = async (file: File) => {
    if (!file) {
      callReportError("No file selected");
      return <Snackbar autoHideDuration={6000} message={currentErrorMessage} />;
    }

    try {
      setLoadingImage(true);
      const result = await dispatch(updateUserImage(file));
      // Remove this line as we're now using the effect to update imageUrl
      // setImageUrl(url);
      setLoadingImage(false);
    } catch (error) {
      setCurrentErrorMessage("An error occurred, please try again.");
    }
  };

  return (
    <Box
      p={"16px"}
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <FileUploader
        handleChange={postDetail}
        dropMessageStyle={{ display: "none" }}
        name="file"
        types={fileTypes}
        multiple={false}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignContent={"center"}
        >
          <Grid item>
            {loadingImage ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: sizeOverride ? `${sizeOverride}px` : "80px",
                  width: sizeOverride ? `${sizeOverride}px` : "80px",
                  backgroundColor: theme.palette.colors.purple[500],
                  borderRadius: "50%",
                  margin: "8px",
                }}
              >
                <CircularProgress
                  size={sizeOverride ? sizeOverride * 0.375 : 80 * 0.375}
                  thickness={6}
                  sx={{
                    color: theme.palette.colors.grayScale[100],
                  }}
                />
              </Box>
            ) : (
              <Avatar
                sx={{
                  margin: "8px",
                  backgroundColor: theme.palette.colors.grayScale[400],
                  justifySelf: "center",
                  width: sizeOverride ? `${sizeOverride}px` : "80px",
                  height: sizeOverride ? `${sizeOverride}px` : "80px",
                  "&:hover": {
                    cursor: "pointer",
                    filter: "brightness(80%)",
                  },
                  "&:hover:active": {
                    transform: "scale(0.95)",
                    transition: "transform 0.1s",
                  },
                }}
                src={imageUrl}
              />
            )}
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: "primary",
                fontSize: "12px",
                textAlign: "center",
                cursor: "pointer",
                fontWeight: "600",
              }}
            >
              {hasImage
                ? `Welcome${
                    userDetails?.firstName ? `, ${userDetails?.firstName}` : "!"
                  }`
                : "Add"}
            </Typography>
          </Grid>
        </Grid>
      </FileUploader>
    </Box>
  );
};
