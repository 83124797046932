import React, { useEffect, useState } from "react";
import {
  IconButton,
  Typography,
  Divider,
  Container,
  Box,
  ClickAwayListener,
  CircularProgress,
  Button,
} from "@mui/material";
import { Checkbox, CheckboxChecked, Delete, Edit } from "../../../../../icons";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material";
import { IPrompt } from "../../../../../types";
import { PrimaryIcon } from "../../../../ui-elements";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { UtilityService } from "../../../../../services/UtilityService";
import {
  showConfirmPopup,
  setOnConfirm,
} from "../../../../../features/ui/confirmationPopupSlice";

interface PromptCellProps {
  prompt: IPrompt;
  onSelect: () => void;
  onClickAway: () => void;
  handleCheckboxChecked: (prompt: IPrompt) => void;
  adjustCheckboxForMediumScreen?: boolean;
  handleDelete: (prompt: IPrompt) => void;
  handleEdit: (prompt: IPrompt) => void;
  handleCancelEdit: () => void;
  expertColor: string;
}

const PromptCell: React.FC<PromptCellProps> = ({
  prompt,
  onSelect,
  onClickAway,
  handleCheckboxChecked,
  adjustCheckboxForMediumScreen = false,
  handleDelete,
  handleEdit,
  handleCancelEdit,
  expertColor,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const confirmationPopupSlice = useAppSelector(
    (state) => state.confirmationPopup
  );
  const workspaceSlice = useAppSelector((state) => state.workspace);
  const { isEditPromptMode, editingPromptId, isTranscribing } = workspaceSlice;

  const isMobile = UtilityService.getIsMobile();

  const [checked, setChecked] = useState(prompt.isActive);
  const [disabled, setDisabled] = useState(false);
  const [isTapped, setIsTapped] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setChecked(prompt.isActive);
  }, [prompt.isActive]);

  useEffect(() => {
    if (!isEditPromptMode) {
      setIsHovered(false);
      setIsTapped(false);
    }
  }, [isEditPromptMode]);

  const handleCheckboxChange = async () => {
    if (!disabled) {
      setDisabled(true);
      const updatedPrompt = { ...prompt, isActive: !checked };
      handleCheckboxChecked(updatedPrompt);
      setDisabled(false);
    }
  };

  const handleSelect = () => {
    if (!isEditPromptMode && !isTranscribing) {
      setIsTapped(!isTapped);
      onSelect();
    }
  };

  const handleClickAway = () => {
    if (!isMobile) {
      setIsTapped(false);
      onClickAway();
    }
  };

  const handleMouseEnter = () => {
    if (!isMobile) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      setIsHovered(false);
    }
  };

  const handleCancelEditClick = () => {
    handleCancelEdit();
    setIsTapped(false);
  };

  const handleDeleteClick = () => {
    dispatch(
      showConfirmPopup({
        title: "Delete Prompt",
        message: "Are you sure you want to delete this prompt?",
        confirmationText: "Delete",
        isDestructive: true,
        confirmationHandler: "@deleteSinglePrompt",
        payload: prompt._id,
      })
    );
  };

  useEffect(() => {
    if (confirmationPopupSlice.onConfirm.command === "@deleteSinglePrompt") {
      // if the payload is this prompt id
      if (confirmationPopupSlice.onConfirm.payload === prompt._id) {
        handleDelete(prompt);
        dispatch(setOnConfirm({ command: "", payload: "" }));
      }
    }
  }, [confirmationPopupSlice.onConfirm]);

  const PromptActionButtons = () => {
    if (isTranscribing) {
      return null;
    }

    if (isEditPromptMode) {
      if (isEditingThisPrompt) {
        return (
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
            sx={{ position: "absolute", right: "0px" }}
          >
            <Button
              onClick={handleCancelEditClick}
              sx={{
                textTransform: "none",
                fontSize: "0.85rem",
                fontWeight: 800,
                height: "40px",
                backgroundColor: "transparent",
                color: expertColor,
                borderRadius: "12px",
                paddingLeft: "10px",
                marginRight: "0px",
                "&:hover": {
                  backgroundColor: "transparent",
                  transform: "scale(1.05)",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
                transition: "transform 0.1s ease-in-out",
              }}
            >
              Cancel
            </Button>
          </Box>
        );
      } else {
        return null;
      }
    }

    return (
      (isHovered || isTapped) && (
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          sx={{ position: "absolute", right: "0px" }}
        >
          <PrimaryIcon
            iconName={"Delete Prompt"}
            icon={<Delete />}
            handleIconClick={handleDeleteClick}
          />
          <PrimaryIcon
            iconName={"Edit Prompt"}
            icon={<Edit />}
            handleIconClick={() => handleEdit(prompt)}
          />
        </Box>
      )
    );
  };

  const isEditingThisPrompt =
    isEditPromptMode && editingPromptId === prompt._id;
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        display="flex"
        width={"100%"}
        flexDirection={"column"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingBottom: "16px",
            paddingLeft: adjustCheckboxForMediumScreen ? "24px" : "0px",
            paddingRight: "0px",
            marginLeft: "0px",
            position: "relative",
          }}
          onClick={handleSelect}
        >
          <Box
            sx={{
              position: "absolute",
              top: "-8px",
              left: "16px",
              right: "-8px",
              bottom: "8px",
              borderRadius: "8px",
              backgroundColor: isEditingThisPrompt
                ? alpha(expertColor, 0.2)
                : isTapped
                ? alpha(theme.palette.text.primary, 0.05)
                : "transparent",
            }}
          />
          <IconButton
            onClick={handleCheckboxChange}
            disableTouchRipple
            sx={{
              color: theme.palette.text.secondary,
              height: "32px",
              width: "32px",
              padding: "6px",
              borderRadius: "8px",
            }}
          >
            {checked ? <CheckboxChecked /> : <Checkbox />}
          </IconButton>
          <Typography
            sx={{
              fontSize: "12px",
              marginLeft: "10px",
              width: "auto",
              maxWidth: "100%",
              fontWeight: "500",
              display: "-webkit-box",
              padding: 0,
              paddingRight: "0px",
              marginTop: 0,
              marginBottom: 0,
              border: "none",
              color: checked
                ? theme.palette.text.secondary
                : alpha(theme.palette.text.secondary, 0.5),
              marginRight: "60px",
              lineHeight: "20px",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordWrap: "break-word",
            }}
          >
            <span
              style={{
                color: checked
                  ? theme.palette.text.secondary
                  : alpha(theme.palette.text.secondary, 0.5),
                fontWeight: "700",
                marginRight: "2px",
                alignItems: "flex-end",
              }}
            >
              {prompt.words + " "}
            </span>
            <span>{prompt.message}</span>
          </Typography>
          {prompt.deleting ? (
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
              sx={{ position: "absolute", right: "16px" }}
            >
              <CircularProgress
                thickness={6}
                size={16}
                sx={{ color: expertColor }}
              />
            </Box>
          ) : (
            <PromptActionButtons />
          )}
        </Container>
        <Divider
          orientation="horizontal"
          sx={{
            height: "1px",
            borderColor:
              theme.palette.mode === "dark"
                ? theme.palette.background.buttonHighlighted
                : alpha(theme.palette.background.divider, 0.9),
            width: "calc(100% - 8px)",
            marginLeft: "16px",
          }}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default PromptCell;
