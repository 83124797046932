import React from "react";
import { useTheme, alpha } from "@mui/material/styles";
import { IPlan } from "../../../../../types/ui/pricing-page/IPlan";
import GetStartedButton from "../../home-page/home-components/GetStartedButton";
import { usePlanLogic } from "../usePlanLogic";

interface SubscribeButtonProps {
  plan: IPlan;
  isFeaturedPlan: boolean;
  onSubscribe: () => void;
}

const SubscribeButton: React.FC<SubscribeButtonProps> = ({
  plan,
  isFeaturedPlan,
  onSubscribe,
}) => {
  const theme = useTheme();
  const { isCurrentPlan, buttonText, isDisabled } = usePlanLogic(plan);

  return (
    <GetStartedButton
      onClick={onSubscribe}
      color={
        isFeaturedPlan
          ? theme.palette.primary.main
          : theme.palette.background.backdrop
      }
      textColor={
        isFeaturedPlan ? undefined : alpha(theme.palette.primary.main, 0.7)
      }
      textOverride={buttonText}
      disabled={isDisabled}
      hideIcon={isCurrentPlan || buttonText === "Downgrade"}
    />
  );
};

export default SubscribeButton;
