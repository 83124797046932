import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  executeSendMessage,
  executeUpdateMessage,
  executeCreatePrompt,
  executeUpdatePrompt,
} from "../processors/SubmitProcessor";
import { resetChat, saveAndOpenNewChat } from "../chats/chatSlice";
import { resetCache } from "../chats/cacheSlice";
import { RootState } from "../../app/store";
import { IChat, IActionData, ICommand, IErrorMessage } from "../../types/index";
import { deleteSkill } from "../experts/expertiseSlice";
import { deleteKnowledgeDoc } from "../experts/knowledgeDocsSlice";
import { getCommands } from "./commandSource";
import { getPopupDataForCommand } from "./commandSource";
import {
  setIsAutoPlayEnabled,
  setIsTranscribing,
} from "../chats/workspaceSlice";
import { forceStopAudio } from "../audio/textToSpeechSlice";
import { deleteExpert, setIsRemoving } from "../experts/expertsSlice";
import { setOnConfirm, showConfirmPopup } from "../ui/confirmationPopupSlice";
import { showErrorNotification } from "../ui/errorSlice";

const initialState = {
  loading: false,
  commands: getCommands(),
  activeCommand: null as ICommand | null,
  announceCommand: null as string | null,
  error: "" as unknown,
};

// action data is message string and command string
export const doAction = createAsyncThunk(
  "commands/doAction",
  async (actionData: IActionData, { dispatch, getState }) => {
    const { message, command, files } = actionData;
    const state = getState() as RootState;
    const workspaceSlice = state.workspace;
    const activeChat = state.chat.activeChat as IChat;
    if (activeChat === null) {
      console.log("active chat doesn't exist yet.");
      return;
    }

    // if intercepting command, execute it and return
    if (command != null) {
      switch (command) {
        case "@transcribeOn":
        case "@transcribeOff":
          dispatch(setIsTranscribing(command === "@transcribeOn"));
          return;
        case "@speakOn":
        case "@speakOff":
          dispatch(setIsAutoPlayEnabled(command === "@speakOn"));
          if (command === "@speakOff") {
            console.log(
              "clearing message queue and stopping audio on speakOff"
            );
            dispatch(forceStopAudio());
          }
          return;

        case "@cache":
        case "@prompt":
          if (activeChat != null && activeChat._id !== "") {
            const cacheId = activeChat.cacheId as string;
            if (message && message.trim() !== "") {
              if (workspaceSlice.isEditPromptMode) {
                const prompts = state.prompts.prompts;
                const promptId = workspaceSlice.editingPromptId;
                const newPromptMessage = message;
                const promptToUpdate = prompts.find((p) => p._id === promptId);
                if (promptToUpdate) {
                  const updatedPrompt = {
                    ...promptToUpdate,
                    message: newPromptMessage,
                  };
                  await executeUpdatePrompt(updatedPrompt, dispatch);
                }
              } else {
                await executeCreatePrompt(cacheId, message, dispatch);
              }
            }
          } else {
            // create chat, create cache, and then send prompt
            if (message && message.trim() !== "") {
              const cacheId = activeChat.cacheId as string;
              if (workspaceSlice.isEditPromptMode) {
                const prompts = state.prompts.prompts;
                const promptId = workspaceSlice.editingPromptId;
                const newPromptMessage = message;
                const promptToUpdate = prompts.find((p) => p._id === promptId);
                if (promptToUpdate) {
                  const updatedPrompt = {
                    ...promptToUpdate,
                    message: newPromptMessage,
                  };
                  await executeUpdatePrompt(updatedPrompt, dispatch);
                }
              } else {
                await executeCreatePrompt(cacheId, message, dispatch);
              }
            }
          }
          return;
        case "@save":
          const savePopupData = getPopupDataForCommand(command);
          dispatch(showConfirmPopup(savePopupData));
          return;
        case "@createNew":
          const createNewPopupData = getPopupDataForCommand(command);
          dispatch(showConfirmPopup(createNewPopupData));
        case "@trash":
        case "@clearCache":
          const popupData = getPopupDataForCommand(command);
          dispatch(showConfirmPopup(popupData));
          return;
      }

      // if not an intercepting command, process state command
      dispatch(executeCommand(command));
    }

    // if no intercepting command, send message
    if (message != null && message !== "") {
      const messageSlice = state.messages;
      if (messageSlice.editing) {
        const activeMessage = {
          ...messageSlice.activeMessage,
          message: message,
        };
        await executeUpdateMessage(activeMessage, dispatch);
      } else {
        const userDetails = state.userDetails.userDetails;
        await executeSendMessage(
          activeChat._id,
          message,
          userDetails,
          dispatch,
          files
        );
      }
    }
  }
);

export const handleConfirmPopup = createAsyncThunk(
  "commands/confirmPopup",
  async (onConfirmPayload: any, { dispatch, getState }) => {
    const state = getState() as RootState;
    const confirmHandler = onConfirmPayload.command;
    const payload = onConfirmPayload.payload;
    const activeChat = state.chat.activeChat as IChat;

    switch (confirmHandler) {
      case "@trash":
        const result = await dispatch(resetChat(activeChat._id));
        if (result.payload?.status === 403) {
          console.log("failed to clear chat: ", result.payload.message);
          const notificationData = {
            title: "Failed to Clear Chat",
            message: result.payload.message,
          } as IErrorMessage;
          dispatch(showErrorNotification(notificationData));
        }
        break;
      case "@clearCache":
        await dispatch(resetCache(activeChat.cacheId as string));
        break;
      case "@deleteSkill":
        const skill = payload;
        await dispatch(deleteSkill(skill));
        break;
      case "@deleteDoc":
        const docId = payload;
        await dispatch(deleteKnowledgeDoc(docId));
        break;
      case "@deleteExpert":
        const expertId = payload;
        dispatch(setIsRemoving(true));
        const deleteResult = await dispatch(deleteExpert(expertId));
        if (deleteResult?.payload === "success") {
          dispatch(
            setOnConfirm({ command: "@deleteExpert", payload: expertId })
          );
        } else {
          const resultPayload = deleteResult.payload as any;
          const message = resultPayload.message;
          console.log("failed to delete expert: ", message);
          const notificationData = {
            title: "Failed to Delete",
            message: message,
          } as IErrorMessage;
          dispatch(showErrorNotification(notificationData));
        }

        dispatch(setIsRemoving(false));
        break;
      case "@save":
      case "@createNew":
        await dispatch(saveAndOpenNewChat());
        break;
      default:
        // console.log("ignoring handler as its not a MessageBar command.");
        break;
    }
  }
);

export const commandsSlice = createSlice({
  name: "commands",
  initialState: initialState,
  reducers: {
    selectCommand: (state, action) => {
      state.activeCommand = action.payload;
    },

    executeCommand: (state, action) => {
      state.announceCommand = action.payload;
      state.activeCommand = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doAction.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(doAction.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(doAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { selectCommand, executeCommand } = commandsSlice.actions;

export default commandsSlice.reducer;
