import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import { fetchCache } from "./cacheSlice";
import { handleAxiosError } from "../../app/ErrorHandler";
import { INewPromptPayload, IPrompt } from "../../types/index";
import { showTimedAlertPopup } from "../ui/timedAlertPopupSlice";
import { Prompt } from "../../icons";
import { RootState } from "../../app/store";

const initialState = {
  loading: false,
  prompts: [] as IPrompt[],
  error: "" as unknown,
};

export const fetchPrompts = createAsyncThunk(
  "prompts/fetchPrompts",
  async (cacheId: string, { rejectWithValue }) => {
    try {
      const result = await api.fetchPrompts(cacheId);
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const createPrompt = createAsyncThunk(
  "prompts/createPrompt",
  async (promptData: INewPromptPayload, { dispatch, rejectWithValue }) => {
    try {
      const result = await api.createPrompt(promptData);
      dispatch(fetchCache(promptData.cacheId as string));
      // show alert that has success message
      dispatch(
        showTimedAlertPopup({
          icon: <Prompt />,
          title: "Prompt Added",
          details:
            "This prompt will now be used as context for all chats with this Expert.",
        })
      );
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const updatePrompt = createAsyncThunk(
  "prompts/updatePrompt",
  async (prompt: IPrompt, { dispatch, getState, rejectWithValue }) => {
    try {
      const promptId = prompt._id;
      const state = getState() as RootState;
      const getPromptWithId = state.prompts.prompts.find(
        (p) => p._id === promptId
      );
      const isUpdatingActive = prompt?.isActive !== getPromptWithId?.isActive;
      const result = await api.updatePrompt(prompt);
      const cacheId = prompt.cacheId as string;
      dispatch(fetchCache(cacheId));

      if (!isUpdatingActive) {
        dispatch(
          showTimedAlertPopup({
            icon: <Prompt />,
            title: "Prompt Edited",
            details:
              "This updated prompt will now be used as context for all chats with this Expert.",
          })
        );
      }

      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const deletePrompt = createAsyncThunk(
  "prompts/deletePrompt",
  async (promptId: string, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState() as { prompts: { prompts: IPrompt[] } };
      const prompt = state.prompts.prompts.find((p) => p._id === promptId);

      if (prompt) {
        dispatch(markPromptDeleting({ promptId }));
      }

      const result = await api.deletePrompt(promptId);
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const promptsSlice = createSlice({
  name: "prompts",
  initialState,
  reducers: {
    clearPrompts: (state) => {
      state.prompts = [];
      state.error = "";
      state.loading = false;
    },
    markPromptDeleting: (state, action) => {
      const prompt = state.prompts.find(
        (p) => p._id === action.payload.promptId
      );
      if (prompt) {
        prompt.deleting = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPrompts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPrompts.fulfilled, (state, action) => {
      state.loading = false;
      state.prompts = action.payload;
      state.error = "";
    });
    builder.addCase(fetchPrompts.rejected, (state, action) => {
      state.loading = false;
      state.prompts = [];
      state.error = action.error.message;
    });
    builder.addCase(createPrompt.fulfilled, (state, action) => {
      state.prompts.push(action.payload);
    });
    builder.addCase(createPrompt.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(updatePrompt.fulfilled, (state, action) => {
      const index = state.prompts.findIndex(
        (prompt) => prompt._id === action.payload._id
      );
      if (index !== -1) {
        state.prompts[index] = action.payload;
      }
    });
    builder.addCase(updatePrompt.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(deletePrompt.fulfilled, (state, action) => {
      state.prompts = state.prompts.filter(
        (prompt) => prompt._id !== action.payload.deletedId
      );
    });
    builder.addCase(deletePrompt.rejected, (state, action) => {
      const prompt = state.prompts.find((p) => p._id === action.meta.arg);
      if (prompt) {
        prompt.deleting = false;
      }
      state.error = action.error.message;
    });
  },
});

export const { clearPrompts, markPromptDeleting } = promptsSlice.actions;
export default promptsSlice.reducer;
