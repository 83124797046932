import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import CustomInput from "../../../ui-elements/CustomInput";
import { useAppDispatch } from "../../../../app/store";
import { sendResetPasswordEmail } from "../../../../features/users/userCredentialsSlice";
import AnimatedAuthView from "./AnimatedAuthView";
import { AnimatedFromDirection } from "./AnimatedViewWrapper";

const RequestResetPasswordView: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const dispatch = useAppDispatch();

  const [currentErrorMessage, setCurrentErrorMessage] = useState<string | null>(
    null
  );

  const callReportError = (message: string) => {
    setCurrentErrorMessage(message);
  };

  const clearErrorMessage = () => {
    setCurrentErrorMessage(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    clearErrorMessage();
    try {
      const response = await dispatch(sendResetPasswordEmail(email));

      if (
        response.type === "userCredentials/sendResetPasswordEmail/fulfilled"
      ) {
        setEmailSent(true);
      } else {
        const errorMessage =
          response.payload?.message ||
          "Failed to send password reset email. Please try again.";
        callReportError(errorMessage);
      }
    } catch (error) {
      callReportError("An error occurred. Please try again.");
    }
    setLoading(false);
  };

  return (
    <AnimatedAuthView direction={AnimatedFromDirection.Right}>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" gutterBottom>
          Reset Password
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.secondary,
            fontSize: "14px",
            textAlign: "center",
            fontWeight: "400",
            maxWidth: "400px",
            mb: 3,
          }}
        >
          {emailSent
            ? "Check your email! A password reset link has been sent to the email address you provided."
            : "Please enter the email address associated with your account. We will send you a link to reset your password."}
        </Typography>
        <Box width={"100%"} marginTop={"8px"} marginBottom={"32px"}>
          <CustomInput
            name="email"
            label="Email"
            handleChange={handleChange}
            type="email"
            disabled={emailSent}
          />
        </Box>
        {currentErrorMessage && !loading && (
          <Typography
            sx={{
              color: theme.palette.error.main,
              fontSize: "14px",
              textAlign: "center",
              fontWeight: "500",
              mb: 2,
            }}
          >
            {currentErrorMessage}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading || emailSent}
          sx={{ mb: 2, minWidth: "200px" }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : emailSent ? (
            "Email Sent"
          ) : (
            "Send Reset Link"
          )}
        </Button>
        <Button
          sx={{
            fontSize: "14px",
            textTransform: "none",
          }}
          onClick={() => navigate("/auth/login")}
        >
          Back to Login
        </Button>
      </Box>
    </AnimatedAuthView>
  );
};

export default RequestResetPasswordView;
