import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  alpha,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  IExpert,
  ITextToSpeechPayload,
  IUserDetails,
  IVoice,
} from "../../types";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { loadVoices } from "../../features/audio/voicesSlice";
import { updateExpert } from "../../features/experts/expertsSlice";
import BlockTitle from "./BlockTitle";
import PlayButton, { PlayButtonState } from "./PlayButton";
import {
  forceOverrideStopAudio,
  forceStopAudio,
  playDemoAudio,
} from "../../features/audio/textToSpeechSlice";
import { ArrowOpen } from "../../icons";
import { updateUserDetails } from "../../features/users/userDetailsSlice";

interface CustomVoiceSelectorProps {
  activeUserDetails?: IUserDetails | null;
  activeExpert?: IExpert | null;
}

const CustomVoiceSelector: React.FC<CustomVoiceSelectorProps> = ({
  activeUserDetails,
  activeExpert,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const textToSpeechSlice = useAppSelector((state) => state.textToSpeech);
  const voicesSlice = useAppSelector((state) => state.voices);
  const [voices, setVoices] = useState<IVoice[]>([]);
  const [voice, setVoice] = useState<IVoice | null>(null);

  // Load voices on component mount
  useEffect(() => {
    dispatch(loadVoices());
  }, [dispatch]);

  // Set the active voice based on user or expert details
  useEffect(() => {
    if (voicesSlice.voices && voicesSlice.voices.length > 0) {
      setVoices(voicesSlice.voices);

      if (activeUserDetails) {
        const userVoice = voicesSlice.voices.find(
          (v) => v._id === activeUserDetails.voice
        );
        setVoice(userVoice);
        return;
      }

      if (activeExpert) {
        const expertVoice = voicesSlice.voices.find(
          (v) => v._id === activeExpert.voice
        );
        setVoice(expertVoice);
        return;
      }

      // Fallback: Set the first voice if nothing is selected
      if (voicesSlice.voices.length > 0) {
        setVoice(voicesSlice.voices[0]);
      }
    }
  }, [voicesSlice, activeUserDetails, activeExpert]);

  // Handle voice selection changes
  const handleVoiceChange = (event) => {
    const selectedVoice = voicesSlice.voices.find(
      (v) => v.displayName === event.target.value
    );
    setVoice(selectedVoice);

    if (activeUserDetails) {
      const updatedUser = { ...activeUserDetails, voice: selectedVoice._id };
      dispatch(updateUserDetails(updatedUser));
    }
    if (activeExpert) {
      const updatedExpert = { ...activeExpert, voice: selectedVoice._id };
      dispatch(updateExpert(updatedExpert));
    }
  };

  // Generate menu items for the voices dropdown
  const voiceItems = () => {
    if (voices && voices.length > 0) {
      return voices.map((voice) => (
        <MenuItem key={voice._id} value={voice.displayName}>
          {voice.displayName}
        </MenuItem>
      ));
    } else {
      return <MenuItem value={"Loading voices..."}>Loading voices...</MenuItem>;
    }
  };

  const selectStyle = {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(
        theme.palette.text.placeholder,
        theme.palette.mode === "dark" ? 0.8 : 1.0
      ),
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: activeExpert?.color || theme.palette.primary.main,
    },
    "& .MuiInputLabel-root": {
      color: activeExpert?.color || theme.palette.text.secondary,
      backgroundColor: theme.palette.background.backdrop,
      paddingRight: "5px",
      paddingLeft: "5px",
    },
    "& .Mui-focused .MuiInputLabel-root": {
      color: activeExpert?.color || theme.palette.primary.main,
    },
    background: theme.palette.background.backdrop,
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: 600,
    height: "50px",
    color: theme.palette.text.primary,
    "& .MuiSelect-icon": {
      fontSize: "12px",
      height: "20px",
      width: "20px",
      color: theme.palette.text.primary,
      marginTop: "-4px",
      marginRight: "4px",
    },
  };

  const [playButtonState, setPlayButtonState] = useState<PlayButtonState>(
    PlayButtonState.DEFAULT
  );

  useEffect(() => {
    if (textToSpeechSlice.isPlaying) {
      setPlayButtonState(PlayButtonState.PLAYING);
    } else if (textToSpeechSlice.isLoading) {
      setPlayButtonState(PlayButtonState.LOADING);
    } else {
      setPlayButtonState(PlayButtonState.DEFAULT);
    }
  }, [textToSpeechSlice.isPlaying, textToSpeechSlice.isLoading]);

  // Voice demo text variations
  const greetingBeginning = [
    "What's up",
    "Hi",
    "Hello",
    "Hey",
    "What's good",
    "What it do",
  ];

  const greetingEndings = [
    "If you like what you hear, don't change that dial!",
    "I hope you like it!",
    "How do I sound?",
    "What do you think?",
    "I might be partial, but I think this might be the one.",
  ];

  // Handle play button click
  const onPlay = () => {
    if (
      textToSpeechSlice.activePlayingMessage &&
      textToSpeechSlice.activePlayingMessage !== voice?._id
    ) {
      dispatch(forceStopAudio()); // Stop other playing audios before starting the current one
    }

    setPlayButtonState(PlayButtonState.LOADING);

    if (textToSpeechSlice.isPlaying) {
      dispatch(forceStopAudio());
    } else if (textToSpeechSlice.isLoading) {
      dispatch(forceOverrideStopAudio());
    } else {
      const randomIntro =
        greetingBeginning[Math.floor(Math.random() * greetingBeginning.length)];
      const randomGreeting =
        greetingEndings[Math.floor(Math.random() * greetingEndings.length)];

      const demoText = `${randomIntro}! This is a quick demo of the ${voice?.displayName} voice. ${randomGreeting}`;

      const textToSpeechAudioPayload = {
        text: demoText,
        voiceId: voice?._id ?? "",
        isPublic: true,
      } as ITextToSpeechPayload;

      dispatch(playDemoAudio(textToSpeechAudioPayload));
    }
  };

  // Handle stop button click
  const onStop = () => {
    setPlayButtonState(PlayButtonState.DEFAULT);
    dispatch(forceStopAudio());
  };

  return (
    <Box>
      <BlockTitle
        title={activeUserDetails ? "My Voice" : "Voice"}
        subtitle={voice?.displayName ?? "Loading voices..."}
        description="This is the voice used for conversational playback"
        overrideMarginTop={true}
      />
      <Box display="flex" alignItems="center" mt={3} gap={2} mb={1}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel
            style={{
              color:
                activeExpert?.color || alpha(theme.palette.text.secondary, 0.6),
              fontWeight: 700,
              fontSize: "14px",
              backgroundColor: theme.palette.background.backdrop,
              paddingLeft: "11px",
              paddingRight: "11px",
              marginLeft: "-3px",
              borderRadius: "8px",
            }}
          >
            Voice
          </InputLabel>

          <Select
            value={voice?.displayName ?? "Loading voices..."}
            onChange={handleVoiceChange}
            sx={{
              ...selectStyle,
              cursor: "pointer",
              pointerEvents: "auto",
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
              },
            }}
            IconComponent={ArrowOpen}
          >
            {voiceItems()}
          </Select>
        </FormControl>
        <PlayButton
          onPlay={onPlay}
          onStop={onStop}
          buttonStateOverride={playButtonState}
          colorOverride={activeExpert ? activeExpert.color : undefined}
        />
      </Box>
    </Box>
  );
};

export default CustomVoiceSelector;
