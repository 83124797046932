import { useEffect, useRef, useState } from "react";
import BodyHeader from "./body-header/BodyHeader";
import ScrollHeader from "../ScrollHeader";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { IExpert } from "../../../../../types/index";
import StyleSection from "./sections/style-section/StyleSection";
import KnowledgeSection from "./sections/knowledge-section/KnowledgeSection";
import { Box, Typography } from "@mui/material";
import { MenuItem } from "../../../../ui-elements/menu-tabs/MenuItemEnums";
import { useTheme } from "@mui/material/styles";
import {
  setOnConfirm,
  showConfirmPopup,
} from "../../../../../features/ui/confirmationPopupSlice";

const ProfileView = ({ handleIconClicked }) => {
  const scrollRef = useRef(null);
  const expertSlice = useAppSelector((state) => state.experts);
  const confirmationPopupSlice = useAppSelector(
    (state) => state.confirmationPopup
  );
  const activeExpert = expertSlice.activeExpert as IExpert;
  const [selectedTab, setSelectedTab] = useState(MenuItem.Expertise);
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Navigate to chat view if expert is deleted
  useEffect(() => {
    if (confirmationPopupSlice.onConfirm.command === "@deleteExpert") {
      handleIconClicked("Chat");
      dispatch(setOnConfirm({ command: "", payload: "" }));
    }
  }, [confirmationPopupSlice.onConfirm]);

  const handleResetClicked = () => {
    dispatch(
      showConfirmPopup({
        title: "Delete Expert",
        message: "Are you sure you want to delete this expert?",
        confirmationText: "Delete",
        isDestructive: true,
        confirmationHandler: "@deleteExpert",
        payload: activeExpert._id,
      })
    );
  };

  const sectionForTab = () => {
    switch (selectedTab) {
      case MenuItem.Expertise:
        return <KnowledgeSection />;
      case MenuItem.Persona:
        return <StyleSection />;
      case MenuItem.Stats:
        return (
          <Typography
            sx={{
              color: theme.palette.text.primary,
              fontSize: "20px",
              fontWeight: 600,
              margin: "16px",
            }}
          >
            Coming Soon!
          </Typography>
        );
      default:
        return <StyleSection />;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "100%",
        width: "100%",
      }}
    >
      <ScrollHeader
        title={"Profile"}
        countLabel={""}
        handleDeleteClicked={handleResetClicked}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            overflowX: "hidden",
            maxHeight: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            backgroundColor: "transparent",
            width: "100%",
            height: "100%",
          }}
        >
          <BodyHeader expert={activeExpert} selectedTab={selectedTab} />
          {sectionForTab()}
          <div ref={scrollRef} />{" "}
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileView;
