import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { HomeExpertsSectionData } from "../home-sections/HomeExpertsData";

interface HomeToggleProps {
  expertData: HomeExpertsSectionData;
  isSelected: boolean;
  onSelect: (expert: HomeExpertsSectionData) => void;
}

const HomeToggle: React.FC<HomeToggleProps> = ({
  expertData,
  isSelected,
  onSelect,
}) => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(expertData.image);
  const [currentColor, setCurrentColor] = useState(
    theme.palette.text.secondary
  );

  useEffect(() => {
    setCurrentImage(isSelected ? expertData.selectedImage : expertData.image);
    setCurrentColor(
      isSelected ? theme.palette.text.primary : theme.palette.text.secondary
    );
  }, [isSelected, expertData, theme.palette.text.secondary]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      alignContent={"center"}
      padding={0}
      sx={{
        width: { xs: "50px", sm: "100px" },
        height: { xs: "50px", sm: "100px" },
      }}
    >
      <Box
        onClick={() => onSelect(expertData)}
        sx={{
          width: "100%",
          height: "100%",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            cursor: "pointer",
            transform: "scale(1.05)",
          },
        }}
      >
        <img
          src={currentImage}
          alt={expertData.title}
          style={{ width: "100%", height: "100%" }}
        />
      </Box>

      <Typography
        variant="body1"
        color={currentColor}
        sx={{
          display: { xs: "none", sm: "block" },
          transition: "color 0.3s ease-in-out",
        }}
      >
        {expertData.subtitle}
      </Typography>
    </Box>
  );
};

export default HomeToggle;
