import { Box, Button, darken } from "@mui/material";
import { Forward } from "../../../../../icons";
import { styled } from "@mui/material/styles";
import { UtilityService } from "../../../../../services/UtilityService";

// Styled component for the animated button
const AnimatedButton = styled(Button)(({ theme }) => ({
  // Define the hover state styles for the button
  "&:hover .MuiButton-endIcon": {
    animation: "moveArrow 0.3s ease-in-out forwards",
  },
  "@keyframes moveArrow": {
    "0%": {
      transform: "translateX(0)",
    },

    "100%": {
      transform: "translateX(8px)",
    },
  },
}));

const darkenedColor = (color: string) => {
  if (color) {
    return darken(color, 0.2);
  }
  return null;
};

interface GetStartedButtonProps {
  onClick: () => void;
  color?: string;
  textColor?: string;
  textOverride?: string;
  disabled?: boolean;
  hideIcon?: boolean;
}

const GetStartedButton: React.FC<GetStartedButtonProps> = ({
  onClick,
  color,
  textColor,
  textOverride,
  disabled,
  hideIcon,
}) => {
  const handleClick = () => {
    if (disabled) {
      return;
    }

    if (onClick) {
      onClick();
    }
  };

  const isMobile = UtilityService.getIsMobile();

  return (
    <AnimatedButton
      variant="contained"
      onClick={handleClick}
      aria-hidden={false}
      disabled={disabled && disabled !== undefined ? disabled : false}
      endIcon={
        hideIcon ? null : (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "16px", width: "16px" }}
          >
            <Forward />
          </Box>
        )
      }
      sx={{
        height: "40px",
        width: "160px",
        borderRadius: "6px",
        background: color ? color : "primary",
        color: textColor ? textColor : "white",
        "&:hover": !isMobile
          ? {
              background: color ? darkenedColor(color) : "primary",
            }
          : {},
      }}
    >
      {textOverride ? textOverride : "Get Started"}
    </AnimatedButton>
  );
};

export default GetStartedButton;
