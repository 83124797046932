import React, { useEffect, useState } from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import { IKnowledgeDoc, IMessage } from "../../../../../../types";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  clearImageUrl,
  fetchImageUrl,
} from "../../../../../../features/images/imageCacheSlice";
import KnowledgeDocThumbnail from "../../../footer-bar/file-drawer/KnowledgeDocThumbnail";
import { IPlaceholderFile } from "../../../../../../types/chat/IMessage";

interface ImagesBlockProps {
  message: IMessage;
  knowledgeDocs: IKnowledgeDoc[];
}

const ImagesBlock: React.FC<ImagesBlockProps> = ({
  message,
  knowledgeDocs,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const imageUrls = useAppSelector((state) => state.imageCache.urls);
  const [loadingImages, setLoadingImages] = useState(new Set<string>());
  const [placeholderFiles, setPlaceholderFiles] = useState<IPlaceholderFile[]>(
    []
  );

  useEffect(() => {
    if (message.placeholderFiles) {
      const hasImageFile = message.placeholderFiles.some((placeholderFile) =>
        placeholderFile.file.type.includes("image")
      );
      if (hasImageFile) {
        setPlaceholderFiles(message.placeholderFiles as IPlaceholderFile[]);
      }
    }

    if (knowledgeDocs?.length > 0) {
      knowledgeDocs.forEach((doc) => {
        const { knowledgeType, _id, tempUid } = doc;
        if (["png", "jpg", "jpeg"].includes(knowledgeType.toLowerCase())) {
          const matchingPlaceholder = message.placeholderFiles?.find(
            (pf) => pf.tempUid === tempUid
          );
          if (matchingPlaceholder) {
            setPlaceholderFiles((prev) => [...prev, matchingPlaceholder]);
          } else if (!imageUrls[_id] && !matchingPlaceholder) {
            setLoadingImages((prev) => new Set(prev.add(_id)));
            dispatch(fetchImageUrl(_id));
          }
        }
      });
    }
  }, [message, knowledgeDocs, imageUrls, dispatch]);

  if (!knowledgeDocs?.length && !placeholderFiles.length) {
    return null;
  }

  const handleImageError = (docId: string) => {
    dispatch(clearImageUrl(docId));
    dispatch(fetchImageUrl(docId));
  };

  const handleImageLoad = (docId: string) => {
    setLoadingImages((prev) => {
      const newSet = new Set(prev);
      newSet.delete(docId);
      return newSet;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display:
            knowledgeDocs?.length === 1 || placeholderFiles?.length === 1
              ? "flex"
              : "grid",
          gridTemplateColumns:
            knowledgeDocs?.length === 1 || placeholderFiles?.length === 1
              ? "none"
              : knowledgeDocs?.length === 2 || placeholderFiles?.length === 2
              ? "repeat(2, 100px)"
              : knowledgeDocs?.length === 3 || placeholderFiles?.length === 3
              ? "repeat(2, 100px)"
              : "repeat(auto-fill, 100px)",
          gap: "10px",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          margin: "6px",
        }}
      >
        {placeholderFiles.length <= 0 &&
          knowledgeDocs.map((doc, index) => {
            const { knowledgeType, _id } = doc;
            if (["png", "jpg", "jpeg"].includes(knowledgeType.toLowerCase())) {
              return (
                <Box
                  key={_id} // Using _id for knowledgeDocs
                  sx={{
                    width: knowledgeDocs.length === 1 ? "240px" : "100px",
                    height: knowledgeDocs.length === 1 ? "240px" : "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                    borderRadius: "8px",
                  }}
                >
                  <Box
                    height={"100%"}
                    width={"100%"}
                    alignItems={"center"}
                    display={"flex"}
                    justifyContent={"center"}
                    sx={{
                      backgroundColor: theme.palette.background.button,
                      position: "relative",
                      borderRadius: "8px",
                      border: loadingImages.has(_id)
                        ? `1px solid ${theme.palette.primary.main}`
                        : "none",
                    }}
                  >
                    {loadingImages.has(_id) && (
                      <CircularProgress
                        size={16}
                        thickness={8}
                        sx={{
                          color: "expertColor",
                          position: "absolute",
                        }}
                      />
                    )}
                    <Box
                      component="img"
                      src={imageUrls[_id]}
                      alt={`image-${index}`}
                      onError={() => handleImageError(_id)}
                      onLoad={() => handleImageLoad(_id)}
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        display: loadingImages.has(_id) ? "none" : "block",
                      }}
                    />
                  </Box>
                </Box>
              );
            }
            return null;
          })}

        {placeholderFiles?.map((placeholderFile, index) => (
          <Box
            key={index}
            sx={{
              width: placeholderFiles.length === 1 ? "240px" : "100px",
              height: placeholderFiles.length === 1 ? "240px" : "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              borderRadius: "8px",
            }}
          >
            <Box
              height={"100%"}
              width={"100%"}
              alignItems={"center"}
              display={"flex"}
              justifyContent={"center"}
              sx={{
                backgroundColor: theme.palette.background.button,
                position: "relative",
                borderRadius: "8px",
              }}
            >
              <KnowledgeDocThumbnail
                title={placeholderFile.file.name}
                showLoading={false}
                file={placeholderFile.file}
                size={placeholderFiles.length === 1 ? 240 : 100}
                forceNoBorderRadius={true}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ImagesBlock;
