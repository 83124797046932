import { setPreparingEdit } from "../../../../../../features/chats/messagesSlice";
import {
  doAction,
  selectCommand,
} from "../../../../../../features/commands/commandsSlice";
import { ICommand, IActionData } from "../../../../../../types";
import { createEmptyDecoratedEditor } from "../commands/CommandDecorator";
import { IMessageBarState } from "../state-setters/MessageBarState";
import { ActiveView } from "../../../../../../features/chats/workspaceSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import { resetCacheView } from "../state-setters/useViewSetter";

export function handleSubmit(messagesSlice, messageBarState: IMessageBarState) {
  const dispatch = useAppDispatch();
  const workspaceSlice = useAppSelector((state) => state.workspace);
  const commandsSlice = useAppSelector((state) => state.commands);
  const activeExpert = useAppSelector((state) => state.experts.activeExpert);

  return () => {
    if (messagesSlice.editing) {
      dispatch(setPreparingEdit(true));
    }
    const contentState = messageBarState.editorState.getCurrentContent();
    let currentMessage = contentState.getPlainText().trim();
    const activeCommand = commandsSlice.activeCommand as ICommand;

    if (activeCommand != null) {
      const startIndex = activeCommand.startIndex as number;
      const endIndex = activeCommand.endIndex as number;
      const beforeCommand = currentMessage.slice(0, startIndex);
      const afterCommand = currentMessage.slice(endIndex + 1);
      currentMessage = `${beforeCommand}${afterCommand}`.trim();
    }

    const command =
      activeCommand != null && activeCommand.command != null
        ? activeCommand.command
        : null;

    const message = currentMessage != null ? currentMessage : null;
    const files = messageBarState.files;
    const submitData = { message, command, files } as IActionData;

    dispatch(doAction(submitData));

    messageBarState.editorState = createEmptyDecoratedEditor(
      commandsSlice,
      activeExpert
    );

    dispatch(selectCommand(null));

    messageBarState.showPlaceholder = true;
    messageBarState.showSuggestions = false;
    messageBarState.wasShowingSuggestions = false;

    if (workspaceSlice.activeView === ActiveView.CACHE) {
      resetCacheView(
        messageBarState,
        commandsSlice,
        activeExpert,
        dispatch,
        workspaceSlice
      );
    }
  };
}
