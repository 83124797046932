import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  Divider,
  Box,
  Tab,
  alpha,
  darken,
} from "@mui/material";
import { Delete, Expertise, Persona, Add, Prompt } from "../../../../icons";
import { useTheme } from "@mui/material/styles";
import PrimaryIcon from "../../../ui-elements/PrimaryIcon";
import { MenuTabs } from "../../../ui-elements/menu-tabs/MenuTabs";
import MobileMenuTabs from "../../../ui-elements/menu-tabs/MobileMenuTabs";
import { MenuItem } from "../../../ui-elements/menu-tabs/MenuItemEnums";
import CustomSpeakIcon from "../../../ui-elements/SpeakIcon";
import {
  ActiveView,
  setIsAutoPlayEnabled,
} from "../../../../features/chats/workspaceSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import ToolTipButton from "../../../ui-elements/ToolTipButton";
import { showInstructionPopup } from "../../../../features/ui/instructionPopupSlice";
import { cacheInstructionPanelData } from "../../../popups/instruction-panel/instructionPanelData";

interface ScrollHeaderProps {
  title: string;
  countLabel?: string | null;
  handleAddClicked?: (iconName: string) => void | null;
  handleDeleteClicked?: (iconName: string) => void | null;
  selectedTab?: MenuItem;
  setSelectedTab?: (tab: MenuItem) => void;
  showDelete?: boolean;
}

const ScrollHeader: React.FC<ScrollHeaderProps> = ({
  title,
  countLabel,
  handleAddClicked,
  handleDeleteClicked,
  selectedTab,
  setSelectedTab,
  showDelete = true,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const workspaceSlice = useAppSelector((state) => state.workspace);
  const messagesSlice = useAppSelector((state) => state.messages);
  const activeExpert = useAppSelector((state) => state.experts.activeExpert);
  const [containerWidth, setContainerWidth] = useState<number | null>(0);
  const [showMobileProfileMenu, setShowMobileProfileMenu] = useState(
    title === "Profile" && containerWidth && containerWidth < 600
  );
  const [showDesktopProfileMenu, setShowDesktopProfileMenu] = useState(
    title === "Profile" && containerWidth && containerWidth > 600
  );
  const containerRef = useRef<HTMLDivElement>(null);

  const isDefaultExpert = () => {
    return activeExpert?.isDefaultExpert;
  };

  const isProfileView = () => {
    return title === "Profile";
  };

  useEffect(() => {
    const width = containerRef?.current?.clientWidth as number;
    setContainerWidth(width);
    setShowMobileProfileMenu(title === "Profile" && width < 600);
    setShowDesktopProfileMenu(title === "Profile" && width > 600);
  }, [containerRef]);

  useEffect(() => {
    const handleResize = () => {
      const width = containerRef?.current?.clientWidth as number;
      setContainerWidth(width);
      setShowMobileProfileMenu(title === "Profile" && width < 600);
      setShowDesktopProfileMenu(title === "Profile" && width > 600);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleTabChange = (tab: MenuItem) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    //console.log("new tab: " + selectedTab);
  }, [selectedTab]);

  const showDeleteIcon = () => {
    if (!showDelete) {
      return false;
    }

    if (title === "Cache") {
      return false;
    }

    if (handleDeleteClicked) {
      if (isProfileView()) {
        if (!isDefaultExpert()) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  };

  const handleSpeakButtonClick = () => {
    const isAutoPlayEnabled = workspaceSlice.isAutoPlayEnabled;
    dispatch(setIsAutoPlayEnabled(!isAutoPlayEnabled));
  };

  const lowerCaseTitle = title.toLowerCase();

  const handleCacheToolTipPressed = () => {
    dispatch(showInstructionPopup(cacheInstructionPanelData));
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginLeft: "16px",
        marginRight: "16px",
        width: "calc(100% - 16px)",
      }}
    >
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: showDesktopProfileMenu
              ? "space-between"
              : "flex-start",
          }}
        >
          <Typography
            sx={{
              display: "inline-block",
              fontWeight: 700 + "!important",
              fontSize: "20px",
              color: activeExpert?.color || theme.palette.primary.main,
              marginRight: "8px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              display: "inline-block",
              color: theme.palette.text.secondary,
              fontWeight: "400",
              fontSize: "10px",
              marginRight: "8px",
              marginTop: "12px",
              marginBottom: "7px",
            }}
          >
            {countLabel ?? ""}
          </Typography>
          {showMobileProfileMenu && (
            <Grid
              item
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
            >
              <Divider
                orientation="vertical"
                sx={{
                  height: "24px",
                  marginLeft: "-2px",
                  marginRight: "18px",
                  backgroundColor: theme.palette.background.divider,
                }}
              />
              <MobileMenuTabs
                selectedTab={selectedTab}
                onItemSelected={handleTabChange}
                mobileMenuItems={[
                  {
                    title: "Expertise",
                    icon: <Expertise />,
                    onClick: () => handleTabChange(MenuItem.Expertise),
                  },
                  ,
                  {
                    title: "Persona",
                    icon: <Persona />,
                    onClick: () => handleTabChange(MenuItem.Persona),
                  },

                  // {
                  //   title: "Stats",
                  //   icon: <Stats />,
                  //   onClick: () => handleTabChange(MenuItem.Stats),
                  // },
                ]}
              />
            </Grid>
          )}
        </Grid>
        {showDesktopProfileMenu && (
          <Grid
            item
            display={"flex"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <MenuTabs
              selectedTab={selectedTab}
              onChange={handleTabChange}
              color={activeExpert?.color}
              tabs={[
                <Tab key={1} value={1} label="Expertise" disableRipple />,
                <Tab key={0} value={0} label="Persona" disableRipple />,
                // <Tab key={2} value={2} label="Stats" disableRipple />,
              ]}
            />
          </Grid>
        )}
        {
          <Grid item display={"flex"} alignItems={"center"}>
            {!messagesSlice.editing &&
              workspaceSlice.activeView !== ActiveView.CACHE &&
              workspaceSlice.activeView !== ActiveView.PROFILE &&
              lowerCaseTitle !== "cache" && (
                <CustomSpeakIcon
                  handleSpeakButtonClick={handleSpeakButtonClick}
                />
              )}
            {showDeleteIcon() && (
              <PrimaryIcon
                iconName={"Delete & New"}
                icon={<Delete />}
                handleIconClick={handleDeleteClicked}
                sx={{ marginLeft: "-2px" }}
              />
            )}
            {handleAddClicked && (
              <PrimaryIcon
                icon={<Add />}
                iconName="Save & New"
                size="22px"
                defaultColorOverride={theme.palette.colors.grayScale.white}
                backgroundColorOverride={
                  activeExpert?.color ?? theme.palette.primary.main
                }
                highlightedColorOverride={darken(
                  activeExpert?.color ?? theme.palette.primary.main,
                  0.2
                )}
                handleIconClick={handleAddClicked}
                sx={{ marginRight: "16px", marginLeft: "4px" }}
              />
            )}
            {title === "Cache" && (
              <ToolTipButton onClick={handleCacheToolTipPressed} />
            )}
          </Grid>
        }
      </Grid>
      <Divider
        sx={{
          position: "relative",
          top: "-1px",
          backgroundColor: alpha(
            theme.palette.background.buttonHighlighted,
            0.1
          ),
          marginRight: "8px",
          marginLeft: "-8px",
          height: "0.5px",
        }}
      />
    </Box>
  );
};

export default ScrollHeader;
