import { alpha, Box, Typography, useTheme } from "@mui/material";
import React from "react";

export interface InstructionPanelProps {
  title: string;
  lines: string[];
  icon?: React.ReactNode;
  overridePadding?: boolean;
}

const InstructionPanel: React.FC<InstructionPanelProps> = ({
  title,
  lines,
  icon,
  overridePadding,
}) => {
  const theme = useTheme();

  const renderListItem = (content: string, index: number) => (
    <Box
      key={index}
      sx={{
        fontSize: "0.85rem",
        width: "100%",
        maxWidth: "320px",
        fontWeight: 400,
        textAlign: "left",
        color: theme.palette.text.secondary,
        marginBottom: index < lines.length - 1 ? "16px" : 0,
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <Box
        component="span"
        sx={{
          marginRight: "8px",
          marginTop: "6px",
          minWidth: "8px",
          height: "8px",
          borderRadius: "50%",
          backgroundColor: theme.palette.text.secondary,
        }}
      />
      <Typography
        component="span"
        sx={{
          fontSize: "0.85rem",
          width: "100%",
        }}
        dangerouslySetInnerHTML={{
          __html: content.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>"),
        }}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        justifySelf: "center",
        height: "100%",
        minHeight: "100%",
        width: "100%",
        paddingTop: "48px",
        paddingBottom: "48px",
        ...(overridePadding && {
          paddingTop: "0px",
          paddingBottom: "0px",
        }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {icon && (
          <Box
            sx={{
              height: "48px",
              width: "48px",
              display: "flex",
              marginBottom: "8px",
              alignItems: "center",
              justifyContent: "center",
              padding: "8px",
              color: theme.palette.text.secondary,
              backgroundColor: theme.palette.background.buttonHighlighted,
              borderRadius: "50%",
            }}
          >
            {React.cloneElement(icon as React.ReactElement, {
              style: { width: "100%", height: "100%" },
            })}
          </Box>
        )}
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            color: theme.palette.text.secondary,
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "column",
          padding: { xs: "16px", sm: "24px", md: "30px" },
          borderRadius: "12px",
          backgroundColor: alpha(
            theme.palette.background.buttonHighlighted,
            0.5
          ),
          textAlign: "center",
          margin: "20px",
        }}
      >
        {lines.map((line, index) => renderListItem(line, index))}
      </Box>
    </Box>
  );
};

export default InstructionPanel;
