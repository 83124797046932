import { Box, useTheme } from "@mui/material";
import { ActiveView } from "../../../../features/chats/workspaceSlice";
import CacheView from "../content-views/cache-view/CacheView";
import ChatView from "../content-views/chat-view/ChatView";
import ProfileView from "../content-views/profile-view/ProfileView";
import { useAppSelector } from "../../../../app/store";
import { useMemo } from "react";
import useScreenSize from "../../../ui-elements/useScreenSize";

interface ContentPanelProps {
  handleIconClick: (iconName: string) => void;
}

export const ContentPanel: React.FC<ContentPanelProps> = ({
  handleIconClick,
}) => {
  const theme = useTheme();
  const screenSize = useScreenSize();
  const workspaceSlice = useAppSelector((state) => state.workspace);

  const { marginTop, marginBottom } = useMemo(() => {
    let marginTop = "";
    let marginBottom = "";

    if (workspaceSlice.activeView !== ActiveView.PROFILE) {
      marginTop = screenSize.isLarge ? "6.25px" : "12px";
    } else {
      marginTop = screenSize.isLarge ? "0px" : "9.75px";
    }

    if (
      workspaceSlice.activeView === ActiveView.CHAT ||
      workspaceSlice.activeView === ActiveView.CACHE
    ) {
      marginBottom = screenSize.isLarge ? "10px" : "16px";
    } else {
      marginBottom = "0px";
    }

    return { marginTop, marginBottom };
  }, [screenSize.isLarge, workspaceSlice.activeView]);

  return (
    <Box
      sx={{
        display: "fixed",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        position: "relative",
        height: "100%",
        width: "100%",
        marginTop,
        marginBottom,
        backgroundColor: theme.palette.background.paper,
        borderRadius: screenSize.isLarge ? 0 : "16px",
        boxShadow: `0px 0px 0px 1px rgba(0, 0, 0, 0.12)`,
        overflow: "hidden",
        overflowX: "hidden",
        maxHeight: "100%",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "space-between",
          justifyContent:
            workspaceSlice.activeView === ActiveView.CHAT
              ? "space-between"
              : "flex-start",
          width: "100%",
          height: "100%",
          marginTop: screenSize.isLarge ? "8px" : 0,
        }}
      >
        {viewForSelectedIcon({ workspaceSlice, handleIconClick })}
      </Box>
    </Box>
  );
};

const viewForSelectedIcon = ({ workspaceSlice, handleIconClick }) => {
  switch (workspaceSlice.activeView) {
    case ActiveView.CHAT:
      return <ChatView />;
    case ActiveView.PROFILE:
      return <ProfileView handleIconClicked={handleIconClick} />;
    case ActiveView.CACHE:
      return <CacheView />;
    default:
      return <ChatView />;
  }
};
