import { Button } from "@mui/material";
import { useAppDispatch } from "../../../../../app/store";
import { useNavigate } from "react-router-dom";
import {
  logout,
  setManualLogout,
} from "../../../../../features/users/userAuthSlice";

export const BackToLoginButton = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleBackToLogin = async () => {
    dispatch(setManualLogout(true));
    await dispatch(logout());
    setTimeout(() => {
      navigate("/auth/login");
    }, 300);
  };

  return (
    <Button
      variant="text"
      color="primary"
      onClick={handleBackToLogin}
      sx={{
        width: "194px",
        height: "40px",
        alignSelf: "center",
        fontSize: "0.85rem",
        fontWeight: "500",
        // not all caps
        textTransform: "none",
      }}
    >
      {"< Back To Login"}
    </Button>
  );
};
