import React from "react";
import { Typography, Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/system";
import { Attachment, Upload } from "../../icons";
import PrimaryIcon from "./PrimaryIcon";
import { FileUploader } from "react-drag-drop-files";
import { IErrorMessage } from "../../types";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { createKnowledgeDocs } from "../../features/experts/knowledgeDocsSlice";
import { showErrorNotification } from "../../features/ui/errorSlice";
import { UtilityService } from "../../services/UtilityService";
import { FileProcessor } from "../../services/FileProcessor";

interface KnowledgeUploaderProps {
  hideUploadButton: boolean;
  isExpertise?: boolean;
  isUrlInputActive: boolean;
}

const KnowledgeUploader: React.FC<KnowledgeUploaderProps> = ({
  hideUploadButton = false,
  isExpertise = false,
  isUrlInputActive = false,
}) => {
  const expertSlice = useAppSelector((state) => state.experts.activeExpert);
  const expertiseId = expertSlice?.expertise as string;
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const fileTypes = FileProcessor.VALID_FILE_TYPES;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = async (files: File[]) => {
    try {
      const processedFiles = await FileProcessor.processFiles(files, {
        expertiseId,
      });

      if (processedFiles.invalidFileCount > 0) {
        const errorMessage = {
          title: "Oops!",
          message: `${processedFiles.invalidFileCount} file(s) could not be processed. Please check file types and sizes.`,
        } as IErrorMessage;
        dispatch(showErrorNotification(errorMessage));
      }

      if (processedFiles.files.length > 0) {
        dispatch(createKnowledgeDocs(processedFiles));
      }
    } catch (error) {
      const errorMessage = {
        title: "Error",
        message: error.message,
      } as IErrorMessage;
      dispatch(showErrorNotification(errorMessage));
    }
  };

  const isMobile = UtilityService.getIsMobile();

  return (
    <FileUploader
      handleChange={handleChange}
      dropMessageStyle={{ display: "none" }}
      directory={true}
      multiple={true}
      name="file"
      types={fileTypes}
      children={
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent:
              isUrlInputActive || isSmallScreen ? "flex-end" : "space-between",
            borderRadius: "7px",
            border: `1px dashed ${alpha(theme.palette.text.secondary, 0.4)}`,
            height: "50px",
            width: isUrlInputActive || isSmallScreen ? "41px" : "100%",
            overflow: "hidden",
            paddingRight: isUrlInputActive || isSmallScreen ? "3px" : "0px",
            cursor: "pointer",
            "&:hover": {
              border: `1px dashed ${alpha(theme.palette.text.primary, 1.0)}`,
            },
          }}
        >
          {!isUrlInputActive && !isSmallScreen && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "180px",
              }}
            >
              <PrimaryIcon
                iconName="Upload"
                icon={<Attachment />}
                size="32px"
                disabled={true}
              />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  position: "relative",
                  color: theme.palette.text.secondary,
                  pointerEvents: "none",
                  marginLeft: "-4px",
                }}
              >
                <span>{"upload "}</span>
                {!isMobile && (
                  <span
                    style={{
                      fontWeight: 700,
                      color: theme.palette.text.primary,
                    }}
                  >
                    {"pdf, jpb, png"}
                  </span>
                )}
              </Typography>
            </Box>
          )}
          {(!hideUploadButton || isUrlInputActive || isSmallScreen) && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                backgroundColor: theme.palette.text.placeholder,
                borderRadius: "6px",
                width: "32px",
                height: "32px",
                marginRight: isUrlInputActive || isSmallScreen ? 0 : "3px",
              }}
            >
              <PrimaryIcon
                iconName="Upload"
                icon={<Upload />}
                size="32px"
                defaultColorOverride={theme.palette.text.primary}
                disabled={true}
                sx={{ marginLeft: "4px" }}
              />
            </Box>
          )}
        </Box>
      }
    />
  );
};

export default KnowledgeUploader;
