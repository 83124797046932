import { Chat, Prompt } from "../../../icons";

export const cacheInstructionPanelData = {
  title: "Your Context",
  lines: [
    `Type **@prompt** into the message bar along with your prompt to add that prompt to your cache.`,
    "Your prompt will be used as context in every chat with this Expert",
    "Uncheck any you want to temporarily disable",
  ],
  icon: <Prompt />,
};

export const chatInstructionPanelData = (firstName = null) => ({
  title: `Welcome${firstName ? `, ${firstName}` : "!"}`,
  lines: [
    "Type a message or click the mic icon to use speech-to-text",
    "Use **@** to access tools (e.g. @speakOn to quickly enable auto play for inbound messages)",
    "Click the upload icon to upload a file. Images and PDFs are currently supported.",
  ],
  icon: <Chat />,
});
