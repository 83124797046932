// MainMenuMobile.tsx
import { useEffect, useState } from "react";
import { Box, IconButton, Drawer, useTheme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { logout, setManualLogout } from "../../../features/users/userAuthSlice";
import { googleLogout } from "@react-oauth/google";
import MenuItemRow from "../../ui-elements/menu-tabs/MenuItemRow";
import {
  Chat,
  DarkMode,
  LightMode,
  Login,
  Logo,
  Logout,
  Signup,
} from "../../../icons";
import { setDarkMode } from "../../../features/ui/themeSlice";
import { updateUserPreferences } from "../../../features/users/userPreferencesSlice";
import { UtilityService } from "../../../services/UtilityService";
import virtual_experts_logo from "../../../images/virtual-experts-logo.png";

interface MainMenuMobileProps {
  isLoggedIn: boolean;
  isPaymentFailed: boolean;
  isWorkspace: boolean;
  isAccountPage: boolean;
  isOnboarded: boolean;
  isBetaApproved: boolean;
  isActiveSubscription: boolean;
  isVerified: boolean;
}

const MainMenuMobile = ({
  isLoggedIn,
  isPaymentFailed,
  isWorkspace,
  isAccountPage,
  isOnboarded,
  isBetaApproved,
  isActiveSubscription,
  isVerified,
}: MainMenuMobileProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const themeSlice = useAppSelector((state) => state.theme);
  const isDarkMode = themeSlice.isDarkMode;

  const userPreferences = useAppSelector(
    (state) => state.userPreferences.userPreferences
  );

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const buttons = [];

  if (isLoggedIn && isOnboarded && isBetaApproved && !isActiveSubscription) {
    buttons.push(
      <MenuItemRow
        title={"Choose Plan"}
        icon={<Logo />}
        onClick={() => navigate("/pricing")}
      />
    );
  } else {
    // if the current page is the pricing page, don't show any buttons
    if (
      !isWorkspace &&
      !isAccountPage &&
      isOnboarded &&
      isBetaApproved &&
      isVerified
    ) {
      buttons.push(
        <MenuItemRow
          title={"Pricing"}
          icon={<Logo />}
          onClick={() => navigate("/pricing")}
        />
      );
    }
  }

  if (isActiveSubscription) {
    if (!isWorkspace) {
      buttons.push(
        <MenuItemRow
          title={"Workspace"}
          icon={<Chat />}
          onClick={() => navigate("/workspace")}
        />
      );
    }
  }

  if (!isLoggedIn) {
    buttons.push(
      <MenuItemRow
        title={"Log In"}
        icon={<Login />}
        onClick={() => navigate("/auth/login")}
      />
    );

    buttons.push(
      <MenuItemRow
        title={"Sign Up"}
        icon={<Signup />}
        onClick={() => navigate("/auth/signup")}
      />
    );
  } else {
    buttons.push(
      <MenuItemRow
        title={isDarkMode ? "Go Light" : "Go Dark"}
        icon={isDarkMode ? <LightMode /> : <DarkMode />}
        onClick={toggleDarkMode}
      />
    );

    buttons.push(
      <MenuItemRow title={"Logout"} icon={<Logout />} onClick={signOut} />
    );
  }

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  function signOut() {
    dispatch(setManualLogout(true));
    navigate("/auth/login");
    googleLogout();
    dispatch(logout());
  }

  async function toggleDarkMode() {
    const newState = !isDarkMode;
    dispatch(setDarkMode(newState));

    const updatedUser = { ...userPreferences, prefersDarkMode: newState };
    try {
      await dispatch(updateUserPreferences(updatedUser));
    } catch (error) {
      console.log("Failed to update user preferences", error);
    }
  }

  useEffect(() => {
    setIsMobile(UtilityService.getIsMobile());
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        color: theme.palette.text.secondary,
        marginTop: "4px",
      }}
    >
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon
          style={{
            fontSize: isMobile ? "2.0rem" : "1.5rem",
          }}
        />
      </IconButton>
      <Drawer anchor={"right"} open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
            paddingTop: "10px",
            paddingBottom: "10px",
            transform: "scale(0.6)",
            cursor: "pointer",
          }}
          onClick={() => {
            toggleDrawer(false)();
            navigate("/home");
          }}
        >
          <img
            src={virtual_experts_logo}
            alt="virtual_experts_logo"
            width="210"
            height="30"
          />
        </Box>

        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          {
            // make each button with a key
            buttons.map((button, index) => (
              <Box key={index}>{button}</Box>
            ))
          }
        </Box>
      </Drawer>
    </Box>
  );
};

export default MainMenuMobile;
