import React, { useEffect } from "react";
import { Box, Divider, Grid } from "@mui/material";
import SectionBase from "../../workspace-page/content-views/profile-view/sections/SectionBase";
import DarkModeBlock from "./settings-components/DarkmodeBlock";
import CustomVoiceSelector from "../../../ui-elements/CustomVoiceSelector";
import NewsletterBlock from "./settings-components/NewsletterBlock";
import { useAppSelector } from "../../../../app/store";
import EnableTooltips from "./settings-components/EnableTooltips";

interface PreferencesBlockProps {
  containerWidth: number;
}

const PreferencesBlock: React.FC<PreferencesBlockProps> = ({
  containerWidth,
}) => {
  const userDetails = useAppSelector((state) => state.userDetails.userDetails);
  const userPreferences = useAppSelector(
    (state) => state.userPreferences.userPreferences
  );

  useEffect(() => {
    //console.log("containerWidth: " + containerWidth);
  }, [containerWidth]);

  const spacerPadding = containerWidth > 600 ? "24px" : "16px";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-column",
        alignItems: "flex-start",
        alignContent: "flex-start",
        height: "auto",
        width: "100%",
      }}
    >
      <SectionBase title="Preferences">
        <Box
          sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "24px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CustomVoiceSelector activeUserDetails={userDetails} />
          <Box height="24px" />
          <Divider orientation="horizontal" />
          <Box height={spacerPadding} />
          <DarkModeBlock userPreferences={userPreferences} />
          <Box height={spacerPadding} />
          <Divider orientation="horizontal" />
          <Box height={spacerPadding} />
          <EnableTooltips />
          <Box height={spacerPadding} />
          <Divider orientation="horizontal" />
          <Box height={spacerPadding} />
          <NewsletterBlock userPreferences={userPreferences} />
        </Box>
      </SectionBase>
    </Box>
  );
};

export default PreferencesBlock;
