import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Container } from "@mui/material";
import createGlobalTheme from "./customTheme/globalTheme";
import { useAppSelector } from "./app/store";
import TokenValidator from "./components/helpers/TokenValidator";
import { GlobalStylesComponent } from "./customTheme/GlobalStyles";
import ErrorMessengerPopup from "./components/helpers/ErrorMessengerPopup";
import AudioPlayer from "./components/misc/AudioPlayer";
import PageManager from "./components/pages/PageManager";
import { TouchToClick } from "./TouchToClick";
import DebugSnackbar from "./components/popups/DebugSnackbar";
import TimedAlertPopup from "./components/popups/timed-alert/TimedAlertPopup";
import ConfirmationPopup from "./components/popups/ConfirmationPopup";
import InstructionPanelPopup from "./components/popups/instruction-panel/InstructionPanelPopup";

const App: React.FC = () => {
  const isDarkMode = useAppSelector((state) => state.theme.isDarkMode);
  const theme = createGlobalTheme(isDarkMode);
  const clientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TouchToClick />
      <GlobalStylesComponent isDarkMode={isDarkMode} />
      <GoogleOAuthProvider clientId={clientId}>
        <Router>
          <DebugSnackbar />
          <Container
            className="parent-container"
            sx={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              overflowY: "scroll",
              WebkitOverflowScrolling: "touch",
              overscrollBehavior: "auto",
            }}
          >
            <TokenValidator />
            <ErrorMessengerPopup />
            <AudioPlayer />
            <PageManager />
            <TimedAlertPopup />
            <ConfirmationPopup />
            <InstructionPanelPopup />
          </Container>
        </Router>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
};

export default App;
