import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface ConfirmDowngradePopupProps {
  open: boolean;
  onClose: (confirm: boolean) => void;
}

const ConfirmDowngradePopup: React.FC<ConfirmDowngradePopupProps> = ({
  open,
  onClose,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "16px",
          padding: "8px",
          paddingBottom: "16px",
          maxWidth: { xs: "90vw", sm: "400px" },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography
          sx={{
            fontWeight: 800,
            fontSize: "18px",
            color: theme.palette.text.primary,
            textAlign: "center",
          }}
        >
          Confirm Plan Downgrade
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            fontFamily: "Poppins-Regular",
            fontSize: "14px",
            color: theme.palette.text.primary,
            textAlign: "center",
            paddingBottom: "6px",
          }}
        >
          By clicking continue, your subscription will be downgraded at the end
          of the current billing cycle. Are you sure you want to proceed?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent="center"
          width="100%"
        >
          <Button
            sx={{
              color: theme.palette.colors.grayScale.white,
              backgroundColor:
                theme.palette.mode === "dark"
                  ? theme.palette.colors.grayScale[500]
                  : theme.palette.colors.grayScale[300],
              "&:hover": {
                backgroundColor: theme.palette.colors.grayScale[400],
              },
              width: "120px",
              marginRight: "16px",
            }}
            onClick={() => onClose(false)}
          >
            Cancel
          </Button>
          <Button
            sx={{
              color: theme.palette.colors.grayScale.white,
              backgroundColor: theme.palette.error.main,
              "&:hover": {
                backgroundColor: theme.palette.error.dark,
              },
              width: "120px",
            }}
            onClick={() => onClose(true)}
          >
            Confirm
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDowngradePopup;
