import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import * as api from "../../routes/routes";
import { handleAxiosError } from "../../app/ErrorHandler";
import {
  IErrorMessage,
  IMessage,
  INewMessagePayload,
  ICursorData,
} from "../../types/index";
import { clearMessageFiles, updateActiveMessage } from "./workspaceSlice";
import {
  playAudio,
  forceStopAudio,
  setIsPlaying,
  setIsLoading,
  setStopOverride,
} from "../audio/textToSpeechSlice";
import { ITextToSpeechPayload } from "../../types/audio/ITextToSpeech";
import { RootState } from "../../app/store";
import { showErrorNotification } from "../ui/errorSlice";
import { hydrateChat } from "./chatSlice";
import { IPlaceholderFile, MessageState } from "../../types/chat/IMessage";
import { LocalStorageService } from "../../services/LocalStorageService";
import { FileProcessor } from "../../services/FileProcessor";

const initialState = {
  loading: false,
  messages: [] as IMessage[],
  activeMessage: null as IMessage | null,
  messageFiles: [] as File[],
  editing: false,
  copyMessage: null as string | null,
  cursorData: null as ICursorData | null,
  error: "" as unknown,
  preparingEdit: false,
  showFullQueue: "" as string,
  currentString: "",
};

export const fetchMessages = createAsyncThunk(
  "messages/fetchMessages",
  async (chatId: string, { rejectWithValue }) => {
    try {
      const result = await api.fetchMessages(chatId);
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const sendMessage = createAsyncThunk(
  "messages/sendMessage",
  async (messageData: INewMessagePayload, { dispatch, rejectWithValue }) => {
    try {
      const hasFiles = messageData.messageFiles?.length > 0;
      let filePayloads = [];

      if (hasFiles) {
        const processedFiles = await FileProcessor.processFiles(
          messageData.messageFiles,
          { chatId: messageData.chatId }
        );

        if (processedFiles.invalidFileCount > 0) {
          throw new Error(
            `${processedFiles.invalidFileCount} file(s) could not be processed.`
          );
        }

        filePayloads = processedFiles.files;
      }

      const createTempUid = () => {
        return Math.random().toString(36).substring(2, 15);
      };

      // create temporary uid for each file that we can use to match to a sibling knowledge doc when the knowledge doc comes back
      filePayloads = filePayloads.map((file) => ({
        ...file,
        tempUid: createTempUid(),
      }));

      const messageDataWithFiles = {
        ...messageData,
        uploadingFiles: hasFiles,
        messageFiles: [],
        messageFilesPayloads: filePayloads,
      } as INewMessagePayload;

      const result = await api.sendMessage(messageDataWithFiles);
      dispatch(clearMessageFiles(true));
      await dispatch(hydrateChat(messageData.chatId));

      const messageId = result.data._id;
      let message = result.data;

      if (hasFiles) {
        const placeholderFiles = messageData.messageFiles.map((file) => ({
          file,
          tempUid: createTempUid(),
        })) as IPlaceholderFile[];

        await LocalStorageService.setFilesForKey(
          `${messageId}-placeholderFiles`,
          placeholderFiles
        );
        message = { ...message, placeholderFiles };
      }

      return message;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const updateMessage = createAsyncThunk(
  "messages/updateMessage",
  async (messageData: IMessage, { dispatch, rejectWithValue }) => {
    try {
      const result = await api.updateMessage(messageData);
      const chatId = messageData.chatId as string;
      dispatch(hydrateChat(chatId));
      dispatch(fetchMessages(chatId));
      dispatch(setEditing(false));
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const setActiveMessage = createAsyncThunk(
  "messages/setActiveMessage",
  async (messageData: IMessage, { dispatch, rejectWithValue }) => {
    try {
      dispatch(updateActiveMessage(messageData));
      return messageData;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const deleteMessage = createAsyncThunk(
  "messages/deleteMessage",
  async (messageId: string, { rejectWithValue }) => {
    try {
      const result = await api.deleteMessage(messageId);
      return result.data;
    } catch (error) {
      return rejectWithValue(handleAxiosError(error));
    }
  }
);

// NOTE - that this is called using a string in SocketMiddleware.tsx
export const onReceiveMessage = createAsyncThunk(
  "messages/onReceiveMessage",
  async (message: IMessage, { getState, dispatch }) => {
    const state = getState() as RootState;
    const activeExpert = state.experts.activeExpert;
    const activeChatId = activeExpert?.activeChat;

    if (activeChatId === message.chatId) {
      dispatch(receiveMessage(message));
      const autoPlayEnabled = state.workspace.isAutoPlayEnabled;
      const isPlayingAudio = state.textToSpeech.isPlaying;

      if (autoPlayEnabled && !isPlayingAudio) {
        dispatch(playMessage(message));
      }
    }
  }
);

export const playMessage = createAsyncThunk(
  "messages/playMessage",
  async (message: IMessage, { getState, dispatch, rejectWithValue }) => {
    if (message === null) {
      dispatch(forceStopAudio());
      return;
    }

    try {
      const text = message.message;
      const voiceId = message.senderVoiceId;

      dispatch(setIsPlaying(false));
      dispatch(setIsLoading(true));

      const textToSpeechPayload = {
        text: text,
        voiceId: voiceId,
        isPublic: false,
        messageId: message._id,
      } as ITextToSpeechPayload;

      const response = await api.textToSpeech(textToSpeechPayload);
      const audioUrl = response.data.audioUrl;
      message = { ...message, tempAudioUrl: audioUrl };

      const state = getState() as RootState;
      if (state.textToSpeech.stopOverride) {
        dispatch(setStopOverride(false));
        dispatch(setIsLoading(false));
      } else {
        dispatch(playAudio(message));
      }

      return audioUrl; // This URL is used by the audio component but does so in workspaceSlice
    } catch (error) {
      dispatch(forceStopAudio());
      dispatch(setIsLoading(false));
      dispatch(setStopOverride(false));

      if (error.response.status === 402) {
        const outOfDataErrorMessage = {
          title: "Out of Data!",
          message:
            "You appear to be out of data. Please either wait until your next billing cycle or upgrade your plan and try again.",
          outOfData: true,
        } as IErrorMessage;
        dispatch(showErrorNotification(outOfDataErrorMessage));
        return rejectWithValue(outOfDataErrorMessage);
      }

      let message =
        "Something went wrong. Please try this feature again later. Sorry for the inconvenience.";
      if (error.response.status === 422) {
        message =
          "This text contains inappropriate content.  Please edit the message and try playing again.";
      }

      const errorMessage = {
        title: "Error playing message.",
        message: message,
      } as IErrorMessage;

      if (error.response.status !== 405) {
        dispatch(showErrorNotification(errorMessage));
      }

      return rejectWithValue(handleAxiosError(error));
    }
  }
);

export const messagesSlice = createSlice({
  name: "messages",
  initialState: initialState,
  reducers: {
    clearMessages: (state) => {
      state.messages = [];
      state.loading = false;
      state.error = "";
    },
    clearActiveMessage: (state) => {
      state.activeMessage = null;
      state.loading = false;
      state.error = "";
    },
    setEditing: (state, action: PayloadAction<boolean>) => {
      state.editing = action.payload;
    },
    receiveMessage: (state, action: PayloadAction<IMessage>) => {
      state.messages.unshift(action.payload);
    },
    setCursorData: (state, action: PayloadAction<ICursorData>) => {
      state.cursorData = action.payload;
    },
    resetCursorData: (state) => {
      state.cursorData = null;
    },
    setPreparingEdit: (state, action: PayloadAction<boolean>) => {
      state.preparingEdit = action.payload;
    },
    setIsCopying: (state, action: PayloadAction<any>) => {
      state.copyMessage = action.payload;
    },
    setShowFullQueue: (state, action: PayloadAction<string>) => {
      // payload is the messageId.  Set the messageId to show ignored state
      state.showFullQueue = action.payload;

      state.messages = state.messages.map((message) => {
        if (message._id === action.payload) {
          return { ...message, messageState: MessageState.Ignored };
        }
        return message;
      });

      if (state.activeMessage?._id === action.payload) {
        state.activeMessage = {
          ...state.activeMessage,
          messageState: MessageState.Ignored,
        };
      }
    },
    setStateForMessage: (
      state,
      action: PayloadAction<{ messageId: string; messageState: MessageState }>
    ) => {
      state.messages = state.messages.map((message) => {
        if (message._id === action.payload.messageId) {
          return { ...message, messageState: action.payload.messageState };
        }
        return message;
      });

      if (state.activeMessage?._id === action.payload.messageId) {
        state.activeMessage = {
          ...state.activeMessage,
          messageState: action.payload.messageState,
        };
      }
    },
    setCurrentString: (state, action: PayloadAction<string>) => {
      state.currentString = action.payload;
    },
    setPlaceholderFilesForMessage: (
      state,
      action: PayloadAction<{
        messageId: string;
        placeholderFiles: IPlaceholderFile[];
      }>
    ) => {
      state.messages = state.messages.map((message) => {
        if (message._id === action.payload.messageId) {
          message.placeholderFiles = action.payload.placeholderFiles;
        }

        return message;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setActiveMessage.fulfilled, (state, action) => {
      state.activeMessage = action.payload;
    });
    builder.addCase(fetchMessages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMessages.fulfilled, (state, action) => {
      state.loading = false;
      state.messages = action.payload;
      state.error = "";
    });
    builder.addCase(fetchMessages.rejected, (state, action) => {
      state.loading = false;
      state.messages = [];
      state.error = action.error.message;
    });
    builder.addCase(sendMessage.fulfilled, (state, action) => {
      state.messages.unshift(action.payload);
    });
    builder.addCase(sendMessage.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(
      updateMessage.fulfilled,
      (state, action: PayloadAction<IMessage>) => {
        state.messages = state.messages.map((message) => {
          if (message._id === action.payload._id) {
            return action.payload;
          }
          return message;
        });
      }
    );
    builder.addCase(updateMessage.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(
      deleteMessage.fulfilled,
      (state, action: PayloadAction<IMessage>) => {
        state.messages = state.messages.filter(
          (message) => message._id !== action.payload._id
        );
      }
    );
    builder.addCase(deleteMessage.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(playMessage.rejected, (state, action) => {
      //state.isWorking = false;
      state.error = action.error.message;
    });
  },
});

export const {
  clearMessages,
  clearActiveMessage,
  setEditing,
  receiveMessage,
  setCursorData,
  resetCursorData,
  setPreparingEdit,
  setIsCopying,
  setShowFullQueue,
  setStateForMessage,
  setCurrentString,
  setPlaceholderFilesForMessage,
} = messagesSlice.actions;
export default messagesSlice.reducer;
