import React, { useEffect, useState } from "react";
import { Box, Button, darken, useTheme } from "@mui/material";
import PopupBase from "../PopupBase";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import { setAPopupIsShowing } from "../../../features/ui/confirmationPopupSlice";
import InstructionPanel from "./InstructionPanel";
import { hideInstructionPopup } from "../../../features/ui/instructionPopupSlice";
import { UtilityService } from "../../../services/UtilityService";

const InstructionPanelPopup: React.FC = () => {
  const dispatch = useAppDispatch();
  const instructionPopup = useAppSelector((state) => state.instructionPopup);
  const expertSlice = useAppSelector((state) => state.experts);
  const activeExpert = expertSlice.activeExpert;
  const activeExpertColor = activeExpert?.color;

  useEffect(() => {
    dispatch(setAPopupIsShowing(instructionPopup.show));
  }, []);

  const handleClose = () => {
    dispatch(setAPopupIsShowing(false));
    dispatch(hideInstructionPopup());
  };

  function handleKeyPress(event: React.KeyboardEvent) {
    if (event.key === "Enter") {
      handleClose();
    }
  }

  const isMobile = UtilityService.getIsMobile();

  return instructionPopup.show ? (
    <PopupBase open={instructionPopup.show} onClose={handleClose}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        gap={1}
        width={"100%"}
      >
        <Box height="16px" />
        <InstructionPanel
          title={instructionPopup.title}
          lines={instructionPopup.lines}
          icon={instructionPopup.icon}
          overridePadding={true}
        />
        <Box height="16px" />
        <Button
          sx={{
            alignSelf: "center",
            color: "white",
            backgroundColor: activeExpertColor ?? "primary.main",
            "&:hover": !isMobile
              ? {
                  backgroundColor: activeExpertColor
                    ? darken(activeExpertColor, 0.1)
                    : "primary.dark",
                }
              : undefined,
            width: "100%",
            maxWidth: "120px",
          }}
          autoFocus={true}
          disableFocusRipple={true}
          onClick={handleClose}
          onKeyDown={handleKeyPress}
        >
          OK
        </Button>
      </Box>
    </PopupBase>
  ) : null;
};

export default InstructionPanelPopup;
