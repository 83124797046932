import { useEffect, useState, ReactNode } from "react";
import { Box, Typography, alpha, useTheme } from "@mui/material";
import BlockTitle from "../../../../ui-elements/BlockTitle";
import CustomCheckbox from "../../../../ui-elements/CustomCheckbox";

interface AuthCheckboxBlockProps {
  title: string;
  subtitle: string | ReactNode;
  setValue: (value: string) => void;
  value: string;
}

const AuthCheckboxBlock = ({
  title,
  subtitle,
  setValue,
  value,
}: AuthCheckboxBlockProps) => {
  const theme = useTheme();
  const [checked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(value === "true" ? true : false);
  }, [value]);

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.checked;
    setValue(newValue ? "true" : "false");
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="flex-start"
      pb={2}
      gap={"16px"}
      width="100%"
    >
      <Box width="24px" pt="5px">
        <CustomCheckbox
          isChecked={checked}
          setIsChecked={handleCheckboxChange}
          size="24px"
        />
      </Box>
      <Box display="flex" flexDirection="column" width="calc(100% - 24px)">
        <Typography
          fontWeight={800}
          fontSize={"0.85rem"}
          color={alpha(theme.palette.text.secondary, 0.9)}
        >{`${title}`}</Typography>
        <Typography
          fontWeight={300}
          fontSize={"0.85rem"}
          color={alpha(theme.palette.text.secondary, 0.7)}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default AuthCheckboxBlock;
