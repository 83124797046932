import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Grid,
  Divider,
  Button,
  useTheme,
} from "@mui/material";
import { PulseLoader } from "react-spinners";
import { useAppSelector, useAppDispatch } from "../../../../../../app/store";
import { TranscriptionState } from "../../../../../../features/audio/transcriptionSlice";
import {
  setEditing,
  setPreparingEdit,
} from "../../../../../../features/chats/messagesSlice";
import {
  setIsTranscribing,
  setIsAutoPlayEnabled,
  ActiveView,
} from "../../../../../../features/chats/workspaceSlice";
import { getIconForCommand } from "../../../../../../features/commands/commandSource";
import {
  TranscribeOn,
  TranscribeOff,
  Command,
  Send,
  Edit,
  Upload,
} from "../../../../../../icons";
import { PrimaryIcon } from "../../../../../ui-elements";
import CustomTooltip from "../../../../../ui-elements/custom-tooltip/CustomTooltip";
import { UtilityService } from "../../../../../../services/UtilityService";

const ICON_SIZE = 24;
const ICON_PADDING = 6;
const BUTTON_SIZE = ICON_SIZE + ICON_PADDING * 2;

interface ActionButtonsProps {
  handleUploadClick: () => void;
  handleSendIconTapped: () => void;
  handleCommandsIconTapped: () => void;
  isFocused: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  handleUploadClick,
  handleSendIconTapped,
  handleCommandsIconTapped,
  isFocused,
}) => {
  const commandsSlice = useAppSelector((state) => state.commands);
  const activeExpert = useAppSelector((state) => state.experts.activeExpert);
  const workspaceSlice = useAppSelector((state) => state.workspace);
  const transcriptionSlice = useAppSelector(
    (state) => state.audioTranscription
  );

  const transcriptionState = transcriptionSlice.transcriptionState;
  const messagesSlice = useAppSelector((state) => state.messages);
  const hasText = messagesSlice.currentString !== "";
  const isActiveCommand = commandsSlice?.activeCommand !== null;
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [isMouseDown, setIsMouseDown] = useState(false);

  const isMobile = UtilityService.getIsMobile();

  const handleMouseDown = () => {
    if (isMobile) {
      return;
    }
    setIsMouseDown(true);
  };

  const handleMouseUp = () => {
    if (isMobile) {
      return;
    }
    setIsMouseDown(false);
  };

  const handleMouseOver = () => {
    if (isMobile) {
      return;
    }
    if (isMouseDown) {
      setIsMouseDown(true);
    }
  };

  const handleMouseLeave = () => {
    if (isMobile) {
      return;
    }
    setIsMouseDown(false);
  };

  useEffect(() => {
    if (messagesSlice.editing) {
      dispatch(setIsTranscribing(false));
      dispatch(setIsAutoPlayEnabled(false));
    }
  }, [messagesSlice.editing]);

  useEffect(() => {
    // console.log("isFocused changed in action buttons");
    // dispatch(
    //   showDebugSnackbar({
    //     message: `isFocused: ${isFocused}`,
    //     severity: "info",
    //   })
    // );
  }, [isFocused]);

  useEffect(() => {
    // console.log("isTranscribing", isTranscribing);
  }, [commandsSlice]);

  useEffect(() => {
    // console.log("New transcriptionState", transcriptionState);
  }, [transcriptionSlice]);

  const handleMicButtonClick = () => {
    if (transcriptionState === TranscriptionState.Connected) {
      dispatch(setIsTranscribing(false));
      return;
    }

    if (transcriptionState === TranscriptionState.Disconnected) {
      dispatch(setIsTranscribing(true));
      return;
    }
  };

  const cancelEditClicked = () => {
    dispatch(setEditing(false));
    dispatch(setPreparingEdit(false));
  };

  const submitIcon = () => {
    if (
      messagesSlice.editing &&
      workspaceSlice.activeView !== ActiveView.CACHE
    ) {
      return <Edit />;
    }

    if (isActiveCommand) {
      if (
        commandsSlice.activeCommand !== null &&
        commandsSlice.activeCommand.command !== null
      ) {
        return getIconForCommand(commandsSlice.activeCommand.command);
      }
    }

    return <Send />;
  };

  const transcriptionIcon = () => {
    switch (transcriptionState) {
      case TranscriptionState.Connected:
        return <TranscribeOn />;
      case TranscriptionState.Connecting:
        return <TranscribeOn />;
      case TranscriptionState.Disconnected:
        return <TranscribeOff />;
      case TranscriptionState.Disconnecting:
        return <TranscribeOff />;
      default:
        return <TranscribeOff />;
    }
  };

  const transcriptionColor = () => {
    const connectedColor = theme.palette.colors.red[500];
    const defaultColor = theme.palette.text.secondary;

    switch (transcriptionState) {
      case TranscriptionState.Connected:
        return connectedColor;
      case TranscriptionState.Connecting:
      case TranscriptionState.Disconnecting:
      case TranscriptionState.Disconnected:
        return defaultColor;

      default:
        return defaultColor;
    }
  };

  const currentlyTranscribing =
    transcriptionState === TranscriptionState.Connected ||
    transcriptionState === TranscriptionState.Connecting;

  const getColorForState = () => {
    if (isActiveCommand || messagesSlice.editing) {
      return activeExpert?.color;
    } else if (isFocused && !currentlyTranscribing) {
      if (hasText) {
        return activeExpert?.color;
      }
      return theme.palette.text.secondary;
    } else if (currentlyTranscribing) {
      return theme.palette.colors.red[500];
    } else {
      return theme.palette.text.secondary;
    }
  };

  return (
    <Grid
      container
      sx={{
        direction: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "8px 6px 4px 8px",
        width: "100%",
      }}
    >
      <Box display={"flex"} alignItems={"center"} paddingBottom={"0px"}>
        {!messagesSlice.editing &&
          !isActiveCommand &&
          workspaceSlice.activeView !== ActiveView.CACHE &&
          (transcriptionState === TranscriptionState.Connecting ||
          transcriptionState === TranscriptionState.Disconnecting ? (
            <Box
              display={"flex"}
              flexDirection={"column"}
              height={"32px"}
              width={"33px"}
              justifyContent={"center"}
              alignContent={"center"}
              marginLeft={"12px"}
              marginRight={"1.5px"}
            >
              <PulseLoader
                color={transcriptionColor()}
                loading={true}
                size={4}
                margin={1}
              />
            </Box>
          ) : (
            <PrimaryIcon
              iconName="Transcribe"
              icon={transcriptionIcon()}
              handleIconClick={handleMicButtonClick}
              selectedIcon={
                transcriptionSlice.transcriptionState !==
                TranscriptionState.Disconnected
                  ? "Transcribe"
                  : ""
              }
              stopPropagation={true}
              sx={{
                color: transcriptionColor(),
                marginLeft: "4px",
                zIndex: 1000,
              }}
              selectedColorOverride={transcriptionColor()}
            />
          ))}
        {!isActiveCommand && !messagesSlice.editing && (
          <PrimaryIcon
            iconName="Upload"
            icon={<Upload />}
            handleIconClick={handleUploadClick}
            sx={{
              marginRight: "4px",
              zIndex: 1000,
            }}
          />
        )}
        {messagesSlice.editing &&
          workspaceSlice.activeView !== ActiveView.CACHE && (
            <Button
              onClick={cancelEditClicked}
              sx={{
                textTransform: "none",
                fontSize: "12px",
                fontWeight: 800,
                backgroundColor: "transparent",
                color: activeExpert.color,
                borderRadius: "12px",
                paddingLeft: "10px",
                paddingRight: "10px",

                "&:hover": !isMobile && {
                  backgroundColor: "transparent",
                  transform: "scale(1.05)",
                },
                "& .MuiTouchRipple-root": {
                  display: "none",
                },
                transition: "transform 0.1s ease-in-out",
              }}
            >
              Cancel
            </Button>
          )}

        {!messagesSlice.editing && (
          <PrimaryIcon
            iconName="Commands"
            icon={<Command />}
            sx={{
              opacity: isActiveCommand ? 0 : 1,
              zIndex: 1000,
            }}
            disabled={isActiveCommand ? true : false}
            handleIconClick={handleCommandsIconTapped}
          />
        )}
        {!isActiveCommand && (
          <Divider
            orientation={"vertical"}
            sx={{
              width: "1px",
              backgroundColor: theme.palette.background.divider,
              marginLeft: "8px",
              marginRight: "14px",
              height: "24px",
            }}
          />
        )}
        <CustomTooltip title={"Submit"}>
          <IconButton
            sx={{
              color: getColorForState(),
              backgroundColor: "transparent",
              paddingBottom: "6px",
              paddingTop: "6px",
              paddingLeft: 0,
              paddingRight: 0,
              height: BUTTON_SIZE,
              width: BUTTON_SIZE,
              "&:hover": !isMobile && {
                backgroundColor: "transparent",
                color: currentlyTranscribing
                  ? theme.palette.colors.red[500]
                  : activeExpert?.color,
              },
              transition: "none",
            }}
            onClick={handleSendIconTapped}
            disableFocusRipple
            disableRipple
          >
            <Box
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseLeave}
              onMouseOver={handleMouseOver}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "inherit",

                height: "32px",
                "&:hover": !isMobile && {
                  backgroundColor: "transparent",
                  transform: !isMouseDown ? "scale(1.3)" : "scale(0.9)",
                },
                transition: "transform 0.1s ease-in-out",
                overflow: "hidden",
                padding: "3px",
              }}
            >
              {submitIcon()}
            </Box>
          </IconButton>
        </CustomTooltip>
      </Box>
    </Grid>
  );
};

export default ActionButtons;
