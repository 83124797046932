import React, { useEffect, useState } from "react";
import { Box, Divider, useTheme } from "@mui/material";
import SectionBase from "../../workspace-page/content-views/profile-view/sections/SectionBase";
import { Input } from "../../../ui-elements";
import { InputData } from "../../../ui-elements/CustomInput";
import CustomDatePicker from "../../../ui-elements/CustomDatePicker";
import BlockTitle from "../../../ui-elements/BlockTitle";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { updateUserDetails } from "../../../../features/users/userDetailsSlice";

interface MyInfoBlockProps {
  containerWidth: number;
}

const MyInfoBlock: React.FC<MyInfoBlockProps> = ({ containerWidth }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector((state) => state.userDetails.userDetails);
  // 16 years ago from today's date
  const sixteenYearsAgo = new Date();
  sixteenYearsAgo.setFullYear(sixteenYearsAgo.getFullYear() - 16);
  const initialDate =
    userDetails && userDetails.birthday
      ? new Date(userDetails.birthday)
      : sixteenYearsAgo;

  const [date, setDate] = useState(initialDate);

  useEffect(() => {
    // console.log("containerWidth: " + containerWidth);
  }, [containerWidth]);

  useEffect(() => {
    if (date !== initialDate) {
      const updatedUser = { ...userDetails, birthday: date };
      dispatch(updateUserDetails(updatedUser));
    }
  }, [date]);

  const handleSubmitInput = async (inputData: InputData) => {
    console.log(`inputData ${inputData}`);
  };

  function getAge() {
    // convert date into an age
    const today = new Date();
    const birthDate = new Date(date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    // if the current month is less than the birth month, then subtract 1 from age
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  }

  const spacerPadding = containerWidth > 600 ? "24px" : "16px";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-column",
        alignItems: "flex-start",
        alignContent: "flex-start",
        height: "auto",
        width: "100%",
      }}
    >
      <SectionBase title="My Info">
        <Box
          sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "24px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <BlockTitle
            title="Name"
            subtitle={`${userDetails?.firstName} ${userDetails?.lastName}`}
            description="Experts will use this name to address you in conversations."
            overrideMarginTop={true}
          />
          <Box height={spacerPadding} />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "16px",
              width: "100%",
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Input
                startingValue={userDetails?.firstName}
                name="first-name"
                label="First"
                selectedColorOverride={theme.palette.primary.main}
                handleSubmit={handleSubmitInput}
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <Input
                startingValue={userDetails?.lastName}
                name="last-name"
                label="Last"
                selectedColorOverride={theme.palette.primary.main}
                handleSubmit={handleSubmitInput}
              />
            </Box>
          </Box>
          <Box height={"32px"} />
          <Divider orientation="horizontal" />
          <Box height={spacerPadding} />
          <BlockTitle
            title="Age"
            subtitle={getAge()}
            description="Used to authorize usage and also help experts provide relevant answers."
            overrideMarginTop={true}
          />
          <Box height={"16px"} />
          <CustomDatePicker
            selectedDate={date}
            onDateChange={(newDate: Date) => setDate(newDate)}
          />
        </Box>
      </SectionBase>
    </Box>
  );
};

export default MyInfoBlock;
