import { useEffect, useRef } from "react";
import { EditorState, ContentState, SelectionState } from "draft-js";
import { UtilityService } from "../../../../../../services/UtilityService";

export const useMobileKeyboardMicRecognizer = (
  editorWrapperRef: React.RefObject<HTMLElement>,
  messageBarState: { editorState: EditorState | null }
) => {
  const isProcessingVoiceInput = useRef(false);

  useEffect(() => {
    const isMobile = UtilityService.getIsMobile();
    if (!isMobile) return;

    const getEditorElement = (): HTMLElement | null =>
      editorWrapperRef.current?.querySelector('[contenteditable="true"]') ||
      null;

    const handleVoiceInput = (): void => {
      if (isProcessingVoiceInput.current) return;
      isProcessingVoiceInput.current = true;

      try {
        const editorElement = getEditorElement();
        if (!editorElement) {
          throw new Error("Editor element not found");
        }

        const domText = editorElement.innerText.trim();
        // console.log("[DEBUG] DOM text after voice input:", domText);

        // Get the current EditorState and its decorator
        const currentEditorState =
          messageBarState.editorState || EditorState.createEmpty();
        const decorator = currentEditorState.getDecorator();

        // Create a new ContentState from the current DOM content
        const newContentState = ContentState.createFromText(domText);

        // Create a new EditorState
        let newEditorState = EditorState.createWithContent(newContentState);

        // Set selection to the end of the content
        const lastBlock = newContentState.getLastBlock();
        const lastBlockKey = lastBlock.getKey();
        const lastBlockLength = lastBlock.getLength();
        const newSelection = SelectionState.createEmpty(lastBlockKey).merge({
          anchorOffset: lastBlockLength,
          focusOffset: lastBlockLength,
        });

        newEditorState = EditorState.forceSelection(
          newEditorState,
          newSelection
        );

        // Apply the decorator after creating the new EditorState
        if (decorator) {
          newEditorState = EditorState.set(newEditorState, { decorator });
        }

        // Update messageBarState
        messageBarState.editorState = newEditorState;

        // console.log("[DEBUG] New EditorState:", newEditorState.toJS());
        // console.log(
        //   "[DEBUG] New ContentState:",
        //   newEditorState.getCurrentContent().toJS()
        // );
        // console.log(
        //   "[DEBUG] New Selection:",
        //   newEditorState.getSelection().toJS()
        // );
      } catch (error) {
        console.error("[DEBUG] Error in handleVoiceInput:", error);
      } finally {
        isProcessingVoiceInput.current = false;
      }
    };

    const debouncedHandleVoiceInput = UtilityService.debounce(
      handleVoiceInput,
      100
    );

    const handleInputEvent = (event: InputEvent): void => {
      //console.log("[DEBUG] Input event:", event.inputType);
      if (event.inputType === "insertText") {
        debouncedHandleVoiceInput();
      }
    };

    const editorElement = getEditorElement();
    if (editorElement) {
      editorElement.addEventListener(
        "input",
        handleInputEvent as EventListener
      );
    }

    return () => {
      if (editorElement) {
        editorElement.removeEventListener(
          "input",
          handleInputEvent as EventListener
        );
      }
    };
  }, [editorWrapperRef, messageBarState]);
};
