// create a tooltip description that's 10-15 words long for each tool. We'll pass in a name into the const, siwtch on the name, and return the description for the tooltip

export const getTooltipDescription = (name: string) => {
  switch (name) {
    case "Speak":
      return "This will automatically speak the expert's messages as they come in.";
    case "Commands":
      return "Quick and easy shortcuts to help you work faster.";
    case "Profile":
      return "Add urls and files to your Expert's knowledge base & control their personality.";
    case "Cache":
      return "Save prompts to your Expert's cache and they'll be used in all chats with this Expert.";
    case "Chat":
      return "This is where you and your Expert Get. Stuff. Done.";
    case "Delete & New":
      return "Clear out this chat and start over.";
    case "Save & New":
      return "Save this chat to your Expert's chat history to access later.";
    case "Transcribe":
      return "Convert your speech to text.";
    case "Upload":
      return "Upload images and PDFs at the Chat level. Go to Expert profile to persist documents between chats.";
    case "Submit":
      return "Get a response from your Expert.";
    default:
      return "";
  }
};
