import { useEffect, useCallback } from "react";
import { EditorState, ContentState, SelectionState } from "draft-js";
import { useAppDispatch, useAppSelector } from "../../../../../../app/store";
import {
  ActiveView,
  setCurrentMessageText,
  setIsTranscribing,
  setCurrentPromptText,
  cancelEditPrompt,
} from "../../../../../../features/chats/workspaceSlice";
import { selectCommand } from "../../../../../../features/commands/commandsSlice";
import {
  createCommandDecorator,
  createEmptyDecoratedEditor,
} from "../commands/CommandDecorator";
import { IMessageBarState } from "./MessageBarState";
import {
  setEditing,
  setPreparingEdit,
} from "../../../../../../features/chats/messagesSlice";
// Adjust the import path as needed

export const useViewSetter = (
  messageBarState: IMessageBarState,
  activeExpert: any,
  getStateWithCommands: (editorState: EditorState) => EditorState
) => {
  const dispatch = useAppDispatch();
  const workspaceSlice = useAppSelector((state) => state.workspace);
  const {
    activeView,
    currentMessageText,
    currentPromptText,
    isTranscribing,
    isEditPromptMode,
  } = workspaceSlice;
  const messagesSlice = useAppSelector((state) => state.messages);
  const commands = useAppSelector((state) => state.commands.commands);

  // Creates a new EditorState with given text
  const createEditorState = useCallback(
    (text: string, shouldDecorate = true) => {
      const contentState = ContentState.createFromText(text);
      const editorState = shouldDecorate
        ? EditorState.createWithContent(
            contentState,
            createCommandDecorator(commands, activeExpert?.color)
          )
        : EditorState.createWithContent(contentState);

      const lastBlock = contentState.getBlockMap().last();
      const selection = SelectionState.createEmpty(lastBlock.getKey()).merge({
        anchorOffset: lastBlock.getLength(),
        focusOffset: lastBlock.getLength(),
      });

      return EditorState.forceSelection(editorState, selection);
    },
    [commands, activeExpert]
  );

  // Updates editor state with new text and view settings
  const updateEditorState = useCallback(
    (text: string, view: ActiveView, showPlaceholder = false) => {
      const newEditorState = createEditorState(text);
      const formattedEditor = getStateWithCommands(newEditorState);

      return {
        localView: view,
        showPlaceholder,
        editorState: formattedEditor,
      };
    },
    [createEditorState, getStateWithCommands]
  );

  // Handles state changes when switching views
  const handleViewChange = useCallback(() => {
    const currentText = messageBarState.editorState
      .getCurrentContent()
      .getPlainText();

    if (
      activeView === ActiveView.CACHE &&
      messageBarState.localView === ActiveView.CHAT
    ) {
      if (messagesSlice.editing) {
        dispatch(setEditing(false));
        dispatch(setPreparingEdit(false));
      }

      dispatch(setCurrentMessageText(currentText));
      if (isTranscribing) dispatch(setIsTranscribing(false));
    } else if (
      activeView === ActiveView.CHAT &&
      messageBarState.localView === ActiveView.CACHE
    ) {
      if (isEditPromptMode) {
        dispatch(cancelEditPrompt());
        dispatch(setCurrentPromptText("")); // Clear the prompt text when cancelling edit mode
      } else {
        dispatch(setCurrentPromptText(currentText));
      }
    }

    dispatch(selectCommand(null));

    if (activeView === ActiveView.CHAT) {
      return updateEditorState(
        currentMessageText,
        activeView,
        currentMessageText === ""
      );
    } else if (activeView === ActiveView.CACHE) {
      dispatch(
        selectCommand({ command: "@prompt", startIndex: 0, endIndex: 7 })
      );
      const promptText = isEditPromptMode
        ? ""
        : currentPromptText.startsWith("@prompt")
        ? currentPromptText
        : `@prompt ${currentPromptText}`;
      return updateEditorState(promptText, activeView, false);
    } else {
      return {
        localView: activeView,
        editorState: createEmptyDecoratedEditor(commands, activeExpert),
      };
    }
  }, [
    activeView,
    messageBarState.localView,
    isTranscribing,
    currentMessageText,
    currentPromptText,
    updateEditorState,
    dispatch,
    commands,
    activeExpert,
    isEditPromptMode,
  ]);

  // Trigger view change handling when active view changes
  useEffect(() => {
    if (messageBarState.localView && messageBarState.localView !== activeView) {
      const updatedState = handleViewChange();
      Object.assign(messageBarState, updatedState);
    }
  }, [activeView, messageBarState, handleViewChange]);

  return messageBarState;
};

export function resetCacheView(
  messageBarState: IMessageBarState,
  commandsSlice: any,
  activeExpert: any,
  dispatch: any,
  workspaceSlice: any
) {
  dispatch(selectCommand({ command: "@prompt", startIndex: 0, endIndex: 7 }));
  const isEditingPrompt = workspaceSlice.isEditPromptMode;
  const promptText = isEditingPrompt
    ? workspaceSlice.currentPromptText
    : "@prompt ";

  const contentState = ContentState.createFromText(promptText);
  const newEditorState = EditorState.createWithContent(
    contentState,
    createCommandDecorator(commandsSlice.commands, activeExpert?.color)
  );

  // Move cursor to the end of the text
  const selection = newEditorState.getSelection().merge({
    anchorOffset: promptText.length,
    focusOffset: promptText.length,
  });
  const editorStateWithSelection = EditorState.forceSelection(
    newEditorState,
    selection
  );

  const updatedState = {
    localView: ActiveView.CACHE,
    editorState: editorStateWithSelection,
    showPlaceholder: false,
  };

  Object.assign(messageBarState, updatedState);
}
